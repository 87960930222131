import { Button } from '@material-ui/core'
import MaterialTable, { Column } from 'material-table'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  ActionButtonContainer,
  FindLegislatorForm,
  FindTermForm,
  FormDrawer,
  FullHeightPage,
  LoadingContainer,
  NotFound,
  PageFooter,
  PageHeader,
  ScrollablePageContents,
  useDisclosure,
  useModal,
  useToast,
} from 'src/components'
import { rowDatum } from 'src/utilities/coercion'
import { displayMutationError, hasMutationErrors } from 'src/utilities/errors'
import { TermParameters } from '../../components/terms/FindTermForm'
import { Paths } from '../../constants/routes'
import { useStore } from '../../getMstGql'
import {
  ImportGroupModelType,
  importGroupSelector,
  LegislatorModelType,
  MutationResponseModelType,
} from '../../models'
import { useQuery } from '../../models/reactUtils'
import { ImportGroupDetailsPageParams } from './details'

export type ImportGroupLegislator = {
  id: string
  name: string
}

const getImportGroupLegislator = (legislator: LegislatorModelType): ImportGroupLegislator => {
  return {
    id: legislator.id,
    name: legislator.fullName || '',
  }
}

const EditImportGroupLegislatorsPage: React.FC = () => {
  const history = useHistory()
  const { setToast } = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { openDrawer, closeDrawer } = useModal()
  const { mutateSetImportGroupLegislators, queryLegislatorsByTerm } = useStore()
  const { id } = useParams<ImportGroupDetailsPageParams>()
  const [legislators, setLegislators] = useState<Array<ImportGroupLegislator>>([])
  const [loading, setLoading] = useState(false)
  const { loading: detailsLoading, data } = useQuery<{ importGroup: ImportGroupModelType }>(
    (store) => store.queryImportGroup({ id }, importGroupSelector),
  )
  const importGroup = useMemo(() => {
    return data?.importGroup
  }, [data])

  useEffect(() => {
    if (importGroup) {
      setLegislators((legislators) =>
        legislators.length
          ? legislators
          : importGroup.legislators?.toJS()?.map(getImportGroupLegislator),
      )
    }
  }, [importGroup])

  const handleRemoveRow = useCallback(
    (importGroupLeg: ImportGroupLegislator): void => {
      setLegislators((legislators) => legislators.filter((leg) => leg.id !== importGroupLeg.id))
    },
    [setLegislators],
  )

  const upsertRow = useCallback(
    (importGroupLeg: ImportGroupLegislator): void => {
      setLegislators((legislators) => {
        const existingIndex = legislators.findIndex((iv) => iv.id === importGroupLeg.id)
        if (existingIndex < 0) {
          return [...legislators, importGroupLeg]
        }
        const beforeRows = legislators.slice(0, existingIndex)
        const afterRows = legislators.slice(existingIndex + 1)
        return [...beforeRows, importGroupLeg, ...afterRows]
      })
    },
    [setLegislators],
  )

  const handleAddIndividual = useCallback(
    (legislator: LegislatorModelType) => {
      upsertRow(getImportGroupLegislator(legislator))
    },
    [upsertRow],
  )

  const handleRemoveIndividual = useCallback((legislator: LegislatorModelType) => {
    setLegislators((legislators) => legislators.filter((l) => l.id !== legislator.id))
  }, [])

  const selectedLegislatorIds = useMemo(() => legislators.map((l) => l.id), [legislators])

  const upsertMultipleRows = useCallback(
    (addedLegislators: Array<ImportGroupLegislator>): void => {
      setLegislators((legislators) => {
        const legislatorsToAdd = addedLegislators.filter(
          (addedLeg) => !legislators.find((l) => l.id === addedLeg.id),
        )
        return [...legislators, ...legislatorsToAdd]
      })
    },
    [setLegislators],
  )

  const handleAddByTerm = useCallback(
    async (term: TermParameters): Promise<void> => {
      setLoading(true)
      try {
        const response: {
          legislatorsByTerm: Array<LegislatorModelType>
        } = await queryLegislatorsByTerm(term)
        const newLegislators: Array<ImportGroupLegislator> = response.legislatorsByTerm.map(
          (leg) => ({
            id: leg.id,
            name: leg.fullName || '',
          }),
        )
        upsertMultipleRows(newLegislators)
      } finally {
        setLoading(false)
      }
    },
    [queryLegislatorsByTerm, upsertMultipleRows],
  )

  const handleSave = useCallback(async () => {
    setLoading(true)
    const legislatorIds = legislators.map((l) => l.id)

    const response: {
      setImportGroupLegislators: MutationResponseModelType
    } = await mutateSetImportGroupLegislators({
      importGroupId: id,
      legislatorIds,
    })
    setLoading(false)
    if (hasMutationErrors(response)) {
      setToast(displayMutationError(response))
      return
    }
    setToast({ variant: 'success', message: 'Legislators saved!' })
    history.push(`/importGroups/${id}`)
  }, [mutateSetImportGroupLegislators, id, legislators, setToast, history, setLoading])

  const columns: Array<Column<ImportGroupLegislator>> = useMemo(
    () => [
      {
        title: 'Name',
        field: 'name',
      },
    ],
    [],
  )

  if (detailsLoading && !importGroup) {
    return <LoadingContainer loading={detailsLoading} />
  }

  if (!importGroup) {
    return <NotFound />
  }

  return (
    <LoadingContainer loading={detailsLoading}>
      <FullHeightPage>
        <PageHeader
          title="Edit votes"
          breadcrumbs={[
            { label: 'Import groups', link: Paths.ImportGroupsList },
            { label: importGroup.name || '', link: `/importGroups/${importGroup.id}` },
            { label: 'Edit' },
          ]}
        >
          <ActionButtonContainer>
            <Button
              variant="contained"
              disabled={loading}
              onClick={() => {
                openDrawer(<FindTermForm onClose={closeDrawer} onComplete={handleAddByTerm} />)
              }}
            >
              Add by term
            </Button>
            <Button variant="contained" disabled={loading} onClick={onOpen}>
              Add individual
            </Button>
          </ActionButtonContainer>
        </PageHeader>
        <ScrollablePageContents>
          <MaterialTable
            data={legislators}
            columns={columns}
            options={{
              search: false,
              filtering: true,
              toolbar: false,
              debounceInterval: 200,
              pageSizeOptions: [10, 50, 100, 500],
              pageSize: 10,
            }}
            style={{ boxShadow: 'none' }}
            localization={{ header: { actions: '' } }}
            actions={[
              {
                icon: 'delete',
                tooltip: 'Remove',
                onClick: (event, d): void => handleRemoveRow(rowDatum(d)),
              },
            ]}
          />
        </ScrollablePageContents>
        <PageFooter>
          <ActionButtonContainer>
            <Button
              variant="contained"
              onClick={() => {
                history.push(`/importGroups/${importGroup.id}`)
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" disabled={loading} onClick={handleSave}>
              Save
            </Button>
          </ActionButtonContainer>
        </PageFooter>
        <FormDrawer open={isOpen} onClose={onClose}>
          <FindLegislatorForm
            onComplete={onClose}
            onAddLegislator={handleAddIndividual}
            selectedLegislatorIds={selectedLegislatorIds}
            onRemoveLegislator={handleRemoveIndividual}
          />
        </FormDrawer>
      </FullHeightPage>
    </LoadingContainer>
  )
}

export default observer(EditImportGroupLegislatorsPage)
