/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from 'mobx'
import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { BookModel, BookModelType } from './BookModel'
import { BookModelSelector } from './BookModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  records: IObservableArray<BookModelType>
}

/**
 * BookListResponseBase
 * auto generated base class for the model BookListResponseModel.
 */
export const BookListResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('BookListResponse')
    .props({
      __typename: types.optional(types.literal('BookListResponse'), 'BookListResponse'),
      records: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => BookModel))),
      ),
      count: types.union(types.undefined, types.number),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class BookListResponseModelSelector extends QueryBuilder {
  get count() {
    return this.__attr(`count`)
  }
  records(
    builder?: string | BookModelSelector | ((selector: BookModelSelector) => BookModelSelector),
  ) {
    return this.__child(`records`, BookModelSelector, builder)
  }
}
export function selectFromBookListResponse() {
  return new BookListResponseModelSelector()
}

export const bookListResponseModelPrimitives = selectFromBookListResponse().count
