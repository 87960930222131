/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from 'mobx-state-tree'

/**
 * Typescript enum
 */

export enum LegislatorsOrderColumns {
  FirstName = 'FirstName',
  LastName = 'LastName',
}

/**
 * LegislatorsOrderColumns
 */
export const LegislatorsOrderColumnsEnumType = types.enumeration('LegislatorsOrderColumns', [
  'FirstName',
  'LastName',
])
