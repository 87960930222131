/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { ChamberEnumType } from './ChamberEnum'
import { GovernmentLevelEnumType } from './GovernmentLevelEnum'
import { LegislatorModel, LegislatorModelType } from './LegislatorModel'
import { LegislatorModelSelector } from './LegislatorModel.base'
import { PartyEnumType } from './PartyEnum'
import { StateEnumType } from './StateEnum'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  legislator: LegislatorModelType
}

/**
 * TermBase
 * auto generated base class for the model TermModel.
 */
export const TermModelBase = withTypedRefs<Refs>()(
  ModelBase.named('Term')
    .props({
      __typename: types.optional(types.literal('Term'), 'Term'),
      id: types.identifier,
      createdAt: types.union(types.undefined, types.frozen()),
      updatedAt: types.union(types.undefined, types.frozen()),
      district: types.union(types.undefined, types.null, types.integer),
      party: types.union(types.undefined, PartyEnumType),
      legislator: types.union(types.undefined, MSTGQLRef(types.late((): any => LegislatorModel))),
      startDate: types.union(types.undefined, types.frozen()),
      endDate: types.union(types.undefined, types.frozen()),
      level: types.union(types.undefined, GovernmentLevelEnumType),
      chamber: types.union(types.undefined, ChamberEnumType),
      state: types.union(types.undefined, StateEnumType),
      legislatorId: types.union(types.undefined, types.string),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class TermModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  get district() {
    return this.__attr(`district`)
  }
  get party() {
    return this.__attr(`party`)
  }
  get startDate() {
    return this.__attr(`startDate`)
  }
  get endDate() {
    return this.__attr(`endDate`)
  }
  get level() {
    return this.__attr(`level`)
  }
  get chamber() {
    return this.__attr(`chamber`)
  }
  get state() {
    return this.__attr(`state`)
  }
  get legislatorId() {
    return this.__attr(`legislatorId`)
  }
  legislator(
    builder?:
      | string
      | LegislatorModelSelector
      | ((selector: LegislatorModelSelector) => LegislatorModelSelector),
  ) {
    return this.__child(`legislator`, LegislatorModelSelector, builder)
  }
}
export function selectFromTerm() {
  return new TermModelSelector()
}

export const termModelPrimitives = selectFromTerm().createdAt.updatedAt.district.party.startDate
  .endDate.level.chamber.state.legislatorId
