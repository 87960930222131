import { LegislatorModelType } from '../models'
import * as XLSX from 'xlsx'
import { ColInfo } from 'xlsx'
import { Maybe } from './types'

export type LegislatorUploadTemplateColumn = {
  name: string
  defaultValue?: (legislator: LegislatorModelType) => Maybe<string>
  width?: number
}

export function generateLegislatorUploadTemplate(
  legislators: Array<LegislatorModelType>,
  filename: string,
  additionalColumns: Array<LegislatorUploadTemplateColumn> = [],
): void {
  const workbook = XLSX.utils.book_new()
  const headerRow: Array<string> = ['ID', 'Name'].concat(additionalColumns.map((col) => col.name))
  const legislatorRows = legislators.map(
    (leg): Array<string> => {
      const baseRow = [leg.id, leg.fullName || '']
      return baseRow.concat(
        additionalColumns.map((col) => (col.defaultValue && col.defaultValue(leg)) || ''),
      )
    },
  )
  const worksheet = XLSX.utils.aoa_to_sheet([headerRow, ...legislatorRows])

  const legislatorColumnWidth = legislators.reduce((prev, legislator): number => {
    const name = legislator.fullName || ''
    return name.length > prev ? name.length : prev
  }, 0)

  worksheet['!cols'] = [
    {
      wch: 36,
    },
    {
      wch: legislatorColumnWidth,
    },
    ...additionalColumns.map(
      (col): ColInfo => ({
        wch: col.width,
      }),
    ),
  ]

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Legislators')
  XLSX.writeFile(workbook, `${filename.replace(/ /g, '_')}.xlsx`, { compression: true })
}

export function loadWorkbook(file: File): Promise<Maybe<XLSX.WorkBook>> {
  return new Promise<XLSX.WorkBook>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event: ProgressEvent<FileReader>): void => {
      if (!event?.target?.result) {
        console.error(event?.target?.error || new Error('Failed to load workbook'))
        return resolve(undefined)
      }
      if (typeof event.target.result === 'string') {
        console.error(new Error('Loaded result is a string'))
        return resolve(undefined)
      }
      const data = new Uint8Array(event.target.result)
      try {
        const workbook = XLSX.read(data, { type: 'array' })
        return resolve(workbook)
      } catch (error) {
        console.error(error)
        resolve(undefined)
      }
    }
    reader.readAsArrayBuffer(file)
  })
}

export function isErrorArray(result: ParsedWorkbookResult<any>): result is Array<string> {
  return Array.isArray(result)
}

export function isParsedResult<T extends object>(result: ParsedWorkbookResult<T>): result is T {
  return !Array.isArray(result)
}

export type ParsedWorkbookResult<T extends object> = T | Array<string>

export type RawWorkbookRecord = Record<string, string | number>

export type WorkbookRecordParser<T extends object> = (
  rawRecord: RawWorkbookRecord,
  index: number,
) => ParsedWorkbookResult<T>

export function parseWorkbook<T extends object>(
  workbook: XLSX.WorkBook,
  parser: WorkbookRecordParser<T>,
): Array<ParsedWorkbookResult<T>> {
  const sheetName = workbook.SheetNames[0]
  const sheet = workbook.Sheets[sheetName]
  const rawRecords = XLSX.utils.sheet_to_json<RawWorkbookRecord>(sheet)
  return rawRecords.map(parser)
}
