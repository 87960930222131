import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@material-ui/core'
import React from 'react'

export type ButtonProps = MuiButtonProps

const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <MuiButton component={MuiButton} variant="contained" fullWidth {...props}>
      {children}
    </MuiButton>
  )
}

export default Button
