import { TextField, TextFieldProps, useTheme } from '@material-ui/core'
import * as React from 'react'

const ReadOnlyTextField: React.FC<TextFieldProps> = (props) => {
  const theme = useTheme()
  return (
    <TextField
      disabled
      InputProps={{ readOnly: true, style: { color: theme.palette.text.primary } }}
      {...props}
    />
  )
}

export default ReadOnlyTextField
