import * as React from 'react'
import { BooksTable, PageContents, PageHeader } from 'src/components'

const BooksListPage: React.FC = () => {
  return (
    <>
      <PageHeader title="Books" breadcrumbs={[{ label: 'Books' }]} />
      <PageContents>
        <BooksTable />
      </PageContents>
    </>
  )
}

export default BooksListPage
