import { Button } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import * as React from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useToast } from '../common/utils/toast'
import { useStore } from '../../getMstGql'
import {
  FieldGroup,
  FormButtonGroup,
  FormContainer,
  FormSection,
  FormTextField,
} from '../common/forms'
import {
  LegislatorCreationResponseModelSelector,
  LegislatorCreationResponseModelType,
  legislatorModelPrimitives,
} from '../../models'
import { displayMutationError, hasMutationErrors } from '../../utilities/errors'
import { CreateLegislatorInput } from '../../models/RootStore.base'

const initialFormValues = {
  firstName: '',
  lastName: '',
  bioguideId: '',
}

const schema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
})

export interface AddLegislatorFormProps {
  onComplete: (willNavigate?: boolean) => any
}

const AddLegislatorForm: React.FC<AddLegislatorFormProps> = ({ onComplete }) => {
  const history = useHistory()
  const [navigateAfterCreation, setNavigateAfterCreate] = useState(false)
  const store = useStore()
  const { setToast } = useToast()

  return (
    <FormContainer title="Add Legislator">
      <Formik
        initialValues={initialFormValues}
        validationSchema={schema}
        validateOnBlur={false}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true)
          const input: CreateLegislatorInput = {
            firstName: values.firstName,
            lastName: values.lastName,
          }
          if (values.bioguideId) {
            input.bioguideId = values.bioguideId
          }

          const response = (await store
            .mutateCreateLegislator(
              { input },
              new LegislatorCreationResponseModelSelector().message.success
                .legislator(legislatorModelPrimitives)
                .toString(),
            )
            .currentPromise()) as { createLegislator: LegislatorCreationResponseModelType }

          if (hasMutationErrors(response)) {
            setToast(displayMutationError(response))
            return
          }

          setToast({
            message: `Legislator ${values.firstName} ${values.lastName} has been created!`,
            variant: 'success',
          })
          actions.resetForm()
          onComplete(navigateAfterCreation)
          if (navigateAfterCreation) {
            const templateId = response?.createLegislator?.legislator?.id
            history.push(`/legislators/${templateId}`)
          }
        }}
      >
        {(props: FormikProps<any>) => (
          <React.Fragment>
            <FormSection title="">
              <FieldGroup>
                <FormTextField label="First name" name="firstName" />
              </FieldGroup>
              <FieldGroup>
                <FormTextField label="Last name" name="lastName" />
              </FieldGroup>
              <FieldGroup>
                <FormTextField label="Bioguide ID (optional)" name="bioguideId" />
              </FieldGroup>
            </FormSection>

            <FormButtonGroup>
              <Button
                variant="contained"
                onClick={() => {
                  props.resetForm()
                  onComplete()
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={props.isSubmitting}
                onClick={() => {
                  setNavigateAfterCreate(false)
                  return props.submitForm()
                }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={props.isSubmitting}
                onClick={() => {
                  setNavigateAfterCreate(true)
                  return props.submitForm()
                }}
              >
                Save and View
              </Button>
            </FormButtonGroup>
          </React.Fragment>
        )}
      </Formik>
    </FormContainer>
  )
}

export default AddLegislatorForm
