/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from 'mobx-state-tree'

/**
 * Typescript enum
 */

export enum IssuesOrderColumns {
  Date = 'Date',
  Level = 'Level',
  Chamber = 'Chamber',
  State = 'State',
  RollCallNumber = 'RollCallNumber',
}

/**
 * IssuesOrderColumns
 */
export const IssuesOrderColumnsEnumType = types.enumeration('IssuesOrderColumns', [
  'Date',
  'Level',
  'Chamber',
  'State',
  'RollCallNumber',
])
