import { makeStyles, Theme, Typography } from '@material-ui/core'
import * as React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}))

export interface FormContainerProps {
  title: string
}

const FormContainer: React.FC<FormContainerProps> = ({ title, children }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography variant="h4" color="primary" className={classes.title}>
        {title}
      </Typography>
      {children}
    </div>
  )
}

export default FormContainer
