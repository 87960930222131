import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import {
  ActionButtonContainer,
  ActionsMenu,
  AddIssueTemplateForm,
  DownloadImportGroupTemplateForm,
  EditIssueForm,
  FieldGroup,
  LoadImportGroupTemplateForm,
  PageContents,
  PageHeader,
  ReadOnlyForm,
  ReadOnlyTextField,
  SelectFilter,
  TableActions,
} from '../../components'
import { LoadingContainer, NotFound } from '../../components/common/utils'
import { useModal } from '../../components/common/utils/modal'
import { useToast } from '../../components/common/utils/toast'
import { Paths } from '../../constants/routes'
import { useStore } from '../../getMstGql'
import { IssueModelType, MutationResponseModelType, Party, VoteValue } from '../../models'
import { useQuery } from '../../models/reactUtils'
import { formatDate } from '../../utilities/dates'
import { displayMutationError, hasMutationErrors } from '../../utilities/errors'
import { IssueUrl } from '../../utilities/IssueUrl'
import { issueSelector } from '../../models/selectors'
import { Accordion, AccordionSummary, Button, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MaterialTable from 'material-table'
import { ParsedWorkbookResult, RawWorkbookRecord } from '../../utilities/spreadsheets'
import { parseParty, parseVoteValue } from '../../utilities/enums'
import { BulkEditVoteRecord } from '../../models/RootStore.base'

export interface IssueDetailsPageParams {
  id: string
}

type ParsedVoteImportRecord = {
  id: string
  name: string
  party: Party
  value: VoteValue
}

function voteRecordImportParser(
  rawRecord: RawWorkbookRecord,
  index: number,
): ParsedWorkbookResult<ParsedVoteImportRecord> {
  const adjustedIndex = index + 2
  const keys = Object.keys(rawRecord)
  const errors = []

  const idKey = keys.find((k) => k.toLowerCase() === 'id') || ''
  const id = rawRecord[idKey]
  if (!id) {
    errors.push(`Missing ID on row ${adjustedIndex}`)
  }
  if (typeof id !== 'string') {
    errors.push(`Invalid ID on row ${adjustedIndex}`)
  }

  const nameKey = keys.find((k) => k.toLowerCase() === 'name') || ''
  const name = rawRecord[nameKey]
  if (!name) {
    errors.push(`Missing name on row ${adjustedIndex}`)
  }
  if (typeof name !== 'string') {
    errors.push(`Invalid name on row ${adjustedIndex}`)
  }

  const partyKey = keys.find((k) => k.toLowerCase() === 'party') || ''
  const party = rawRecord[partyKey]
  if (!party) {
    errors.push(`Missing party on row ${adjustedIndex}`)
  }
  const parsedParty = parseParty(party?.toString() || '')
  if (!parsedParty) {
    errors.push(`Invalid party on row ${adjustedIndex}`)
  }

  const valueKey = keys.find((k) => k.toLowerCase() === 'vote') || ''
  const value = rawRecord[valueKey]
  if (!value) {
    errors.push(`Missing vote value on row ${adjustedIndex}`)
  }
  const parsedValue = parseVoteValue(value?.toString() || '')
  if (!parsedValue) {
    console.log(value, parsedValue)
    errors.push(`Invalid vote value on row ${adjustedIndex}`)
  }

  if (errors.length) {
    return errors
  }
  return {
    id: id as string,
    name: name as string,
    party: parsedParty as Party,
    value: parsedValue as VoteValue,
  }
}

const IssueDetailsPage = () => {
  const { id } = useParams<IssueDetailsPageParams>()
  const { loading, data, query } = useQuery<{ issue: IssueModelType }>((store) =>
    store.queryIssue({ id }, issueSelector),
  )
  const { mutateDeleteIssue, mutateBulkEditVotes } = useStore()
  const history = useHistory()
  const { setToast } = useToast()
  const { openDialog, openDrawer, closeDrawer } = useModal()

  const issue = useMemo(() => {
    return data?.issue
  }, [data])

  const handleDeleteIssue = useCallback(async () => {
    const response = (await mutateDeleteIssue({ id }).currentPromise()) as {
      deleteIssue: MutationResponseModelType
    }

    if (hasMutationErrors(response)) {
      setToast(displayMutationError(response))
    } else {
      setToast('Deleted vote.')
      history.push(Paths.VotesList)
    }
  }, [id, mutateDeleteIssue, setToast, history])

  const handleUpdateIssue = useCallback(() => {
    closeDrawer()
    query?.refetch()
  }, [query, closeDrawer])

  const handleImport = useCallback(
    async (records: Array<ParsedVoteImportRecord>): Promise<void> => {
      const votes: Array<BulkEditVoteRecord> = records.map((importRecord) => ({
        party: importRecord.party,
        legislatorId: importRecord.id,
        value: importRecord.value,
      }))

      const response: { bulkEditVotes: MutationResponseModelType } = await mutateBulkEditVotes({
        issueId: id,
        votes,
      })
      if (hasMutationErrors(response)) {
        setToast(displayMutationError(response))
        closeDrawer()
        return
      }
      setToast({ variant: 'success', message: 'Votes imported' })
      handleUpdateIssue()
    },
    [mutateBulkEditVotes, id, setToast, closeDrawer, handleUpdateIssue],
  )

  const handleImportVotes = useCallback(() => {
    openDrawer(
      <LoadImportGroupTemplateForm
        onCancel={closeDrawer}
        recordParser={voteRecordImportParser}
        onImport={handleImport}
      />,
    )
  }, [openDrawer, closeDrawer, handleImport])

  if (loading && !issue) {
    return <LoadingContainer loading={loading} />
  }

  if (!issue) {
    return <NotFound />
  }
  const templateAction = issue.issueTemplateId
    ? {
        label: 'View issue template',
        onClick: (): void => {
          history.push(`/templates/${issue.issueTemplateId}`)
        },
      }
    : {
        label: 'Add template',
        onClick: (): void => {
          openDrawer(<AddIssueTemplateForm issue={issue} onComplete={handleUpdateIssue} />)
        },
      }

  const title = issue.legislationNumber || ''
  const url = issue.url || ''
  const issueUrl = IssueUrl.from(url)
  return (
    <LoadingContainer loading={loading && !issue}>
      <PageHeader
        title={title}
        breadcrumbs={[{ link: Paths.VotesList, label: 'Votes' }, { label: title }]}
      >
        <ActionButtonContainer>
          <ActionsMenu
            actions={[
              {
                label: 'Download import group template',
                onClick: (): void => {
                  openDrawer(
                    <DownloadImportGroupTemplateForm
                      name="Vote Import Template"
                      onComplete={closeDrawer}
                      additionalColumns={[
                        {
                          name: 'Party',
                          defaultValue: (leg) => leg.mostRecentParty || '',
                          width: 12,
                        },
                        {
                          name: 'Vote',
                        },
                      ]}
                    />,
                  )
                },
              },
              templateAction,
              {
                label: 'Edit',
                onClick: (): void => {
                  openDrawer(<EditIssueForm issue={issue} onComplete={handleUpdateIssue} />)
                },
              },
              {
                label: 'Delete',
                onClick: (): void => {
                  openDialog({
                    title: 'Delete this record?',
                    body: 'This is permanent. Deleted records cannot be restored.',
                    onConfirm: handleDeleteIssue,
                  })
                },
              },
            ]}
          />
        </ActionButtonContainer>
      </PageHeader>
      <PageContents>
        <ReadOnlyForm title="Branch">
          <FieldGroup>
            <ReadOnlyTextField label="Level" value={issue.level} />
            <ReadOnlyTextField label="Chamber" value={issue.chamber} />
            <ReadOnlyTextField label="State" value={issue.state || ' '} />
          </FieldGroup>
        </ReadOnlyForm>

        <ReadOnlyForm title="Issue details">
          <FieldGroup>
            <ReadOnlyTextField label="URL" value={issueUrl?.oldFormat || url} />
          </FieldGroup>
          {issueUrl ? (
            <FieldGroup>
              <ReadOnlyTextField label="New URL" value={issueUrl?.newFormat} />
            </FieldGroup>
          ) : (
            <div />
          )}
          <FieldGroup>
            <ReadOnlyTextField label="Date" value={formatDate(issue.date)} />
            <ReadOnlyTextField label="Result" value={issue.result} />
          </FieldGroup>
          <FieldGroup>
            <ReadOnlyTextField label="Bill title" value={issue.legislationNumber} />
            <ReadOnlyTextField label="Roll #" value={issue.rollCallNumber} />
          </FieldGroup>
          <FieldGroup>
            <ReadOnlyTextField label="Description" multiline value={issue.description} />
          </FieldGroup>
        </ReadOnlyForm>

        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Votes</Typography>
          </AccordionSummary>
          <TableActions>
            <Button variant="contained" onClick={handleImportVotes}>
              Import
            </Button>
            <Button
              variant="contained"
              onClick={(): void => {
                history.push(`/votes/${issue?.id}/edit`)
              }}
            >
              Edit
            </Button>
          </TableActions>
          <MaterialTable
            data={issue?.votes || []}
            columns={[
              {
                title: 'Legislator',
                field: 'legislator.fullName',
                // eslint-disable-next-line react/display-name
                render: (data): React.ReactElement => {
                  return (
                    <Link to={`/legislators/${data.legislatorId}`}>
                      {data.legislator?.fullName}
                    </Link>
                  )
                },
                defaultSort: 'asc',
              },
              {
                title: 'Party',
                field: 'party',
                lookup: Party,
                filterComponent: SelectFilter,
              },
              {
                title: 'Vote',
                field: 'value',
                lookup: VoteValue,
                filterComponent: SelectFilter,
              },
            ]}
            options={{ search: false, filtering: true, toolbar: false }}
            style={{ boxShadow: 'none' }}
            localization={{ header: { actions: '' } }}
          />
        </Accordion>
      </PageContents>
    </LoadingContainer>
  )
}

export default observer(IssueDetailsPage)
