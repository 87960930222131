import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxHeight: '100%',
  },
}))

const FullHeightPage: React.FC = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.root}>{children}</div>
}

export default FullHeightPage
