import { Button } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import * as React from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useToast } from '../common/utils/toast'
import { useStore } from '../../getMstGql'
import {
  FieldGroup,
  FormButtonGroup,
  FormContainer,
  FormSection,
  FormTextField,
} from '../common/forms'
import {
  LayerCreationResponseModelSelector,
  LayerCreationResponseModelType,
  layerModelPrimitives,
} from '../../models'
import { displayMutationError, hasMutationErrors } from '../../utilities/errors'
import { observer } from 'mobx-react-lite'

const initialFormValues = {
  name: '',
}

const schema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
})

export interface AddLayerFormProps {
  onComplete: (willNavigate?: boolean) => any
}

const AddLayerForm: React.FC<AddLayerFormProps> = ({ onComplete }) => {
  const history = useHistory()
  const [navigateAfterCreation, setNavigateAfterCreate] = useState(false)
  const { mutateCreateLayer } = useStore()
  const { setToast } = useToast()

  return (
    <FormContainer title="Add Layer">
      <Formik
        initialValues={initialFormValues}
        validationSchema={schema}
        validateOnBlur={false}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true)
          const response = (await mutateCreateLayer(
            { input: values },
            new LayerCreationResponseModelSelector().message.success
              .layer(layerModelPrimitives)
              .toString(),
          ).currentPromise()) as { createLayer: LayerCreationResponseModelType }
          actions.setSubmitting(false)

          if (hasMutationErrors(response)) {
            setToast(displayMutationError(response))
            return
          }

          setToast({
            message: `Layer has been created!`,
            variant: 'success',
          })
          actions.resetForm()
          onComplete(navigateAfterCreation)
          if (navigateAfterCreation) {
            const layerId = response?.createLayer?.layer?.id
            history.push(`/layers/${layerId}`)
          }
        }}
      >
        {(props: FormikProps<any>) => (
          <React.Fragment>
            <FormSection title="">
              <FieldGroup>
                <FormTextField label="Name" name="name" />
              </FieldGroup>
            </FormSection>

            <FormButtonGroup>
              <Button
                variant="contained"
                onClick={() => {
                  props.resetForm()
                  onComplete()
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={props.isSubmitting}
                onClick={() => {
                  setNavigateAfterCreate(false)
                  return props.submitForm()
                }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={props.isSubmitting}
                onClick={() => {
                  setNavigateAfterCreate(true)
                  return props.submitForm()
                }}
              >
                Save and View
              </Button>
            </FormButtonGroup>
          </React.Fragment>
        )}
      </Formik>
    </FormContainer>
  )
}

export default observer(AddLayerForm)
