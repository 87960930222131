/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { BookModel, BookModelType } from './BookModel'
import { BookModelSelector } from './BookModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  book: BookModelType
}

/**
 * GenerateVoteBookMutationResponseBase
 * auto generated base class for the model GenerateVoteBookMutationResponseModel.
 */
export const GenerateVoteBookMutationResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('GenerateVoteBookMutationResponse')
    .props({
      __typename: types.optional(
        types.literal('GenerateVoteBookMutationResponse'),
        'GenerateVoteBookMutationResponse',
      ),
      success: types.union(types.undefined, types.boolean),
      message: types.union(types.undefined, types.string),
      book: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => BookModel))),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class GenerateVoteBookMutationResponseModelSelector extends QueryBuilder {
  get success() {
    return this.__attr(`success`)
  }
  get message() {
    return this.__attr(`message`)
  }
  book(
    builder?: string | BookModelSelector | ((selector: BookModelSelector) => BookModelSelector),
  ) {
    return this.__child(`book`, BookModelSelector, builder)
  }
}
export function selectFromGenerateVoteBookMutationResponse() {
  return new GenerateVoteBookMutationResponseModelSelector()
}

export const generateVoteBookMutationResponseModelPrimitives = selectFromGenerateVoteBookMutationResponse()
  .success.message
