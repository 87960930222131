/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { TermModel, TermModelType } from './TermModel'
import { TermModelSelector } from './TermModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  term: TermModelType
}

/**
 * TermCreationResponseBase
 * auto generated base class for the model TermCreationResponseModel.
 */
export const TermCreationResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('TermCreationResponse')
    .props({
      __typename: types.optional(types.literal('TermCreationResponse'), 'TermCreationResponse'),
      success: types.union(types.undefined, types.boolean),
      message: types.union(types.undefined, types.string),
      term: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => TermModel))),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class TermCreationResponseModelSelector extends QueryBuilder {
  get success() {
    return this.__attr(`success`)
  }
  get message() {
    return this.__attr(`message`)
  }
  term(
    builder?: string | TermModelSelector | ((selector: TermModelSelector) => TermModelSelector),
  ) {
    return this.__child(`term`, TermModelSelector, builder)
  }
}
export function selectFromTermCreationResponse() {
  return new TermCreationResponseModelSelector()
}

export const termCreationResponseModelPrimitives = selectFromTermCreationResponse().success.message
