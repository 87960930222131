/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { IssueTemplateModel, IssueTemplateModelType } from './IssueTemplateModel'
import { IssueTemplateModelSelector } from './IssueTemplateModel.base'
import { LayerModel, LayerModelType } from './LayerModel'
import { LayerModelSelector } from './LayerModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  issueTemplate: IssueTemplateModelType
  layer: LayerModelType
}

/**
 * AssignedLayerBase
 * auto generated base class for the model AssignedLayerModel.
 */
export const AssignedLayerModelBase = withTypedRefs<Refs>()(
  ModelBase.named('AssignedLayer')
    .props({
      __typename: types.optional(types.literal('AssignedLayer'), 'AssignedLayer'),
      id: types.identifier,
      createdAt: types.union(types.undefined, types.frozen()),
      updatedAt: types.union(types.undefined, types.frozen()),
      issueTemplateId: types.union(types.undefined, types.string),
      layerId: types.union(types.undefined, types.string),
      issueTemplate: types.union(
        types.undefined,
        MSTGQLRef(types.late((): any => IssueTemplateModel)),
      ),
      layer: types.union(types.undefined, MSTGQLRef(types.late((): any => LayerModel))),
      text: types.union(types.undefined, types.string),
      url: types.union(types.undefined, types.null, types.string),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class AssignedLayerModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  get issueTemplateId() {
    return this.__attr(`issueTemplateId`)
  }
  get layerId() {
    return this.__attr(`layerId`)
  }
  get text() {
    return this.__attr(`text`)
  }
  get url() {
    return this.__attr(`url`)
  }
  issueTemplate(
    builder?:
      | string
      | IssueTemplateModelSelector
      | ((selector: IssueTemplateModelSelector) => IssueTemplateModelSelector),
  ) {
    return this.__child(`issueTemplate`, IssueTemplateModelSelector, builder)
  }
  layer(
    builder?: string | LayerModelSelector | ((selector: LayerModelSelector) => LayerModelSelector),
  ) {
    return this.__child(`layer`, LayerModelSelector, builder)
  }
}
export function selectFromAssignedLayer() {
  return new AssignedLayerModelSelector()
}

export const assignedLayerModelPrimitives = selectFromAssignedLayer().createdAt.updatedAt
  .issueTemplateId.layerId.text.url
