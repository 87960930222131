export type Maybe<T> = T | undefined

export function getOrThrow<T>(value: Maybe<T>, errorMessage = 'Unexpected undefined value'): T {
  if (typeof value === 'undefined') {
    throw new Error(errorMessage)
  }
  return value
}

export function getNullable<T>(value: T | null, errorMessage = 'Unexpected null value'): T {
  if (value === null) {
    throw new Error(errorMessage)
  }
  return value
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}
