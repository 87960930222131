/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from 'mobx-state-tree'

/**
 * Typescript enum
 */

export enum IssueTemplatesOrderColumns {
  Category = 'Category',
  SubCategory = 'SubCategory',
  Date = 'Date',
  CreatedAt = 'CreatedAt',
}

/**
 * IssueTemplatesOrderColumns
 */
export const IssueTemplatesOrderColumnsEnumType = types.enumeration('IssueTemplatesOrderColumns', [
  'Category',
  'SubCategory',
  'Date',
  'CreatedAt',
])
