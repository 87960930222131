import { createHttpClient } from 'mst-gql'
import { useContext } from 'react'
import { API_SERVER_URL } from './constants/api'
import { RootStore, RootStoreType } from './models'
import { StoreContext } from './models/reactUtils'

const defaultState = {
  authentication: {
    token: undefined,
  },
}

const gqlHttpClient = createHttpClient(`${API_SERVER_URL}/graphql`, {
  credentials: 'include',
})

export const rootStore = RootStore.create(defaultState, {
  gqlHttpClient,
})

declare global {
  interface Window {
    STORE: RootStoreType
  }
}

if (process.env.NODE_ENV === 'development') {
  window.STORE = rootStore
}

export const useStore = (): RootStoreType => useContext(StoreContext)
