/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { AssignedLayerModel, AssignedLayerModelType } from './AssignedLayerModel'
import { AssignedLayerModelSelector } from './AssignedLayerModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  assignedLayer: AssignedLayerModelType
}

/**
 * AssignedLayerUpdateResponseBase
 * auto generated base class for the model AssignedLayerUpdateResponseModel.
 */
export const AssignedLayerUpdateResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('AssignedLayerUpdateResponse')
    .props({
      __typename: types.optional(
        types.literal('AssignedLayerUpdateResponse'),
        'AssignedLayerUpdateResponse',
      ),
      success: types.union(types.undefined, types.boolean),
      message: types.union(types.undefined, types.string),
      assignedLayer: types.union(
        types.undefined,
        types.null,
        MSTGQLRef(types.late((): any => AssignedLayerModel)),
      ),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class AssignedLayerUpdateResponseModelSelector extends QueryBuilder {
  get success() {
    return this.__attr(`success`)
  }
  get message() {
    return this.__attr(`message`)
  }
  assignedLayer(
    builder?:
      | string
      | AssignedLayerModelSelector
      | ((selector: AssignedLayerModelSelector) => AssignedLayerModelSelector),
  ) {
    return this.__child(`assignedLayer`, AssignedLayerModelSelector, builder)
  }
}
export function selectFromAssignedLayerUpdateResponse() {
  return new AssignedLayerUpdateResponseModelSelector()
}

export const assignedLayerUpdateResponseModelPrimitives = selectFromAssignedLayerUpdateResponse()
  .success.message
