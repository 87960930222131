import MaterialTable, { Column, Options } from 'material-table'
import * as React from 'react'
import { useEffect, useMemo } from 'react'
import {
  ImportGroupListResponseModelType,
  importGroupListSelector,
  ImportGroupModelType,
} from '../../models'
import { rowDatum } from '../../utilities/coercion'
import { observer } from 'mobx-react-lite'
import { Accordion, AccordionSummary, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useQuery } from '../../models/reactUtils'

type ListQueryResponseType = { importGroups: ImportGroupListResponseModelType }

export type ImportGroupsTableProps = {
  filters?: {}
  tableOptions?: Options<ImportGroupModelType>
  onRowClick?: (row: ImportGroupModelType) => void
  cacheKey?: string | number
}

const ImportGroupsTable: React.FC<ImportGroupsTableProps> = ({
  onRowClick,
  cacheKey,
  tableOptions = {},
}) => {
  const { data, loading, query } = useQuery<ListQueryResponseType>((store) =>
    store.queryImportGroups({}, importGroupListSelector),
  )

  useEffect(() => {
    if (!query?.loading) {
      query?.refetch()
    }
  }, [query, cacheKey])

  const handleDetailsClick = React.useCallback(
    (row: ImportGroupModelType): void => onRowClick && onRowClick(row),
    [onRowClick],
  )

  const combinedTableOptions: Options<ImportGroupModelType> = React.useMemo(
    () => ({
      filtering: true,
      toolbar: false,
      pageSize: 10,
      ...tableOptions,
    }),
    [tableOptions],
  )

  const columns = React.useMemo(
    (): Array<Column<ImportGroupModelType>> => [
      {
        title: 'Name',
        field: 'name',
      },
    ],
    [],
  )

  const importGroups: Array<ImportGroupModelType> = useMemo(() => {
    return data?.importGroups?.records?.toJS() || []
  }, [data])

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Import groups</Typography>
      </AccordionSummary>
      <MaterialTable
        columns={columns}
        data={importGroups}
        options={combinedTableOptions}
        isLoading={loading}
        localization={{ header: { actions: '' } }}
        style={{ boxShadow: 'none' }}
        actions={[
          {
            icon: 'pageview',
            tooltip: 'View details',
            onClick: (event, d): void => handleDetailsClick(rowDatum(d)),
          },
        ]}
      />
    </Accordion>
  )
}

export default observer(ImportGroupsTable)
