import React from 'react'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { useHistory, useRouteMatch } from 'react-router-dom'

export interface NavigationItemProps {
  icon?: React.ComponentType
  title: string | React.ReactElement
  path: string
}

const NavigationItem: React.FC<NavigationItemProps> = ({ icon, title, path }) => {
  const history = useHistory()
  const match = useRouteMatch(path)

  return (
    <ListItem button onClick={(): void => history.push(path)} selected={match?.isExact || false}>
      {icon && <ListItemIcon>{React.createElement(icon)}</ListItemIcon>}
      <ListItemText primary={title} />
    </ListItem>
  )
}

export default NavigationItem
