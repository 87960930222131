/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from 'mobx'
import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { UserModel, UserModelType } from './UserModel'
import { UserModelSelector } from './UserModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  records: IObservableArray<UserModelType>
}

/**
 * UserListResponseBase
 * auto generated base class for the model UserListResponseModel.
 */
export const UserListResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('UserListResponse')
    .props({
      __typename: types.optional(types.literal('UserListResponse'), 'UserListResponse'),
      records: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => UserModel))),
      ),
      count: types.union(types.undefined, types.number),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class UserListResponseModelSelector extends QueryBuilder {
  get count() {
    return this.__attr(`count`)
  }
  records(
    builder?: string | UserModelSelector | ((selector: UserModelSelector) => UserModelSelector),
  ) {
    return this.__child(`records`, UserModelSelector, builder)
  }
}
export function selectFromUserListResponse() {
  return new UserListResponseModelSelector()
}

export const userListResponseModelPrimitives = selectFromUserListResponse().count
