import {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
} from '@material-ui/core/TextField/TextField'
import { Field, FieldProps } from 'formik'
import * as React from 'react'
import { FormattedTextField } from './'

export type FormTextFieldProps = { name: string } & (
  | StandardTextFieldProps
  | FilledTextFieldProps
  | OutlinedTextFieldProps
)

const FormTextField: React.FC<FormTextFieldProps> = (props) => (
  <Field name={props.name}>
    {({ field: { value, ...field }, meta }: FieldProps): React.ReactElement => (
      <FormattedTextField
        error={!!meta.error && meta.touched}
        helperText={meta.touched && meta.error}
        disabled={false}
        value={value || ''}
        {...field}
        {...props}
      />
    )}
  </Field>
)

export default FormTextField
