import * as React from 'react'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ActionButtonContainer, Button, PageContents, PageHeader, useModal } from 'src/components'
import { LegislatorModelType } from '../../models'
import { LegislatorsTable } from '../../components/legislators'
import AddLegislatorForm from '../../components/legislators/AddLegislatorForm'

const LegislatorsListPage: React.FC = () => {
  const history = useHistory()
  const { openDrawer, closeDrawer } = useModal()
  const [cacheKey, setCacheKey] = useState(Date.now())
  const updateCacheKey = useCallback(() => setCacheKey(Date.now()), [setCacheKey])
  const handleAddLegislator = useCallback(
    (willNavigate?: boolean) => {
      closeDrawer()
      if (!willNavigate) {
        updateCacheKey()
      }
    },
    [closeDrawer, updateCacheKey],
  )
  const handleRowClick = React.useCallback(
    (row: LegislatorModelType): void => {
      history.push(`/legislators/${row.id}`)
    },
    [history],
  )

  return (
    <>
      <PageHeader title="Legislators" breadcrumbs={[{ label: 'Legislators' }]}>
        <ActionButtonContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={() => openDrawer(<AddLegislatorForm onComplete={handleAddLegislator} />)}
          >
            Create
          </Button>
        </ActionButtonContainer>
      </PageHeader>
      <PageContents>
        <LegislatorsTable onRowClick={handleRowClick} cacheKey={cacheKey} />
      </PageContents>
    </>
  )
}

export default LegislatorsListPage
