/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { BookStatusEnumType } from './BookStatusEnum'
import { UserModel, UserModelType } from './UserModel'
import { UserModelSelector } from './UserModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  user: UserModelType
}

/**
 * BookBase
 * auto generated base class for the model BookModel.
 */
export const BookModelBase = withTypedRefs<Refs>()(
  ModelBase.named('Book')
    .props({
      __typename: types.optional(types.literal('Book'), 'Book'),
      id: types.identifier,
      createdAt: types.union(types.undefined, types.frozen()),
      updatedAt: types.union(types.undefined, types.frozen()),
      userId: types.union(types.undefined, types.string),
      user: types.union(types.undefined, MSTGQLRef(types.late((): any => UserModel))),
      title: types.union(types.undefined, types.string),
      status: types.union(types.undefined, BookStatusEnumType),
      filename: types.union(types.undefined, types.string),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class BookModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  get userId() {
    return this.__attr(`userId`)
  }
  get title() {
    return this.__attr(`title`)
  }
  get status() {
    return this.__attr(`status`)
  }
  get filename() {
    return this.__attr(`filename`)
  }
  user(
    builder?: string | UserModelSelector | ((selector: UserModelSelector) => UserModelSelector),
  ) {
    return this.__child(`user`, UserModelSelector, builder)
  }
}
export function selectFromBook() {
  return new BookModelSelector()
}

export const bookModelPrimitives = selectFromBook().createdAt.updatedAt.userId.title.status.filename
