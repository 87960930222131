import { Button } from '@material-ui/core'
import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { Paths } from '../../constants/routes'
import { useModal } from '../../components/common/utils/modal'
import {
  ActionButtonContainer,
  FieldGroup,
  PageContents,
  PageHeader,
  ReadOnlyForm,
  ReadOnlyTextField,
} from '../../components/common'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../getMstGql'
import EditUserForm from '../../components/users/EditUserForm'

const ProfilePage: React.FC = () => {
  const {
    authentication: { currentUser },
  } = useStore()
  const { openDrawer, closeDrawer } = useModal()
  if (!currentUser) {
    return <Redirect to={Paths.Login} />
  }
  return (
    <>
      <PageHeader title="Profile" breadcrumbs={[{ label: currentUser.name || '' }]}>
        <ActionButtonContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              openDrawer(<EditUserForm user={currentUser} profile onComplete={closeDrawer} />)
            }
          >
            Edit
          </Button>
        </ActionButtonContainer>
      </PageHeader>

      <PageContents>
        <ReadOnlyForm title="General">
          <FieldGroup>
            <ReadOnlyTextField label="Email" value={currentUser.email} />
          </FieldGroup>
          <FieldGroup>
            <ReadOnlyTextField label="Name" value={currentUser.name} />
          </FieldGroup>
        </ReadOnlyForm>
      </PageContents>
    </>
  )
}

export default observer(ProfilePage)
