import * as React from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ActionButtonContainer,
  FindLegislatorForm,
  FullHeightPage,
  LoadingContainer,
  NotFound,
  PageFooter,
  PageHeader,
  ScrollablePageContents,
  SelectFilter,
  useModal,
  useToast,
} from 'src/components'
import { useHistory, useParams } from 'react-router-dom'
import {
  Chamber,
  GovernmentLevel,
  IssueModelType,
  LegislatorModelType,
  MutationResponseModelType,
  Party,
  State,
  VoteModelType,
  VoteValue,
} from '../../models'
import { useQuery } from '../../models/reactUtils'
import { issueSelector } from '../../models/selectors'
import { IssueDetailsPageParams } from './details'
import { observer } from 'mobx-react-lite'
import { Paths } from '../../constants/routes'
import { Button, TextField } from '@material-ui/core'
import MaterialTable, { Column } from 'material-table'
import { Autocomplete } from '@material-ui/lab'
import { useStore } from '../../getMstGql'
import { getNullable, getOrThrow } from '../../utilities/types'
import { BulkEditVoteRecord } from '../../models/RootStore.base'
import { displayMutationError, hasMutationErrors } from 'src/utilities/errors'
import { rowDatum } from 'src/utilities/coercion'

export type IssueVote = {
  legislatorId: string
  name: string
  party: Party | null
  value: VoteValue | null
}

const getIssueVotes = (issue: IssueModelType): Array<IssueVote> => {
  const votes = issue.votes || []
  return votes.map(
    (vote: VoteModelType): IssueVote => ({
      legislatorId: vote.legislatorId || '',
      name: vote?.legislator?.fullName || '',
      party: (vote.party as Party) || null,
      value: (vote.value as VoteValue) || null,
    }),
  )
}

const EditVotesPage: React.FC = () => {
  const history = useHistory()
  const { setToast } = useToast()
  const { mutateBulkEditVotes, queryLegislatorsByTerm } = useStore()
  const { id } = useParams<IssueDetailsPageParams>()
  const [issueVotes, setIssueVotes] = useState<Array<IssueVote>>([])
  const [showIncomplete, setShowIncomplete] = useState(false)
  const [loading, setLoading] = useState(false)
  const { loading: detailsLoading, data } = useQuery<{ issue: IssueModelType }>((store) =>
    store.queryIssue({ id }, issueSelector),
  )
  const { openDrawer, closeDrawer } = useModal()

  const issue = useMemo(() => {
    return data?.issue
  }, [data])

  useEffect(() => {
    if (issue) {
      setIssueVotes((votes) => (votes.length ? votes : getIssueVotes(issue)))
    }
  }, [issue])

  const handleRemoveRow = useCallback(
    (issueVote: IssueVote): void => {
      setIssueVotes((votes) => votes.filter((v) => v.legislatorId !== issueVote.legislatorId))
    },
    [setIssueVotes],
  )

  const upsertRow = useCallback(
    (issueVote: IssueVote): void => {
      setIssueVotes((issueVotes) => {
        const existingIndex = issueVotes.findIndex(
          (iv) => iv.legislatorId === issueVote.legislatorId,
        )
        if (existingIndex < 0) {
          return [...issueVotes, issueVote]
        }
        const beforeRows = issueVotes.slice(0, existingIndex)
        const afterRows = issueVotes.slice(existingIndex + 1)
        return [...beforeRows, issueVote, ...afterRows]
      })
    },
    [setIssueVotes],
  )

  const upsertMultipleRows = useCallback(
    (addedVotes: Array<IssueVote>): void => {
      setIssueVotes((issueVotes) => {
        const votesToAdd = addedVotes.filter(
          (v) => !issueVotes.find((iv) => iv.legislatorId === v.legislatorId),
        )
        return [...issueVotes, ...votesToAdd]
      })
    },
    [setIssueVotes],
  )

  const handleAddByTerm = useCallback(async (): Promise<void> => {
    setLoading(true)
    try {
      if (!issue) {
        setLoading(false)
        return
      }
      const response: {
        legislatorsByTerm: Array<LegislatorModelType>
      } = await queryLegislatorsByTerm({
        date: issue.date || '',
        level: getOrThrow(getNullable(issue.level)) as GovernmentLevel,
        chamber: getOrThrow(getNullable(issue.chamber)) as Chamber,
        state: (issue.state as State) || undefined,
      })
      const newIssueVotes: Array<IssueVote> = response.legislatorsByTerm.map((leg) => ({
        legislatorId: leg.id,
        name: leg.fullName || '',
        party: (leg.mostRecentParty as Party) || null,
        value: null,
      }))
      upsertMultipleRows(newIssueVotes)
    } finally {
      setLoading(false)
    }
  }, [issue, queryLegislatorsByTerm, upsertMultipleRows])

  const handleAddIndividual = useCallback(
    (legislator: LegislatorModelType) => {
      const issueVote: IssueVote = {
        legislatorId: legislator.id,
        name: legislator.fullName || '',
        party: (legislator.mostRecentParty as Party) || null,
        value: null,
      }
      upsertRow(issueVote)
    },
    [upsertRow],
  )

  const handleSave = useCallback(async () => {
    const invalidRecord = issueVotes.find((v) => !v.party || !v.value)
    if (invalidRecord) {
      setShowIncomplete(true)
      setToast({
        variant: 'error',
        message: 'One or more votes are incomplete',
      })
      return
    }
    setLoading(true)
    const votes: Array<BulkEditVoteRecord> = issueVotes.map((iv) => ({
      party: getNullable(iv.party),
      legislatorId: iv.legislatorId,
      value: getNullable(iv.value),
    }))

    const response: { bulkEditVotes: MutationResponseModelType } = await mutateBulkEditVotes({
      issueId: id,
      votes,
    })
    setLoading(false)
    if (hasMutationErrors(response)) {
      setToast(displayMutationError(response))
      return
    }
    setToast({ variant: 'success', message: 'Votes saved' })
    history.push(`/votes/${id}`)
  }, [mutateBulkEditVotes, id, issueVotes, setToast, history, setShowIncomplete, setLoading])

  const visibleVotes = useMemo(() => {
    if (!showIncomplete) return issueVotes
    return issueVotes.filter((v) => !v.party || !v.value)
  }, [issueVotes, showIncomplete])

  const columns: Array<Column<IssueVote>> = useMemo(
    () => [
      {
        title: 'Legislator',
        field: 'name',
      },
      {
        title: 'Party',
        field: 'party',
        lookup: Party,
        filterComponent: SelectFilter,
        render: function EditParty(rowData): React.ReactElement {
          return (
            <Autocomplete
              options={['', ...Object.values(Party).map((p) => p.toString())]}
              value={rowData.party || ''}
              getOptionLabel={(option) => option}
              getOptionSelected={(option, value) => option === value}
              disableClearable
              onChange={(_, value: string) => {
                upsertRow({
                  ...rowData,
                  party: (value as Party) || null,
                })
              }}
              renderInput={(params) => {
                return <TextField variant="standard" {...params} />
              }}
            />
          )
        },
      },
      {
        title: 'Vote',
        field: 'value',
        lookup: VoteValue,
        filterComponent: SelectFilter,
        render: function EditParty(rowData): React.ReactElement {
          return (
            <Autocomplete
              options={['', ...Object.values(VoteValue).map((v) => v.toString())]}
              value={rowData.value || ''}
              getOptionLabel={(option) => option}
              getOptionSelected={(option, value) => option === value}
              disableClearable
              blurOnSelect
              onChange={(event, value: string) => {
                upsertRow({
                  ...rowData,
                  value: (value as VoteValue) || null,
                })
              }}
              renderInput={(params) => {
                return <TextField variant="standard" {...params} />
              }}
            />
          )
        },
      },
    ],
    [upsertRow],
  )

  if (detailsLoading && !issue) {
    return <LoadingContainer loading={detailsLoading} />
  }

  if (!issue) {
    return <NotFound />
  }

  return (
    <LoadingContainer loading={detailsLoading}>
      <FullHeightPage>
        <PageHeader
          title="Edit votes"
          breadcrumbs={[
            { label: 'Votes', link: Paths.VotesList },
            { label: issue.legislationNumber || '', link: `/votes/${issue.id}` },
            { label: 'Edit votes' },
          ]}
        >
          <ActionButtonContainer>
            <Button variant="contained" onClick={() => setShowIncomplete((current) => !current)}>
              {showIncomplete ? 'Show all' : 'Show incomplete'}
            </Button>
            <Button variant="contained" disabled={loading} onClick={handleAddByTerm}>
              Add by term
            </Button>
            <Button
              variant="contained"
              disabled={loading}
              onClick={() => {
                openDrawer(
                  <FindLegislatorForm
                    onComplete={closeDrawer}
                    onAddLegislator={handleAddIndividual}
                  />,
                )
              }}
            >
              Add individual
            </Button>
          </ActionButtonContainer>
        </PageHeader>
        <ScrollablePageContents>
          <MaterialTable
            data={visibleVotes}
            columns={columns}
            options={{
              search: false,
              filtering: true,
              toolbar: false,
              debounceInterval: 200,
              pageSizeOptions: [10, 50, 100, 500],
              pageSize: 10,
            }}
            style={{ boxShadow: 'none' }}
            localization={{ header: { actions: '' } }}
            actions={[
              {
                icon: 'delete',
                tooltip: 'Remove',
                onClick: (event, d): void => handleRemoveRow(rowDatum(d)),
              },
            ]}
          />
        </ScrollablePageContents>
        <PageFooter>
          <ActionButtonContainer>
            <Button
              variant="contained"
              onClick={() => {
                history.push(`/votes/${issue.id}`)
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" disabled={loading} onClick={handleSave}>
              Save
            </Button>
          </ActionButtonContainer>
        </PageFooter>
      </FullHeightPage>
    </LoadingContainer>
  )
}

export default observer(EditVotesPage)
