/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from 'mobx-state-tree'

/**
 * Typescript enum
 */

export enum Gender {
  Male = 'Male',
  Female = 'Female',
  Other = 'Other',
}

/**
 * Gender
 */
export const GenderEnumType = types.enumeration('Gender', ['Male', 'Female', 'Other'])
