import * as React from 'react'
import { Column, EditCellColumnDef } from 'material-table'
import DateRangePicker from '../../forms/DateRangePicker'

export type DateRangeFilterProps<T extends object = {}> = {
  columnDef: Column<T>
  onFilterChanged: (rowId: string, value: any) => void
}

const DateRangeFilter = <T extends object = {}>({
  columnDef,
  onFilterChanged,
}: DateRangeFilterProps<T>) => {
  const cellDef: EditCellColumnDef = columnDef as EditCellColumnDef
  return (
    <DateRangePicker
      value={cellDef.tableData.filterValue || []}
      onChange={(dateRange): void => {
        onFilterChanged(String(cellDef.tableData.id), dateRange)
      }}
    />
  )
}

export default DateRangeFilter
