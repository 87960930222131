import { Button, TextField } from '@material-ui/core'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { FieldGroup, FormButtonGroup, FormContainer, FormSection } from '../common/forms'
import { observer } from 'mobx-react-lite'
import { LoadingContainer } from '../common/utils'
import { useQuery } from '../../models/reactUtils'
import {
  ImportGroupListResponseModelType,
  importGroupListSelector,
  ImportGroupModelType,
} from '../../models'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  generateLegislatorUploadTemplate,
  LegislatorUploadTemplateColumn,
} from '../../utilities/spreadsheets'

type ImportGroupsListResponse = { importGroups: ImportGroupListResponseModelType }

export interface DownloadImportGroupTemplateFormProps {
  name: string
  additionalColumns?: Array<LegislatorUploadTemplateColumn>
  onComplete: () => any
}

const DownloadImportGroupTemplateForm: React.FC<DownloadImportGroupTemplateFormProps> = ({
  name,
  additionalColumns,
  onComplete,
}) => {
  const { data, loading } = useQuery<ImportGroupsListResponse>((store) => {
    return store.queryImportGroups({}, importGroupListSelector)
  })
  const [selectedImportGroup, setSelectedImportGroupId] = useState<ImportGroupModelType | null>(
    null,
  )
  const [importGroups, setImportGroups] = useState<Array<ImportGroupModelType>>([])

  useEffect(() => {
    if (data?.importGroups?.records) {
      setImportGroups(data.importGroups.records.toJS())
    }
  }, [data, setImportGroups])

  const handleDownloadClick = useCallback(() => {
    generateLegislatorUploadTemplate(
      selectedImportGroup?.legislators || [],
      `${selectedImportGroup?.name || ''} ${name}`,
      additionalColumns,
    )
    onComplete()
  }, [additionalColumns, name, onComplete, selectedImportGroup])

  return (
    <FormContainer title="Add import group">
      <LoadingContainer loading={loading}>
        <FormSection title="">
          <FieldGroup>
            <Autocomplete
              fullWidth
              value={selectedImportGroup}
              options={importGroups}
              onChange={(_, value): void => {
                setSelectedImportGroupId(value)
              }}
              getOptionLabel={(option): string =>
                importGroups.find((ig) => ig.id === option.id)?.name || ''
              }
              renderInput={(params): React.ReactElement => (
                <TextField {...params} variant="standard" label="Import group" />
              )}
            />
          </FieldGroup>
        </FormSection>

        <FormButtonGroup>
          <Button
            variant="contained"
            onClick={() => {
              onComplete()
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!selectedImportGroup}
            onClick={handleDownloadClick}
          >
            Download
          </Button>
        </FormButtonGroup>
      </LoadingContainer>
    </FormContainer>
  )
}

export default observer(DownloadImportGroupTemplateForm)
