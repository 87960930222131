/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from 'mobx-state-tree'

/**
 * Typescript enum
 */

export enum IssueResult {
  Passed = 'Passed',
  Failed = 'Failed',
}

/**
 * IssueResult
 */
export const IssueResultEnumType = types.enumeration('IssueResult', ['Passed', 'Failed'])
