/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from 'mobx-state-tree'

/**
 * Typescript enum
 */

export enum CategoryOrderColumns {
  CreatedAt = 'CreatedAt',
  UpdatedAt = 'UpdatedAt',
  Title = 'Title',
}

/**
 * CategoryOrderColumns
 */
export const CategoryOrderColumnsEnumType = types.enumeration('CategoryOrderColumns', [
  'CreatedAt',
  'UpdatedAt',
  'Title',
])
