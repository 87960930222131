import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import * as React from 'react'
import { FormDrawer } from 'src/components'
import { DialogContentProps, ModalContext } from './context'

export interface ModalProviderProps {
  children: JSX.Element | Array<JSX.Element>
}

interface ModalProviderState {
  drawerOpen: boolean
  drawerContent: any
  dialogOpen: boolean
  dialogContent?: DialogContentProps
}

const initialState: ModalProviderState = {
  drawerOpen: false,
  drawerContent: <div />,
  dialogOpen: false,
}

const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [state, setState] = React.useState<ModalProviderState>(initialState)

  const handleOpenDialog = (dialogContent: DialogContentProps): void =>
    setState({ ...state, dialogContent, dialogOpen: true })
  const handleCloseDialog = (): void => setState({ ...state, dialogOpen: false })

  const handleOpenDrawer = (drawerContent: any): void =>
    setState({ ...state, drawerContent, drawerOpen: true })
  const handleCloseDrawer = (): void => setState({ ...state, drawerOpen: false })

  const handleCloseAll = (): void => setState(initialState)
  return (
    <ModalContext.Provider
      value={{
        openDrawer: handleOpenDrawer,
        closeDrawer: handleCloseDrawer,
        openDialog: handleOpenDialog,
        closeDialog: handleCloseDialog,
        closeAll: handleCloseAll,
      }}
    >
      {children}
      <FormDrawer open={state.drawerOpen} onClose={handleCloseDialog}>
        {state.drawerContent}
      </FormDrawer>

      <Dialog
        open={state.dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{state.dialogContent?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {state.dialogContent?.body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {state.dialogContent?.cancelText || 'Cancel'}
          </Button>
          <Button
            onClick={(): void => {
              state.dialogContent?.onConfirm()
              handleCloseDialog()
            }}
            color="primary"
            autoFocus
          >
            {state.dialogContent?.confirmText || 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    </ModalContext.Provider>
  )
}

export default ModalProvider
