/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from 'mobx-state-tree'

/**
 * Typescript enum
 */

export enum OrderByDirection {
  Asc = 'Asc',
  Desc = 'Desc',
}

/**
 * OrderByDirection
 */
export const OrderByDirectionEnumType = types.enumeration('OrderByDirection', ['Asc', 'Desc'])
