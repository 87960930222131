/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { BlurbModel, BlurbModelType } from './BlurbModel'
import { BlurbModelSelector } from './BlurbModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  blurb: BlurbModelType
}

/**
 * BlurbUpdateResponseBase
 * auto generated base class for the model BlurbUpdateResponseModel.
 */
export const BlurbUpdateResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('BlurbUpdateResponse')
    .props({
      __typename: types.optional(types.literal('BlurbUpdateResponse'), 'BlurbUpdateResponse'),
      success: types.union(types.undefined, types.boolean),
      message: types.union(types.undefined, types.string),
      blurb: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => BlurbModel))),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class BlurbUpdateResponseModelSelector extends QueryBuilder {
  get success() {
    return this.__attr(`success`)
  }
  get message() {
    return this.__attr(`message`)
  }
  blurb(
    builder?: string | BlurbModelSelector | ((selector: BlurbModelSelector) => BlurbModelSelector),
  ) {
    return this.__child(`blurb`, BlurbModelSelector, builder)
  }
}
export function selectFromBlurbUpdateResponse() {
  return new BlurbUpdateResponseModelSelector()
}

export const blurbUpdateResponseModelPrimitives = selectFromBlurbUpdateResponse().success.message
