/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { SubCategoryModel, SubCategoryModelType } from './SubCategoryModel'
import { SubCategoryModelSelector } from './SubCategoryModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  subCategory: SubCategoryModelType
}

/**
 * SubCategoryUpdateResponseBase
 * auto generated base class for the model SubCategoryUpdateResponseModel.
 */
export const SubCategoryUpdateResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('SubCategoryUpdateResponse')
    .props({
      __typename: types.optional(
        types.literal('SubCategoryUpdateResponse'),
        'SubCategoryUpdateResponse',
      ),
      success: types.union(types.undefined, types.boolean),
      message: types.union(types.undefined, types.string),
      subCategory: types.union(
        types.undefined,
        types.null,
        MSTGQLRef(types.late((): any => SubCategoryModel)),
      ),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class SubCategoryUpdateResponseModelSelector extends QueryBuilder {
  get success() {
    return this.__attr(`success`)
  }
  get message() {
    return this.__attr(`message`)
  }
  subCategory(
    builder?:
      | string
      | SubCategoryModelSelector
      | ((selector: SubCategoryModelSelector) => SubCategoryModelSelector),
  ) {
    return this.__child(`subCategory`, SubCategoryModelSelector, builder)
  }
}
export function selectFromSubCategoryUpdateResponse() {
  return new SubCategoryUpdateResponseModelSelector()
}

export const subCategoryUpdateResponseModelPrimitives = selectFromSubCategoryUpdateResponse()
  .success.message
