import React from 'react'
import { Box, BoxProps, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
}))

export type FormSectionProps = BoxProps & {
  title: string
}

const FormSection: React.FC<FormSectionProps> = ({ children, title, ...props }) => {
  const styles = useStyles()
  return (
    <Box className={styles.container} {...props}>
      <Typography variant="h6">{title}</Typography>
      <Box>{children}</Box>
    </Box>
  )
}

export default FormSection
