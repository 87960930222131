import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    '& .MuiFormControl-root': {
      '& input': {
        cursor: 'default',
      },
      flex: 1,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}))

export interface FieldGroupProps {
  children: JSX.Element | Array<JSX.Element>
  hidden?: boolean
}

const FieldGroup: React.FC<FieldGroupProps> = ({ children, hidden }) => {
  const classes = useStyles()
  if (hidden) return null
  return (
    <div className={classes.root} hidden={hidden}>
      {children}
    </div>
  )
}

export default FieldGroup
