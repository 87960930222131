import { useCallback, useState } from 'react'

export type UseDisclosureHookValue = {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  onToggle: () => void
}

export function useDisclosure(initialState = false): UseDisclosureHookValue {
  const [isOpen, setIsOpen] = useState(initialState)

  const onOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const onToggle = useCallback(() => {
    setIsOpen((v) => !v)
  }, [])

  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
  }
}
