import { createStoreContext, createUseQueryHook } from 'mst-gql'
import { Query } from 'mst-gql/dist/Query'
import * as React from 'react'
import { useEffect } from 'react'
import { Maybe } from '../utilities/types'
import { RootStoreType } from './RootStore'

export const StoreContext = createStoreContext<RootStoreType>(React)

export const useQuery = createUseQueryHook<RootStoreType>(StoreContext, React)

export const useQueryPoll = (query: Maybe<Query>) => {
  useEffect(() => {
    const interval = setInterval(() => {
      query?.refetch()
    }, 2000)

    return () => {
      clearInterval(interval)
    }
  }, [query])
}
