/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from 'mobx'
import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { AssignedLayerModel, AssignedLayerModelType } from './AssignedLayerModel'
import { AssignedLayerModelSelector } from './AssignedLayerModel.base'
import { BlurbModel, BlurbModelType } from './BlurbModel'
import { BlurbModelSelector } from './BlurbModel.base'
import { CategoryModel, CategoryModelType } from './CategoryModel'
import { CategoryModelSelector } from './CategoryModel.base'
import { IssueModel, IssueModelType } from './IssueModel'
import { IssueModelSelector } from './IssueModel.base'
import { SubCategoryModel, SubCategoryModelType } from './SubCategoryModel'
import { SubCategoryModelSelector } from './SubCategoryModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  category: CategoryModelType
  subCategory: SubCategoryModelType
  assignedLayers: IObservableArray<AssignedLayerModelType>
  blurbs: IObservableArray<BlurbModelType>
  issue: IssueModelType
}

/**
 * IssueTemplateBase
 * auto generated base class for the model IssueTemplateModel.
 */
export const IssueTemplateModelBase = withTypedRefs<Refs>()(
  ModelBase.named('IssueTemplate')
    .props({
      __typename: types.optional(types.literal('IssueTemplate'), 'IssueTemplate'),
      id: types.identifier,
      createdAt: types.union(types.undefined, types.frozen()),
      updatedAt: types.union(types.undefined, types.frozen()),
      category: types.union(types.undefined, MSTGQLRef(types.late((): any => CategoryModel))),
      subCategory: types.union(
        types.undefined,
        types.null,
        MSTGQLRef(types.late((): any => SubCategoryModel)),
      ),
      assignedLayers: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => AssignedLayerModel))),
      ),
      blurbs: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => BlurbModel))),
      ),
      issue: types.union(types.undefined, MSTGQLRef(types.late((): any => IssueModel))),
      issueId: types.union(types.undefined, types.string),
      title: types.union(types.undefined, types.string),
      summary: types.union(types.undefined, types.string),
      categoryId: types.union(types.undefined, types.string),
      subCategoryId: types.union(types.undefined, types.null, types.string),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class IssueTemplateModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  get issueId() {
    return this.__attr(`issueId`)
  }
  get title() {
    return this.__attr(`title`)
  }
  get summary() {
    return this.__attr(`summary`)
  }
  get categoryId() {
    return this.__attr(`categoryId`)
  }
  get subCategoryId() {
    return this.__attr(`subCategoryId`)
  }
  category(
    builder?:
      | string
      | CategoryModelSelector
      | ((selector: CategoryModelSelector) => CategoryModelSelector),
  ) {
    return this.__child(`category`, CategoryModelSelector, builder)
  }
  subCategory(
    builder?:
      | string
      | SubCategoryModelSelector
      | ((selector: SubCategoryModelSelector) => SubCategoryModelSelector),
  ) {
    return this.__child(`subCategory`, SubCategoryModelSelector, builder)
  }
  assignedLayers(
    builder?:
      | string
      | AssignedLayerModelSelector
      | ((selector: AssignedLayerModelSelector) => AssignedLayerModelSelector),
  ) {
    return this.__child(`assignedLayers`, AssignedLayerModelSelector, builder)
  }
  blurbs(
    builder?: string | BlurbModelSelector | ((selector: BlurbModelSelector) => BlurbModelSelector),
  ) {
    return this.__child(`blurbs`, BlurbModelSelector, builder)
  }
  issue(
    builder?: string | IssueModelSelector | ((selector: IssueModelSelector) => IssueModelSelector),
  ) {
    return this.__child(`issue`, IssueModelSelector, builder)
  }
}
export function selectFromIssueTemplate() {
  return new IssueTemplateModelSelector()
}

export const issueTemplateModelPrimitives = selectFromIssueTemplate().createdAt.updatedAt.issueId
  .title.summary.categoryId.subCategoryId
