/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from 'mobx'
import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { AssignedLayerModel, AssignedLayerModelType } from './AssignedLayerModel'
import { AssignedLayerModelSelector } from './AssignedLayerModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  records: IObservableArray<AssignedLayerModelType>
}

/**
 * AssignedLayerListResponseBase
 * auto generated base class for the model AssignedLayerListResponseModel.
 */
export const AssignedLayerListResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('AssignedLayerListResponse')
    .props({
      __typename: types.optional(
        types.literal('AssignedLayerListResponse'),
        'AssignedLayerListResponse',
      ),
      records: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => AssignedLayerModel))),
      ),
      count: types.union(types.undefined, types.number),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class AssignedLayerListResponseModelSelector extends QueryBuilder {
  get count() {
    return this.__attr(`count`)
  }
  records(
    builder?:
      | string
      | AssignedLayerModelSelector
      | ((selector: AssignedLayerModelSelector) => AssignedLayerModelSelector),
  ) {
    return this.__child(`records`, AssignedLayerModelSelector, builder)
  }
}
export function selectFromAssignedLayerListResponse() {
  return new AssignedLayerListResponseModelSelector()
}

export const assignedLayerListResponseModelPrimitives = selectFromAssignedLayerListResponse().count
