import { Button } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import * as React from 'react'
import { useMemo } from 'react'
import * as Yup from 'yup'
import { useToast } from '../common/utils/toast'
import { useStore } from '../../getMstGql'
import {
  FieldGroup,
  FormButtonGroup,
  FormContainer,
  FormDateField,
  FormSection,
  FormSelectField,
  FormTextField,
} from '../common/forms'
import {
  Chamber,
  GovernmentLevel,
  IssueCreationResponseModelSelector,
  issueModelPrimitives,
  IssueModelType,
  IssueResult,
  IssueUpdateResponseModelType,
  State,
} from '../../models'
import { displayMutationError, hasMutationErrors } from '../../utilities/errors'
import { UpdateIssueInput } from '../../models/RootStore.base'
import { DateTime } from 'luxon'
import { Maybe } from '../../utilities/types'

const getInitialFormValues = (issue: IssueModelType): Partial<UpdateIssueInput> => ({
  date: DateTime.fromISO(issue.date),
  chamber: (issue.chamber || undefined) as Maybe<Chamber>,
  legislationNumber: issue.legislationNumber,
  state: (issue.state || undefined) as Maybe<State>,
  description: issue.description,
  url: issue.url,
  rollCallNumber: issue.rollCallNumber,
  result: (issue.result || undefined) as Maybe<IssueResult>,
  level: (issue.level || undefined) as Maybe<GovernmentLevel>,
})

const schema = Yup.object().shape({
  date: Yup.object(),
  chamber: Yup.string().required('Required'),
  legislationNumber: Yup.string().required('Required'),
  state: Yup.string().when('level', {
    is: GovernmentLevel.State,
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  description: Yup.string(),
  url: Yup.string().required('Required'),
  rollCallNumber: Yup.number().required('Required'),
  result: Yup.string().required('Required'),
  level: Yup.string().required('Required'),
})

export interface EditIssueFormProps {
  onComplete: () => any
  issue: IssueModelType
}

const EditIssueForm: React.FC<EditIssueFormProps> = ({ onComplete, issue }) => {
  const store = useStore()
  const { setToast } = useToast()

  const initialFormValues = useMemo(() => getInitialFormValues(issue), [issue])

  return (
    <FormContainer title="Edit Vote">
      <Formik
        initialValues={initialFormValues}
        validationSchema={schema}
        validateOnBlur={false}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true)

          const response = (await store
            .mutateUpdateIssue(
              { id: issue.id, input: values as UpdateIssueInput },
              new IssueCreationResponseModelSelector().message.success
                .issue(issueModelPrimitives)
                .toString(),
            )
            .currentPromise()) as { updateIssue: IssueUpdateResponseModelType }

          if (hasMutationErrors(response)) {
            setToast(displayMutationError(response))
            return
          }

          setToast({
            message: `Vote ${values.legislationNumber} has been updated!`,
            variant: 'success',
          })
          actions.resetForm()
          onComplete()
        }}
      >
        {(props: FormikProps<any>) => (
          <React.Fragment>
            <FormSection title="Branch">
              <FieldGroup>
                <FormSelectField
                  label="Level"
                  name="level"
                  options={Object.values(GovernmentLevel).map((value) => ({
                    value,
                    label: value,
                  }))}
                  clearable={false}
                />
              </FieldGroup>
              <FieldGroup>
                <FormSelectField
                  label="Chamber"
                  name="chamber"
                  options={Object.values(Chamber).map((value) => ({
                    value,
                    label: value,
                  }))}
                  clearable={false}
                />
              </FieldGroup>
              <FieldGroup hidden={props.values.level !== GovernmentLevel.State}>
                <FormSelectField
                  label="State"
                  name="state"
                  options={Object.values(State).map((value) => ({
                    value,
                    label: value,
                  }))}
                />
              </FieldGroup>
            </FormSection>

            <FormSection title="Details">
              <FieldGroup>
                <FormTextField label="URL" name="url" />
              </FieldGroup>
              <FieldGroup>
                <FormDateField label="Date" name="date" />
              </FieldGroup>
              <FieldGroup>
                <FormSelectField
                  label="Result"
                  name="result"
                  options={Object.values(IssueResult).map((value) => ({
                    value,
                    label: value,
                  }))}
                  clearable={false}
                />
              </FieldGroup>
              <FieldGroup>
                <FormTextField label="Bill title" name="legislationNumber" />
              </FieldGroup>
              <FieldGroup>
                <FormTextField label="Roll #" name="rollCallNumber" type="number" />
              </FieldGroup>
              <FieldGroup>
                <FormTextField
                  label="Description"
                  name="description"
                  InputProps={{ rows: 3 }}
                  multiline
                />
              </FieldGroup>
            </FormSection>

            <FormButtonGroup>
              <Button
                variant="contained"
                onClick={() => {
                  props.resetForm()
                  onComplete()
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={props.isSubmitting}
                onClick={() => {
                  return props.submitForm()
                }}
              >
                Save
              </Button>
            </FormButtonGroup>
          </React.Fragment>
        )}
      </Formik>
    </FormContainer>
  )
}

export default EditIssueForm
