import { Button } from '@material-ui/core'
import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Paths } from '../../constants/routes'
import { useQuery } from '../../models/reactUtils'
import { useToast } from '../../components/common/utils/toast'
import { useModal } from '../../components/common/utils/modal'
import { LoadingContainer, NotFound } from '../../components/common/utils'
import {
  ActionButtonContainer,
  FieldGroup,
  PageContents,
  PageHeader,
  ReadOnlyForm,
  ReadOnlyTextField,
} from '../../components/common'
import { LayerModelType, MutationResponseModelType } from '../../models'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../getMstGql'
import { displayMutationError, hasMutationErrors } from '../../utilities/errors'

export interface LayerDetailsPageParams {
  id: string
}

const LayerDetailsPage = () => {
  const { id } = useParams<LayerDetailsPageParams>()
  const { loading, data } = useQuery<{ layer: LayerModelType }>((store) => store.queryLayer({ id }))
  const { mutateDeleteLayer } = useStore()
  const history = useHistory()
  const { setToast } = useToast()
  const { openDialog } = useModal()

  const layer = useMemo(() => {
    return data?.layer
  }, [data])

  const handleDeleteLayer = useCallback(async () => {
    const response = (await mutateDeleteLayer({ id }).currentPromise()) as {
      deleteLayer: MutationResponseModelType
    }

    if (hasMutationErrors(response)) {
      setToast(displayMutationError(response))
    } else {
      setToast('Deleted layer.')
      history.push(Paths.LayersList)
    }
  }, [id, mutateDeleteLayer, setToast, history])

  if (loading && !layer) {
    return <LoadingContainer loading={loading} />
  }

  if (!layer) {
    return <NotFound />
  }

  return (
    <LoadingContainer loading={loading && !layer}>
      <PageHeader
        title={layer.name || ''}
        breadcrumbs={[{ link: Paths.LayersList, label: 'Layers' }, { label: layer.name || '' }]}
      >
        <ActionButtonContainer>
          <Button
            variant="outlined"
            color="secondary"
            style={{ marginRight: 7 }}
            onClick={() => {
              openDialog({
                title: 'Delete this record?',
                body: 'This is permanent. Deleted records cannot be restored.',
                onConfirm: handleDeleteLayer,
              })
            }}
          >
            Delete
          </Button>
        </ActionButtonContainer>
      </PageHeader>
      <PageContents>
        <ReadOnlyForm title="General">
          <FieldGroup>
            <ReadOnlyTextField label="Layer" value={layer.name} />
          </FieldGroup>
        </ReadOnlyForm>
      </PageContents>
    </LoadingContainer>
  )
}

export default observer(LayerDetailsPage)
