import { Button } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import * as React from 'react'
import * as Yup from 'yup'
import { useToast } from '../common/utils/toast'
import { useStore } from '../../getMstGql'
import {
  FieldGroup,
  FormButtonGroup,
  FormContainer,
  FormSection,
  FormTextField,
} from '../common/forms'
import { CategoryModelType, SubCategoryCreationResponseModelType } from '../../models'
import { displayMutationError, hasMutationErrors } from '../../utilities/errors'

const initialFormValues = {
  title: '',
}

const schema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
})

export interface AddSubCategoryFormProps {
  category: CategoryModelType
  onComplete: () => any
}

const AddSubCategoryForm: React.FC<AddSubCategoryFormProps> = ({ category, onComplete }) => {
  const store = useStore()
  const { setToast } = useToast()

  return (
    <FormContainer title="Add Subcategory">
      <Formik
        initialValues={initialFormValues}
        validationSchema={schema}
        validateOnBlur={false}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true)

          const { title } = values
          const response = (await store
            .mutateCreateSubCategory({
              input: {
                categoryId: category.id,
                title,
              },
            })
            .currentPromise()) as { createSubCategory: SubCategoryCreationResponseModelType }
          actions.setSubmitting(false)

          if (hasMutationErrors(response)) {
            setToast(displayMutationError(response))
            return
          }

          setToast({
            message: `Category ${title} has been created!`,
            variant: 'success',
          })
          actions.resetForm()
          onComplete()
        }}
      >
        {(props: FormikProps<any>) => (
          <React.Fragment>
            <FormSection title="">
              <FieldGroup>
                <FormTextField label="Title" name="title" />
              </FieldGroup>
            </FormSection>

            <FormButtonGroup>
              <Button
                variant="contained"
                onClick={() => {
                  props.resetForm()
                  onComplete()
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={props.isSubmitting}
                onClick={() => {
                  return props.submitForm()
                }}
              >
                Save
              </Button>
            </FormButtonGroup>
          </React.Fragment>
        )}
      </Formik>
    </FormContainer>
  )
}

export default AddSubCategoryForm
