/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { RootStoreType } from './index'

/**
 * LegislatorIdentityBase
 * auto generated base class for the model LegislatorIdentityModel.
 */
export const LegislatorIdentityModelBase = ModelBase.named('LegislatorIdentity')
  .props({
    __typename: types.optional(types.literal('LegislatorIdentity'), 'LegislatorIdentity'),
    bioguide: types.union(types.undefined, types.null, types.string),
    thomas: types.union(types.undefined, types.null, types.string),
    lis: types.union(types.undefined, types.null, types.string),
    govTrack: types.union(types.undefined, types.null, types.integer),
    openSecrets: types.union(types.undefined, types.null, types.string),
    voteSmart: types.union(types.undefined, types.null, types.integer),
    fec: types.union(types.undefined, types.null, types.array(types.string)),
    cspan: types.union(types.undefined, types.null, types.integer),
    wikipedia: types.union(types.undefined, types.null, types.string),
    houseHistory: types.union(types.undefined, types.null, types.integer),
    ballotpedia: types.union(types.undefined, types.null, types.string),
    mapLight: types.union(types.undefined, types.null, types.integer),
    icpsr: types.union(types.undefined, types.null, types.integer),
    wikidata: types.union(types.undefined, types.null, types.string),
    googleEntityId: types.union(types.undefined, types.null, types.string),
  })
  .views((self) => ({
    get store() {
      return self.__getStore<RootStoreType>()
    },
  }))

export class LegislatorIdentityModelSelector extends QueryBuilder {
  get bioguide() {
    return this.__attr(`bioguide`)
  }
  get thomas() {
    return this.__attr(`thomas`)
  }
  get lis() {
    return this.__attr(`lis`)
  }
  get govTrack() {
    return this.__attr(`govTrack`)
  }
  get openSecrets() {
    return this.__attr(`openSecrets`)
  }
  get voteSmart() {
    return this.__attr(`voteSmart`)
  }
  get fec() {
    return this.__attr(`fec`)
  }
  get cspan() {
    return this.__attr(`cspan`)
  }
  get wikipedia() {
    return this.__attr(`wikipedia`)
  }
  get houseHistory() {
    return this.__attr(`houseHistory`)
  }
  get ballotpedia() {
    return this.__attr(`ballotpedia`)
  }
  get mapLight() {
    return this.__attr(`mapLight`)
  }
  get icpsr() {
    return this.__attr(`icpsr`)
  }
  get wikidata() {
    return this.__attr(`wikidata`)
  }
  get googleEntityId() {
    return this.__attr(`googleEntityId`)
  }
}
export function selectFromLegislatorIdentity() {
  return new LegislatorIdentityModelSelector()
}

export const legislatorIdentityModelPrimitives = selectFromLegislatorIdentity().bioguide.thomas.lis
  .govTrack.openSecrets.voteSmart.fec.cspan.wikipedia.houseHistory.ballotpedia.mapLight.icpsr
  .wikidata.googleEntityId
