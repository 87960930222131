import { Duration } from 'luxon'
import { flow, getEnv, Instance } from 'mobx-state-tree'
import { Query } from 'mst-gql'
import Authentication from 'src/models/Authentication'
import { RefreshTokenResponseModelType } from 'src/models/RefreshTokenResponseModel'
import { hasMutationErrors } from '../utilities/errors'
import { MutationResponseModelType } from './MutationResponseModel'
import { RootStoreBase, UserLoginArgs } from './RootStore.base'
import {
  UserLoginResponseModelSelector,
  UserLoginResponseModelType,
} from './UserLoginResponseModel'
import { userModelPrimitives, UserModelType } from './UserModel'

export interface RootStoreType extends Instance<typeof RootStore> {}

const REFRESH_API_TOKEN_INTERVAL = Duration.fromObject({ minutes: 115 }).as('millisecond')

export const RootStore = RootStoreBase.props({
  authentication: Authentication,
  initializing: false,
  initialized: false,
})
  .actions((self) => ({
    refreshTokenAndSetTimeOut() {
      const query = self.mutateRefreshToken()

      query.then((response) => {
        const {
          refreshToken: { token },
        } = response
        if (token) {
          self.authentication.setToken(token)
          setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            self.refreshTokenAndSetTimeOut()
          }, REFRESH_API_TOKEN_INTERVAL)
        }
        return response
      })

      return query
    },
  }))
  .actions((self) => ({
    initializeApp: flow(function* initializeApp() {
      self.initializing = true
      try {
        const refreshTokenResponse = (yield self.mutateRefreshToken().currentPromise()) as {
          refreshToken: RefreshTokenResponseModelType
        }
        const token = refreshTokenResponse.refreshToken.token
        if (!token) {
          self.initializing = false
          self.initialized = true
          return
        }
        self.authentication.setToken(token.toString())
        setTimeout(() => {
          self.refreshTokenAndSetTimeOut()
        }, REFRESH_API_TOKEN_INTERVAL)
        const whoAmIResponse = (yield self.queryWhoAmI().currentPromise()) as {
          whoAmI: UserModelType | undefined
        }

        self.authentication.currentUser = whoAmIResponse.whoAmI || null
      } catch (error) {
        console.error(`Error initializing app: ${error}`)
        throw error
      } finally {
        self.initializing = false
        self.initialized = true
      }
    }),
  }))
  .actions((self) => ({
    login: flow(function* login(input: UserLoginArgs) {
      try {
        const query = self.mutateLogin(
          { input },
          new UserLoginResponseModelSelector()
            .user(userModelPrimitives)
            .success.message.token.toString(),
        )
        const response = (yield query.currentPromise()) as { login: UserLoginResponseModelType }
        if (hasMutationErrors(response)) {
          return response.login
        }
        if (response.login.token) {
          self.authentication.setToken(String(response.login.token))
        }
        if (response.login.user) {
          self.authentication.currentUser = response.login.user
        }
        return response.login
      } catch (error) {
        return {
          success: false,
          message: error.message,
        }
      }
    }),
    logout(): Query<{ logout: MutationResponseModelType }> {
      const query = self.mutateLogout()
      query.then(() => {
        self.authentication.token = null
        self.authentication.currentUser = null
        getEnv(self).gqlHttpClient.setHeaders({ authorization: '' })
      })
      return query
    },
    refetchCurrentUser: flow(function* () {
      const whoAmIResponse = (yield self.queryWhoAmI().currentPromise()) as {
        whoAmI: UserModelType | undefined
      }
      self.authentication.currentUser = whoAmIResponse.whoAmI || null
    }),
  }))
