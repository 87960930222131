import * as React from 'react'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  ActionButtonContainer,
  AddIssueTemplateForm,
  Button,
  IssueTemplatesTable,
  PageContents,
  PageHeader,
  useModal,
} from 'src/components'
import { IssueTemplateModelType } from '../../models'

const IssueTemplatesListPage: React.FC = () => {
  const history = useHistory()
  const { openDrawer, closeDrawer } = useModal()
  const [cacheKey, setCacheKey] = useState(Date.now())
  const updateCacheKey = useCallback(() => setCacheKey(Date.now()), [setCacheKey])

  const handleAddTemplate = useCallback(
    (willNavigate?: boolean) => {
      closeDrawer()
      if (!willNavigate) {
        updateCacheKey()
      }
    },
    [closeDrawer, updateCacheKey],
  )

  const handleRowClick = React.useCallback(
    (row: IssueTemplateModelType): void => {
      history.push(`/templates/${row.id}`)
    },
    [history],
  )

  return (
    <>
      <PageHeader title="Issue templates" breadcrumbs={[{ label: 'Issue templates' }]}>
        <ActionButtonContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={() => openDrawer(<AddIssueTemplateForm onComplete={handleAddTemplate} />)}
          >
            Create
          </Button>
        </ActionButtonContainer>
      </PageHeader>
      <PageContents>
        <IssueTemplatesTable cacheKey={cacheKey} onRowClick={handleRowClick} />
      </PageContents>
    </>
  )
}

export default IssueTemplatesListPage
