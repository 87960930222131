/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from 'mobx'
import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { ChamberEnumType } from './ChamberEnum'
import { GovernmentLevelEnumType } from './GovernmentLevelEnum'
import { IssueResultEnumType } from './IssueResultEnum'
import { StateEnumType } from './StateEnum'
import { VoteModel, VoteModelType } from './VoteModel'
import { VoteModelSelector } from './VoteModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  votes: IObservableArray<VoteModelType>
}

/**
 * IssueBase
 * auto generated base class for the model IssueModel.
 */
export const IssueModelBase = withTypedRefs<Refs>()(
  ModelBase.named('Issue')
    .props({
      __typename: types.optional(types.literal('Issue'), 'Issue'),
      id: types.identifier,
      createdAt: types.union(types.undefined, types.frozen()),
      updatedAt: types.union(types.undefined, types.frozen()),
      sessionName: types.union(types.undefined, types.null, types.string),
      voteType: types.union(types.undefined, types.null, types.string),
      rawResult: types.union(types.undefined, types.null, types.string),
      result: types.union(types.undefined, IssueResultEnumType),
      votes: types.union(types.undefined, types.array(MSTGQLRef(types.late((): any => VoteModel)))),
      url: types.union(types.undefined, types.string),
      level: types.union(types.undefined, GovernmentLevelEnumType),
      chamber: types.union(types.undefined, ChamberEnumType),
      state: types.union(types.undefined, types.null, StateEnumType),
      legislationNumber: types.union(types.undefined, types.string),
      rollCallNumber: types.union(types.undefined, types.number),
      description: types.union(types.undefined, types.string),
      date: types.union(types.undefined, types.frozen()),
      issueTemplateId: types.union(types.undefined, types.null, types.string),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class IssueModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  get sessionName() {
    return this.__attr(`sessionName`)
  }
  get voteType() {
    return this.__attr(`voteType`)
  }
  get rawResult() {
    return this.__attr(`rawResult`)
  }
  get result() {
    return this.__attr(`result`)
  }
  get url() {
    return this.__attr(`url`)
  }
  get level() {
    return this.__attr(`level`)
  }
  get chamber() {
    return this.__attr(`chamber`)
  }
  get state() {
    return this.__attr(`state`)
  }
  get legislationNumber() {
    return this.__attr(`legislationNumber`)
  }
  get rollCallNumber() {
    return this.__attr(`rollCallNumber`)
  }
  get description() {
    return this.__attr(`description`)
  }
  get date() {
    return this.__attr(`date`)
  }
  get issueTemplateId() {
    return this.__attr(`issueTemplateId`)
  }
  votes(
    builder?: string | VoteModelSelector | ((selector: VoteModelSelector) => VoteModelSelector),
  ) {
    return this.__child(`votes`, VoteModelSelector, builder)
  }
}
export function selectFromIssue() {
  return new IssueModelSelector()
}

export const issueModelPrimitives = selectFromIssue().createdAt.updatedAt.sessionName.voteType
  .rawResult.result.url.level.chamber.state.legislationNumber.rollCallNumber.description.date
  .issueTemplateId
