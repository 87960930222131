import { DateTime } from 'luxon'
import { Maybe } from './types'

export const parseDate = (timestamp: number): DateTime => {
  return DateTime.fromMillis(timestamp)
}

export const formatTimestamp = (timestamp: number): string => {
  return parseDate(timestamp).toUTC().toLocaleString(DateTime.DATE_FULL)
}

export const formatDateString = (isoDate: string): string => {
  return DateTime.fromISO(isoDate).toUTC().toLocaleString(DateTime.DATE_FULL)
}

export const sortByDate = (a: string, b: string): number => {
  return DateTime.fromISO(a).toMillis() - DateTime.fromISO(b).toMillis()
}

export const filterAndSearchDate = (timestamp: number, filterString: string): boolean => {
  return formatTimestamp(timestamp).toLowerCase().includes(filterString.toLowerCase())
}
export type IDateRange = {
  start?: DateTime
  end?: DateTime
}

export function formatDateTime(dateTime: Maybe<DateTime>, fallback = ''): string {
  if (!dateTime || !dateTime.isValid) return fallback
  return dateTime.toFormat('M/d/yy')
}

export function formatDate(dateTime: Maybe<string>, fallback = ''): string {
  if (!dateTime) return fallback
  return formatDateTime(DateTime.fromISO(dateTime), fallback)
}

export function formatDateRange(dateRange: IDateRange): string {
  const startText = formatDateTime(dateRange?.start, 'Start')
  const endText = formatDateTime(dateRange?.end, 'End')
  return `${startText} - ${endText}`
}
