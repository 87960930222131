/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from 'mobx-state-tree'

/**
 * Typescript enum
 */

export enum GovernmentLevel {
  Federal = 'Federal',
  State = 'State',
}

/**
 * GovernmentLevel
 */
export const GovernmentLevelEnumType = types.enumeration('GovernmentLevel', ['Federal', 'State'])
