/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from 'mobx-state-tree'

/**
 * Typescript enum
 */

export enum TermsOrderColumns {
  StartDate = 'StartDate',
  EndDate = 'EndDate',
  Level = 'Level',
  Chamber = 'Chamber',
  State = 'State',
}

/**
 * TermsOrderColumns
 */
export const TermsOrderColumnsEnumType = types.enumeration('TermsOrderColumns', [
  'StartDate',
  'EndDate',
  'Level',
  'Chamber',
  'State',
])
