/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react'

export interface DialogContentProps {
  title: string
  body: string
  confirmText?: string
  cancelText?: string
  onConfirm: () => any
}

export interface ModalContextValue {
  openDrawer: (content: any) => void
  closeDrawer: () => void
  openDialog: (content: DialogContentProps) => void
  closeDialog: () => void
  closeAll: () => void
}

export const ModalContext = React.createContext<ModalContextValue>({
  openDrawer: () => {},
  openDialog: () => {},
  closeDrawer: () => {},
  closeDialog: () => {},
  closeAll: () => {},
})
