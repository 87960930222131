/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { IssueTemplateModel, IssueTemplateModelType } from './IssueTemplateModel'
import { IssueTemplateModelSelector } from './IssueTemplateModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  issueTemplate: IssueTemplateModelType
}

/**
 * IssueTemplateUpdateResponseBase
 * auto generated base class for the model IssueTemplateUpdateResponseModel.
 */
export const IssueTemplateUpdateResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('IssueTemplateUpdateResponse')
    .props({
      __typename: types.optional(
        types.literal('IssueTemplateUpdateResponse'),
        'IssueTemplateUpdateResponse',
      ),
      success: types.union(types.undefined, types.boolean),
      message: types.union(types.undefined, types.string),
      issueTemplate: types.union(
        types.undefined,
        types.null,
        MSTGQLRef(types.late((): any => IssueTemplateModel)),
      ),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class IssueTemplateUpdateResponseModelSelector extends QueryBuilder {
  get success() {
    return this.__attr(`success`)
  }
  get message() {
    return this.__attr(`message`)
  }
  issueTemplate(
    builder?:
      | string
      | IssueTemplateModelSelector
      | ((selector: IssueTemplateModelSelector) => IssueTemplateModelSelector),
  ) {
    return this.__child(`issueTemplate`, IssueTemplateModelSelector, builder)
  }
}
export function selectFromIssueTemplateUpdateResponse() {
  return new IssueTemplateUpdateResponseModelSelector()
}

export const issueTemplateUpdateResponseModelPrimitives = selectFromIssueTemplateUpdateResponse()
  .success.message
