/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from 'mobx'
import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { AssignedLayerModel, AssignedLayerModelType } from './AssignedLayerModel'
import { AssignedLayerModelSelector } from './AssignedLayerModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  assignedLayers: IObservableArray<AssignedLayerModelType>
}

/**
 * LayerBase
 * auto generated base class for the model LayerModel.
 */
export const LayerModelBase = withTypedRefs<Refs>()(
  ModelBase.named('Layer')
    .props({
      __typename: types.optional(types.literal('Layer'), 'Layer'),
      id: types.identifier,
      createdAt: types.union(types.undefined, types.frozen()),
      updatedAt: types.union(types.undefined, types.frozen()),
      name: types.union(types.undefined, types.string),
      assignedLayers: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => AssignedLayerModel))),
      ),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class LayerModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  get name() {
    return this.__attr(`name`)
  }
  assignedLayers(
    builder?:
      | string
      | AssignedLayerModelSelector
      | ((selector: AssignedLayerModelSelector) => AssignedLayerModelSelector),
  ) {
    return this.__child(`assignedLayers`, AssignedLayerModelSelector, builder)
  }
}
export function selectFromLayer() {
  return new LayerModelSelector()
}

export const layerModelPrimitives = selectFromLayer().createdAt.updatedAt.name
