/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from 'mobx'
import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { LegislatorModel, LegislatorModelType } from './LegislatorModel'
import { LegislatorModelSelector } from './LegislatorModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  legislators: IObservableArray<LegislatorModelType>
}

/**
 * ImportGroupBase
 * auto generated base class for the model ImportGroupModel.
 */
export const ImportGroupModelBase = withTypedRefs<Refs>()(
  ModelBase.named('ImportGroup')
    .props({
      __typename: types.optional(types.literal('ImportGroup'), 'ImportGroup'),
      id: types.identifier,
      createdAt: types.union(types.undefined, types.frozen()),
      updatedAt: types.union(types.undefined, types.frozen()),
      legislators: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => LegislatorModel))),
      ),
      name: types.union(types.undefined, types.string),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class ImportGroupModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  get name() {
    return this.__attr(`name`)
  }
  legislators(
    builder?:
      | string
      | LegislatorModelSelector
      | ((selector: LegislatorModelSelector) => LegislatorModelSelector),
  ) {
    return this.__child(`legislators`, LegislatorModelSelector, builder)
  }
}
export function selectFromImportGroup() {
  return new ImportGroupModelSelector()
}

export const importGroupModelPrimitives = selectFromImportGroup().createdAt.updatedAt.name
