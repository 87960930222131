/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from 'mobx'
import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { IssueTemplateModel, IssueTemplateModelType } from './IssueTemplateModel'
import { IssueTemplateModelSelector } from './IssueTemplateModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  records: IObservableArray<IssueTemplateModelType>
}

/**
 * IssueTemplateListResponseBase
 * auto generated base class for the model IssueTemplateListResponseModel.
 */
export const IssueTemplateListResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('IssueTemplateListResponse')
    .props({
      __typename: types.optional(
        types.literal('IssueTemplateListResponse'),
        'IssueTemplateListResponse',
      ),
      records: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => IssueTemplateModel))),
      ),
      count: types.union(types.undefined, types.number),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class IssueTemplateListResponseModelSelector extends QueryBuilder {
  get count() {
    return this.__attr(`count`)
  }
  records(
    builder?:
      | string
      | IssueTemplateModelSelector
      | ((selector: IssueTemplateModelSelector) => IssueTemplateModelSelector),
  ) {
    return this.__child(`records`, IssueTemplateModelSelector, builder)
  }
}
export function selectFromIssueTemplateListResponse() {
  return new IssueTemplateListResponseModelSelector()
}

export const issueTemplateListResponseModelPrimitives = selectFromIssueTemplateListResponse().count
