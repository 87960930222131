import { Box, makeStyles, Popper, TextField, TextFieldProps, Theme } from '@material-ui/core'
import { StaticDatePicker } from '@material-ui/pickers'
import { DateTime } from 'luxon'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { formatDateTime } from '../../../utilities/dates'

const useStyles = makeStyles((theme: Theme) => ({
  popperContainer: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    zIndex: 9999,
  },
  panel: {
    padding: 0,
  },
  container: {
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0,
    },
  },
}))

export type DateInputProps = {
  onChange: (value: DateTime | null) => void
  value: DateTime | null
  minYear?: number
  maxYear?: number
  label?: string
  textFieldProps?: TextFieldProps
}

const DateInput: React.FC<DateInputProps> = ({
  label,
  value,
  onChange,
  textFieldProps,
  minYear = 1980,
  maxYear = DateTime.utc().year + 1,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [localValue, setLocalValue] = useState<string>('')

  useEffect(() => {
    if (
      value &&
      value.isValid &&
      DateTime.fromFormat(localValue || '', 'M/d/yy').toMillis() !== value.toMillis()
    ) {
      setLocalValue(formatDateTime(value))
    }
  }, [value, localValue])

  const handleLocalValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const newValue = event.target.value.replace(/[^0-9/]+/g, '')
      setLocalValue(newValue)
      const newDate = DateTime.fromFormat(newValue, 'M/d/yy')
      onChange(newDate.isValid ? newDate : null)
    },
    [setLocalValue, onChange],
  )

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const isOpen = Boolean(anchorEl)
  const id = isOpen ? 'simple-popper' : undefined

  return (
    <Box display="flex" flex="1">
      <TextField
        label={label}
        variant="standard"
        fullWidth
        value={localValue}
        onChange={handleLocalValueChange}
        onClick={handleClick}
        {...textFieldProps}
      />
      <Box
        position="fixed"
        hidden={!isOpen}
        onClick={() => setAnchorEl(null)}
        top={0}
        left={0}
        right={0}
        bottom={0}
      />
      <Popper id={id} open={isOpen} anchorEl={anchorEl} className={classes.popperContainer}>
        <StaticDatePicker
          value={value}
          onChange={(value) => {
            onChange(value)
            setAnchorEl(null)
          }}
          renderInput={(props) => <TextField {...props} />}
          showToolbar={false}
          disableHighlightToday
          clearable
          minDate={DateTime.fromObject({ year: minYear }).toISO()}
          maxDate={DateTime.fromObject({ year: maxYear }).toISO()}
        />
      </Popper>
    </Box>
  )
}

export default DateInput
