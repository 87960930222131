import { Accordion, AccordionSummary, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MaterialTable, { Column, Options, Query as TableQuery, QueryResult } from 'material-table'
import { observer } from 'mobx-react-lite'
import { Query } from 'mst-gql'
import * as React from 'react'
import { useStore } from '../../getMstGql'
import {
  Chamber,
  GovernmentLevel,
  IssueListResponseModelSelector,
  IssueListResponseModelType,
  issueModelPrimitives,
  IssueModelType,
  IssuesOrderColumns,
  RootStoreType,
} from '../../models'
import { IssuesFilters } from '../../models/RootStore.base'
import { rowDatum } from '../../utilities/coercion'
import { formatDate } from '../../utilities/dates'
import { extractDateRangeFilter, extractEqualsFilter } from '../../utilities/filters'
import { extractOrderByArgs, extractPaginationArgs } from '../../utilities/tables'
import { DateRangeFilter, SelectFilter } from '../common/utils/table'
import { useTableCacheKey } from '../common/utils/table/useTableCacheKey'
import { StateLookup } from '../../utilities/states'

enum IssueColumnKey {
  Level = 'level',
  Chamber = 'chamber',
  State = 'state',
  Date = 'date',
  RollNumber = 'rollCallNumber',
  Name = 'legislationNumber',
}

type IssuesQueryResponseType = { issues: IssueListResponseModelType }

const issuesListSelector = new IssueListResponseModelSelector().count
  .records(issueModelPrimitives)
  .toString()

function queryIssues(
  store: RootStoreType,
  tableQuery: TableQuery<IssueModelType>,
): Query<IssuesQueryResponseType> {
  const filters: IssuesFilters = {}
  extractEqualsFilter(tableQuery, IssueColumnKey.Chamber, filters)
  extractEqualsFilter(tableQuery, IssueColumnKey.Level, filters)
  extractEqualsFilter(tableQuery, IssueColumnKey.State, filters)
  extractEqualsFilter(tableQuery, IssueColumnKey.Name, filters)
  extractEqualsFilter(tableQuery, IssueColumnKey.RollNumber, filters)
  extractDateRangeFilter(tableQuery, IssueColumnKey.Date, filters)
  return store.queryIssues(
    {
      pagination: extractPaginationArgs(tableQuery),
      orderBy: extractOrderByArgs(tableQuery, IssuesOrderColumns),
      filters,
    },
    issuesListSelector,
  )
}

export type IssuesTableProps = {
  filters?: {}
  tableOptions?: Options<IssueModelType>
  onRowClick?: (row: IssueModelType) => void
  cacheKey?: string | number
}

const IssuesTable: React.FC<IssuesTableProps> = ({ onRowClick, cacheKey, tableOptions = {} }) => {
  const store = useStore()
  const tableRef = useTableCacheKey(cacheKey)
  const handleDetailsClick = React.useCallback(
    (row: IssueModelType): void => onRowClick && onRowClick(row),
    [onRowClick],
  )

  const handleTableUpdate = React.useCallback(
    async (query: TableQuery<IssueModelType>): Promise<QueryResult<IssueModelType>> => {
      const results = await queryIssues(store, query)
      return {
        data: results?.issues?.records?.toJS() || [],
        totalCount: results?.issues?.count || 0,
        page: query.page,
      }
    },
    [store],
  )

  const combinedTableOptions: Options<IssueModelType> = React.useMemo(
    () => ({
      filtering: true,
      sorting: true,
      toolbar: false,
      debounceInterval: 300,
      pageSize: 10,
      ...tableOptions,
    }),
    [tableOptions],
  )

  const columns = React.useMemo(
    (): Array<Column<IssueModelType>> => [
      {
        title: 'Date',
        field: 'date',
        render: (d) => formatDate(d?.date),
        filtering: true,
        filterComponent: DateRangeFilter,
      },
      { title: 'Roll #', field: 'rollCallNumber' },
      { title: 'Bill title', field: 'legislationNumber', sorting: false },
      { title: 'Level', field: 'level', lookup: GovernmentLevel, filterComponent: SelectFilter },
      { title: 'Chamber', field: 'chamber', lookup: Chamber, filterComponent: SelectFilter },
      { title: 'State', field: 'state', lookup: StateLookup, filterComponent: SelectFilter },
    ],
    [],
  )

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Votes</Typography>
      </AccordionSummary>
      <MaterialTable
        tableRef={tableRef}
        columns={columns}
        data={handleTableUpdate}
        options={combinedTableOptions}
        localization={{ header: { actions: '' } }}
        style={{ boxShadow: 'none' }}
        actions={[
          {
            icon: 'pageview',
            tooltip: 'View details',
            onClick: (event, d): void => handleDetailsClick(rowDatum(d)),
          },
        ]}
      />
    </Accordion>
  )
}

export default observer(IssuesTable)
