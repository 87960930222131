import { Party, VoteValue } from '../models'
import { Maybe } from './types'

export const parseParty = (partyStr: string): Maybe<Party> => {
  switch (partyStr.toUpperCase().charAt(0)) {
    case 'D':
      return Party.Democrat
    case 'R':
      return Party.Republican
    case 'I':
      return Party.Independent
    case 'O':
      return Party.Other
  }
}

export const parseVoteValue = (rawValue: string): Maybe<VoteValue> => {
  switch (rawValue.toUpperCase()) {
    case 'YEA':
      return VoteValue.Yea
    case 'AYE':
      return VoteValue.Yea
    case 'Y':
      return VoteValue.Yea
    case 'NAY':
      return VoteValue.Nay
    case 'NO':
      return VoteValue.Nay
    case 'N':
      return VoteValue.Nay
    case 'NOT VOTING':
      return VoteValue.NotVoting
    case 'NOTVOTING':
      return VoteValue.NotVoting
    case 'NV':
      return VoteValue.NotVoting
    case 'PRESENT':
      return VoteValue.Present
    case 'P':
      return VoteValue.Present
  }
}
