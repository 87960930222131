/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { IssueTemplateModel, IssueTemplateModelType } from './IssueTemplateModel'
import { IssueTemplateModelSelector } from './IssueTemplateModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  issueTemplate: IssueTemplateModelType
}

/**
 * BlurbBase
 * auto generated base class for the model BlurbModel.
 */
export const BlurbModelBase = withTypedRefs<Refs>()(
  ModelBase.named('Blurb')
    .props({
      __typename: types.optional(types.literal('Blurb'), 'Blurb'),
      id: types.identifier,
      createdAt: types.union(types.undefined, types.frozen()),
      updatedAt: types.union(types.undefined, types.frozen()),
      issueTemplateId: types.union(types.undefined, types.string),
      issueTemplate: types.union(
        types.undefined,
        MSTGQLRef(types.late((): any => IssueTemplateModel)),
      ),
      name: types.union(types.undefined, types.string),
      text: types.union(types.undefined, types.string),
      url: types.union(types.undefined, types.null, types.string),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class BlurbModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  get issueTemplateId() {
    return this.__attr(`issueTemplateId`)
  }
  get name() {
    return this.__attr(`name`)
  }
  get text() {
    return this.__attr(`text`)
  }
  get url() {
    return this.__attr(`url`)
  }
  issueTemplate(
    builder?:
      | string
      | IssueTemplateModelSelector
      | ((selector: IssueTemplateModelSelector) => IssueTemplateModelSelector),
  ) {
    return this.__child(`issueTemplate`, IssueTemplateModelSelector, builder)
  }
}
export function selectFromBlurb() {
  return new BlurbModelSelector()
}

export const blurbModelPrimitives = selectFromBlurb().createdAt.updatedAt.issueTemplateId.name.text
  .url
