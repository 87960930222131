/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from 'mobx-state-tree'

/**
 * Typescript enum
 */

export enum BookStatus {
  Created = 'Created',
  Processing = 'Processing',
  Ready = 'Ready',
  Error = 'Error',
}

/**
 * BookStatus
 */
export const BookStatusEnumType = types.enumeration('BookStatus', [
  'Created',
  'Processing',
  'Ready',
  'Error',
])
