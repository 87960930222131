import * as React from 'react'
import { Column, EditCellColumnDef } from 'material-table'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

export type SelectFilterProps<T extends object = {}> = {
  columnDef: Column<T>
  onFilterChanged: (rowId: string, value: any) => void
}

const SelectFilter = <T extends object = {}>({
  columnDef,
  onFilterChanged,
}: SelectFilterProps<T>) => {
  const cellDef: EditCellColumnDef = columnDef as EditCellColumnDef
  const options = React.useMemo(() => Object.keys(columnDef.lookup || {}), [columnDef.lookup])
  const findOptionLabel = React.useCallback(
    (optionValue: string): string => {
      const option = Object.entries(columnDef.lookup || {}).find(([value]) => {
        return value === optionValue
      })
      return option && option[1]
    },
    [columnDef.lookup],
  )
  return (
    <Autocomplete
      value={cellDef.tableData.filterValue || null}
      options={options}
      onChange={(_, value): void => {
        onFilterChanged(String(cellDef.tableData.id), value)
      }}
      getOptionLabel={findOptionLabel}
      renderInput={(params): React.ReactElement => <TextField {...params} variant="standard" />}
      size="small"
    />
  )
}

export default SelectFilter
