import { Button } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  ActionButtonContainer,
  FieldGroup,
  GenerateVoteBookForm,
  LoadingContainer,
  NotFound,
  PageContents,
  PageHeader,
  ReadOnlyForm,
  ReadOnlyTextField,
  useModal,
  useToast,
  VotesTable,
} from 'src/components'
import { TermsTable } from '../../components/terms'
import { Paths } from '../../constants/routes'
import { LegislatorModelType, MutationResponseModelType } from '../../models'
import { useQuery } from '../../models/reactUtils'
import { getOrThrow } from '../../utilities/types'
import { useStore } from '../../getMstGql'
import { displayMutationError, hasMutationErrors } from '../../utilities/errors'

export interface LegislatorDetailsParams {
  id: string
}

type LegislatorQueryResponse = { legislator: LegislatorModelType }

const LegislatorDetailsPage: React.FC = () => {
  const { id } = useParams<LegislatorDetailsParams>()
  const history = useHistory()
  const { setToast } = useToast()
  const { mutateDeleteLegislator } = useStore()
  const { data, loading } = useQuery<LegislatorQueryResponse>((store) =>
    store.queryLegislator({
      id,
    }),
  )
  const { openDrawer, closeDrawer, openDialog } = useModal()
  const legislator = useMemo(() => data?.legislator, [data])

  const handleGenerateVoteBookComplete = useCallback(() => {
    closeDrawer()
  }, [closeDrawer])

  const handleDeleteCategory = useCallback(async () => {
    const response = (await mutateDeleteLegislator({ id }).currentPromise()) as {
      deleteLegislator: MutationResponseModelType
    }

    if (hasMutationErrors(response)) {
      setToast(displayMutationError(response))
    } else {
      setToast('Deleted legislator.')
      history.push(Paths.LegislatorsList)
    }
  }, [id, mutateDeleteLegislator, setToast, history])

  if (!loading && !legislator) {
    return <NotFound />
  }

  const title = legislator?.fullName || ''
  return (
    <LoadingContainer loading={loading}>
      <PageHeader
        title={title}
        breadcrumbs={[{ link: Paths.LegislatorsList, label: 'Legislators' }, { label: title }]}
      >
        <ActionButtonContainer>
          <Button
            variant="outlined"
            color="secondary"
            style={{ marginRight: 7 }}
            onClick={() => {
              openDialog({
                title: 'Delete this record?',
                body: 'This is permanent. Deleted records cannot be restored.',
                onConfirm: handleDeleteCategory,
              })
            }}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              openDrawer(
                <GenerateVoteBookForm
                  legislator={getOrThrow(legislator)}
                  onComplete={handleGenerateVoteBookComplete}
                />,
              )
            }
          >
            Generate vote book
          </Button>
        </ActionButtonContainer>
      </PageHeader>

      <PageContents>
        <ReadOnlyForm title="General">
          <FieldGroup>
            <ReadOnlyTextField label="First name" value={legislator?.firstName} />
            <ReadOnlyTextField label="Last name" value={legislator?.lastName} />
          </FieldGroup>
          <FieldGroup>
            <ReadOnlyTextField label="Bioguide ID" value={legislator?.bioguideId || ''} />
          </FieldGroup>
        </ReadOnlyForm>
        <TermsTable legislatorId={legislator?.id || ''} tableOptions={{ pageSize: 5 }} />
        <VotesTable legislatorId={id} />
      </PageContents>
    </LoadingContainer>
  )
}

export default observer(LegislatorDetailsPage)
