import { Accordion, AccordionSummary, Button, Typography } from '@material-ui/core'
import MaterialTable from 'material-table'
import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Paths } from '../../constants/routes'
import { useQuery } from '../../models/reactUtils'
import { useToast } from '../../components/common/utils/toast'
import { useModal } from '../../components/common/utils/modal'
import { LoadingContainer, NotFound } from '../../components/common/utils'
import {
  ActionButtonContainer,
  ActionsMenu,
  FieldGroup,
  PageContents,
  PageHeader,
  ReadOnlyForm,
  ReadOnlyTextField,
  TableActions,
} from '../../components/common'
import {
  ImportGroupModelType,
  importGroupSelector,
  LegislatorModelType,
  MutationResponseModelType,
} from '../../models'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../getMstGql'
import { displayMutationError, hasMutationErrors } from '../../utilities/errors'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { rowDatum } from '../../utilities/coercion'
import { generateLegislatorUploadTemplate } from '../../utilities/spreadsheets'

export interface ImportGroupDetailsPageParams {
  id: string
}

const ImportGroupDetailsPage = () => {
  const { id } = useParams<ImportGroupDetailsPageParams>()
  const { loading, data } = useQuery<{ importGroup: ImportGroupModelType }>((store) =>
    store.queryImportGroup({ id }, importGroupSelector, { fetchPolicy: 'no-cache' }),
  )
  const { mutateDeleteImportGroup } = useStore()
  const history = useHistory()
  const { setToast } = useToast()
  const { openDialog } = useModal()

  const importGroup = useMemo(() => {
    return data?.importGroup
  }, [data])

  const handleDeleteImportGroup = useCallback(async () => {
    const response = (await mutateDeleteImportGroup({ id }).currentPromise()) as {
      deleteImportGroup: MutationResponseModelType
    }

    if (hasMutationErrors(response)) {
      setToast(displayMutationError(response))
    } else {
      setToast('Deleted import group.')
      history.push(Paths.ImportGroupsList)
    }
  }, [id, mutateDeleteImportGroup, setToast, history])

  const handleLegislatorClick = useCallback(
    (legislator: LegislatorModelType): void => {
      history.push(`/legislators/${legislator.id}`)
    },
    [history],
  )

  if (loading && !importGroup) {
    return <LoadingContainer loading={loading} />
  }

  if (!importGroup) {
    return <NotFound />
  }

  return (
    <LoadingContainer loading={loading && !importGroup}>
      <PageHeader
        title={importGroup.name || ''}
        breadcrumbs={[
          { link: Paths.ImportGroupsList, label: 'Import groups' },
          { label: importGroup.name || '' },
        ]}
      >
        <ActionButtonContainer>
          <ActionsMenu
            actions={[
              {
                label: 'Download sample file',
                onClick: (): void => {
                  generateLegislatorUploadTemplate(
                    importGroup?.legislators || [],
                    `${importGroup.name} Import Group Template`,
                  )
                },
              },
              {
                label: 'Delete',
                onClick: (): void => {
                  openDialog({
                    title: 'Delete this record?',
                    body: 'This is permanent. Deleted records cannot be restored.',
                    onConfirm: handleDeleteImportGroup,
                  })
                },
              },
            ]}
          />
        </ActionButtonContainer>
      </PageHeader>
      <PageContents>
        <ReadOnlyForm title="General">
          <FieldGroup>
            <ReadOnlyTextField label="Name" value={importGroup.name} />
          </FieldGroup>
        </ReadOnlyForm>

        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Legislators</Typography>
          </AccordionSummary>
          <TableActions>
            <Button
              variant="contained"
              onClick={(): void => {
                history.push(`/importGroups/${importGroup?.id}/edit`)
              }}
            >
              Edit
            </Button>
          </TableActions>
          <MaterialTable
            data={importGroup.legislators || []}
            columns={[
              {
                title: 'Name',
                field: 'fullName',
                defaultSort: 'asc',
              },
            ]}
            options={{ search: false, filtering: true, toolbar: false }}
            style={{ boxShadow: 'none' }}
            localization={{ header: { actions: '' } }}
            actions={[
              {
                icon: 'pageview',
                tooltip: 'View',
                onClick: (event, d) => handleLegislatorClick(rowDatum(d)),
              },
            ]}
          />
        </Accordion>
      </PageContents>
    </LoadingContainer>
  )
}

export default observer(ImportGroupDetailsPage)
