/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from 'mobx-state-tree'

/**
 * Typescript enum
 */

export enum State {
  Alabama = 'Alabama',
  Alaska = 'Alaska',
  Arizona = 'Arizona',
  Arkansas = 'Arkansas',
  California = 'California',
  Colorado = 'Colorado',
  Connecticut = 'Connecticut',
  Delaware = 'Delaware',
  Florida = 'Florida',
  Georgia = 'Georgia',
  Hawaii = 'Hawaii',
  Idaho = 'Idaho',
  Illinois = 'Illinois',
  Indiana = 'Indiana',
  Iowa = 'Iowa',
  Kansas = 'Kansas',
  Kentucky = 'Kentucky',
  Louisiana = 'Louisiana',
  Maine = 'Maine',
  Maryland = 'Maryland',
  Massachusetts = 'Massachusetts',
  Michigan = 'Michigan',
  Minnesota = 'Minnesota',
  Mississippi = 'Mississippi',
  Missouri = 'Missouri',
  Montana = 'Montana',
  Nebraska = 'Nebraska',
  Nevada = 'Nevada',
  NewHampshire = 'NewHampshire',
  NewJersey = 'NewJersey',
  NewMexico = 'NewMexico',
  NewYork = 'NewYork',
  NorthCarolina = 'NorthCarolina',
  NorthDakota = 'NorthDakota',
  Ohio = 'Ohio',
  Oklahoma = 'Oklahoma',
  Oregon = 'Oregon',
  Pennsylvania = 'Pennsylvania',
  RhodeIsland = 'RhodeIsland',
  SouthCarolina = 'SouthCarolina',
  SouthDakota = 'SouthDakota',
  Tennessee = 'Tennessee',
  Texas = 'Texas',
  Utah = 'Utah',
  Vermont = 'Vermont',
  Virginia = 'Virginia',
  Washington = 'Washington',
  WestVirginia = 'WestVirginia',
  Wisconsin = 'Wisconsin',
  Wyoming = 'Wyoming',
  AmericanSamoa = 'AmericanSamoa',
  WashingtonDC = 'WashingtonDC',
  Guam = 'Guam',
  NorthernMarianaIslands = 'NorthernMarianaIslands',
  PuertoRico = 'PuertoRico',
  VirginIslands = 'VirginIslands',
  DakotaTerritory = 'DakotaTerritory',
  TerritoryOfOrleans = 'TerritoryOfOrleans',
  PhilippinesTerritory = 'PhilippinesTerritory',
  Unknown = 'Unknown',
}

/**
 * State
 */
export const StateEnumType = types.enumeration('State', [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'NewHampshire',
  'NewJersey',
  'NewMexico',
  'NewYork',
  'NorthCarolina',
  'NorthDakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'RhodeIsland',
  'SouthCarolina',
  'SouthDakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'WestVirginia',
  'Wisconsin',
  'Wyoming',
  'AmericanSamoa',
  'WashingtonDC',
  'Guam',
  'NorthernMarianaIslands',
  'PuertoRico',
  'VirginIslands',
  'DakotaTerritory',
  'TerritoryOfOrleans',
  'PhilippinesTerritory',
  'Unknown',
])
