import { Accordion, AccordionSummary, Button, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MaterialTable, { Column, Options, Query as TableQuery } from 'material-table'
import * as React from 'react'
import { useCallback, useState } from 'react'
import {
  Chamber,
  GovernmentLevel,
  MutationResponseModelType,
  Party,
  RootStoreType,
  TermModelSelector,
} from 'src/models'
import { extractOrderByArgs, extractPaginationArgs } from 'src/utilities/tables'
import {
  TermListResponseModelSelector,
  TermListResponseModelType,
  TermModelType,
  TermsOrderColumns,
} from '../../models'
import { TermsFilters } from '../../models/RootStore.base'
import { formatDateString } from '../../utilities/dates'
import { extractDateRangeFilter, extractInFilter } from '../../utilities/filters'
import { Maybe } from '../../utilities/types'
import {
  DateRangeFilter,
  ModelListQueryData,
  MultiSelectFilter,
  useModelTable,
} from '../common/utils/table'
import { formatState, StateLookup } from '../../utilities/states'
import { TableActions, useModal, useToast } from '../common'
import AddTermForm from './AddTermForm'
import { useTableCacheKey } from '../common/utils/table/useTableCacheKey'
import { observer } from 'mobx-react-lite'
import { displayMutationError, hasMutationErrors } from '../../utilities/errors'
import { useStore } from '../../getMstGql'
import { rowDatum } from 'src/utilities/coercion'

type ListQueryResponseType = { terms: TermListResponseModelType }

const termsWithBranchSelector = new TermModelSelector().startDate.endDate.chamber.party.level.state.district.toString()

const listSelector = new TermListResponseModelSelector().count
  .records(termsWithBranchSelector)
  .toString()

async function listQuery(
  store: RootStoreType,
  tableQuery: TableQuery<TermModelType>,
  defaultFilters?: TermsFilters,
): Promise<ModelListQueryData<TermModelType>> {
  const filters: TermsFilters = Object.assign({}, defaultFilters)
  extractInFilter(tableQuery, 'state', filters)
  extractInFilter(tableQuery, 'chamber', filters)
  extractInFilter(tableQuery, 'level', filters)
  extractInFilter(tableQuery, 'party', filters)
  extractDateRangeFilter(tableQuery, 'startDate', filters)
  extractDateRangeFilter(tableQuery, 'endDate', filters)

  const results: ListQueryResponseType = await store.queryTerms(
    {
      pagination: extractPaginationArgs(tableQuery),
      orderBy: extractOrderByArgs(tableQuery, TermsOrderColumns),
      filters,
    },
    listSelector,
  )

  return {
    records: results?.terms?.records?.toJS() || [],
    count: results?.terms?.count || 0,
  }
}

export type TermsTableProps = {
  legislatorId: string
  tableOptions?: Options<TermModelType>
  onRowClick?: (row: TermModelType) => void
  filterColumns?: (columns: Array<Column<TermModelType>>) => Array<Column<TermModelType>>
}

const TermsTable: React.FC<TermsTableProps> = ({
  legislatorId,
  onRowClick,
  tableOptions = {},
  filterColumns,
}) => {
  const { openDrawer, closeDrawer, openDialog } = useModal()
  const [cacheKey, setCacheKey] = useState(0)
  const tableRef = useTableCacheKey(cacheKey)
  const { mutateDeleteTerm } = useStore()
  const { setToast } = useToast()
  const handleDeleteTerm = useCallback(
    async (subCategoryId: string) => {
      const response = (await mutateDeleteTerm({ id: subCategoryId }).currentPromise()) as {
        deleteTerm: MutationResponseModelType
      }

      if (hasMutationErrors(response)) {
        setToast(displayMutationError(response))
      } else {
        setToast('Deleted subcategory.')
        setCacheKey(Date.now())
      }
    },
    [mutateDeleteTerm, setToast, setCacheKey],
  )

  const { columnFilter, getColumnProps, handleTableUpdate, actions } = useModelTable<TermModelType>(
    {
      filterColumns,
      materialTableOptions: tableOptions,
      orderColumns: TermsOrderColumns,
      baseFilters: { legislatorId },
      listQuery,
      onRowClick,
      actions: [
        {
          icon: 'delete',
          tooltip: 'Delete subcategory',
          onClick: (event, data) => {
            openDialog({
              title: 'Delete this record?',
              body: 'This is permanent. Deleted records cannot be restored.',
              onConfirm: () => handleDeleteTerm(rowDatum(data).id),
            })
          },
        },
      ],
    },
  )

  const handleTermAdded = useCallback(() => {
    setCacheKey(Date.now())
    closeDrawer()
  }, [closeDrawer, setCacheKey])

  const columns = React.useMemo((): Array<Column<TermModelType>> => {
    return columnFilter([
      {
        defaultSort: 'desc' as any,
        render: (d): Maybe<string> => d?.startDate && formatDateString(d.startDate),
        ...getColumnProps('startDate'),
        filtering: true,
        filterComponent: DateRangeFilter,
      },
      {
        render: (d): Maybe<string> => d?.endDate && formatDateString(d.endDate),
        ...getColumnProps('endDate'),
        filtering: true,
        filterComponent: DateRangeFilter,
      },
      {
        ...getColumnProps('party'),
        filtering: true,
        lookup: Party,
        filterComponent: MultiSelectFilter,
      },
      {
        ...getColumnProps('level'),
        filtering: true,
        lookup: GovernmentLevel,
        filterComponent: MultiSelectFilter,
      },
      {
        ...getColumnProps('chamber'),
        filtering: true,
        lookup: Chamber,
        filterComponent: MultiSelectFilter,
      },
      {
        ...getColumnProps('state'),
        filtering: true,
        lookup: StateLookup,
        render: (data) => formatState(data.state),
        filterComponent: MultiSelectFilter,
      },
    ])
  }, [columnFilter, getColumnProps])

  const combinedTableOptions: Options<TermModelType> = React.useMemo(() => {
    const allFiltersDisabled = !columns.find((col) => col.filtering === undefined || col.filtering)
    return {
      filtering: !allFiltersDisabled,
      toolbar: false,
      debounceInterval: 300,
      pageSize: 10,
      ...tableOptions,
    }
  }, [tableOptions, columns])

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Terms</Typography>
      </AccordionSummary>
      <TableActions>
        <Button
          variant="contained"
          onClick={(): void => {
            openDrawer(<AddTermForm legislatorId={legislatorId} onComplete={handleTermAdded} />)
          }}
        >
          Add
        </Button>
      </TableActions>
      <MaterialTable
        tableRef={tableRef}
        columns={columns}
        data={handleTableUpdate}
        options={combinedTableOptions}
        localization={{ header: { actions: '' } }}
        style={{ boxShadow: 'none' }}
        actions={actions}
      />
    </Accordion>
  )
}

export default observer(TermsTable)
