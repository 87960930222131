import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import {
  ModalProvider,
  OnlyLoggedOutRoute,
  Page,
  PrivateRoute,
  ToastProvider,
} from 'src/components'
import { Paths } from './constants/routes'
import { UserRole } from './models'
import * as Pages from './pages'

const LoggedInRoutes: React.FC = () => {
  return (
    <Page>
      <ToastProvider>
        <ModalProvider>
          <Switch>
            <PrivateRoute path={Paths.Dashboard} component={Pages.Dashboard} exact />

            <PrivateRoute path={Paths.LegislatorDetails} component={Pages.LegislatorsDetailsPage} />
            <PrivateRoute path={Paths.LegislatorsList} component={Pages.LegislatorsListPage} />

            <PrivateRoute path={Paths.TemplateDetails} component={Pages.IssueTemplateDetailsPage} />
            <PrivateRoute path={Paths.TemplatesList} component={Pages.IssueTemplatesListPage} />

            <PrivateRoute path={Paths.EditIssueVotes} component={Pages.EditVotesPage} />
            <PrivateRoute path={Paths.VoteDetails} component={Pages.VoteDetailsPage} />
            <PrivateRoute path={Paths.VotesList} component={Pages.VotesListPage} />

            <PrivateRoute path={Paths.CategoryDetails} component={Pages.CategoryDetailsPage} />
            <PrivateRoute path={Paths.CategoriesList} component={Pages.CategoriesListPage} />

            <PrivateRoute path={Paths.LayerDetails} component={Pages.LayerDetailsPage} />
            <PrivateRoute path={Paths.LayersList} component={Pages.LayersListPage} />

            <PrivateRoute path={Paths.UserProfile} component={Pages.UserProfilePage} />
            <PrivateRoute path={Paths.BooksList} component={Pages.BooksListPage} />

            <PrivateRoute
              path={Paths.UserDetails}
              component={Pages.UserDetailsPage}
              requiredRole={UserRole.ADMIN}
            />
            <PrivateRoute
              path={Paths.UsersList}
              component={Pages.UsersListPage}
              requiredRole={UserRole.ADMIN}
            />

            <PrivateRoute
              path={Paths.EditImportGroupLegislators}
              component={Pages.EditImportGroupLegislatorsPage}
            />
            <PrivateRoute
              path={Paths.ImportGroupDetails}
              component={Pages.ImportGroupDetailsPage}
            />
            <PrivateRoute path={Paths.ImportGroupsList} component={Pages.ImportGroupsListPage} />

            <Route path="*" component={(): JSX.Element => <Redirect to="/" />} />
          </Switch>
        </ModalProvider>
      </ToastProvider>
    </Page>
  )
}

const Routes: React.FC = () => {
  return (
    <Switch>
      <OnlyLoggedOutRoute path={Paths.Login} component={Pages.Login} />
      <Route path="*" component={LoggedInRoutes} />
    </Switch>
  )
}

export default Routes
