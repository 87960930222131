/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from 'mobx'
import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { SubCategoryModel, SubCategoryModelType } from './SubCategoryModel'
import { SubCategoryModelSelector } from './SubCategoryModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  subCategories: IObservableArray<SubCategoryModelType>
}

/**
 * CategoryBase
 * auto generated base class for the model CategoryModel.
 */
export const CategoryModelBase = withTypedRefs<Refs>()(
  ModelBase.named('Category')
    .props({
      __typename: types.optional(types.literal('Category'), 'Category'),
      id: types.identifier,
      createdAt: types.union(types.undefined, types.frozen()),
      updatedAt: types.union(types.undefined, types.frozen()),
      title: types.union(types.undefined, types.string),
      subCategories: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => SubCategoryModel))),
      ),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class CategoryModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  get title() {
    return this.__attr(`title`)
  }
  subCategories(
    builder?:
      | string
      | SubCategoryModelSelector
      | ((selector: SubCategoryModelSelector) => SubCategoryModelSelector),
  ) {
    return this.__child(`subCategories`, SubCategoryModelSelector, builder)
  }
}
export function selectFromCategory() {
  return new CategoryModelSelector()
}

export const categoryModelPrimitives = selectFromCategory().createdAt.updatedAt.title
