import * as React from 'react'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  ActionButtonContainer,
  Button,
  CategoriesTable,
  PageContents,
  PageHeader,
  useModal,
} from 'src/components'
import { CategoryModelType } from '../../models'
import AddCategoryForm from 'src/components/categories/AddCategoryForm'

const CategoriesListPage: React.FC = () => {
  const history = useHistory()
  const { openDrawer, closeDrawer } = useModal()
  const [cacheKey, setCacheKey] = useState(Date.now())
  const updateCacheKey = useCallback(() => setCacheKey(Date.now()), [setCacheKey])
  const handleAddCategory = useCallback(
    (willNavigate?: boolean) => {
      if (!willNavigate) {
        updateCacheKey()
      }
      closeDrawer()
    },
    [closeDrawer, updateCacheKey],
  )
  const handleRowClick = React.useCallback(
    (row: CategoryModelType): void => {
      history.push(`/categories/${row.id}`)
    },
    [history],
  )

  return (
    <>
      <PageHeader title="Categories" breadcrumbs={[{ label: 'Categories' }]}>
        <ActionButtonContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={() => openDrawer(<AddCategoryForm onComplete={handleAddCategory} />)}
          >
            Create
          </Button>
        </ActionButtonContainer>
      </PageHeader>
      <PageContents>
        <CategoriesTable onRowClick={handleRowClick} cacheKey={cacheKey} />
      </PageContents>
    </>
  )
}

export default CategoriesListPage
