import { Button } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import { DateTime } from 'luxon'
import * as React from 'react'
import * as Yup from 'yup'
import { Chamber, GovernmentLevel, State } from '../../models'
import { getOrThrow } from '../../utilities/types'
import {
  FieldGroup,
  FormButtonGroup,
  FormContainer,
  FormDateField,
  FormSection,
  FormSelectField,
} from '../common/forms'

export type TermParameters = {
  level: GovernmentLevel
  chamber: Chamber
  state?: State
  date: DateTime
}

const initialFormValues: Partial<TermParameters> = {
  date: undefined,
  chamber: undefined,
  state: undefined,
  level: undefined,
}

const schema = Yup.object().shape({
  date: Yup.object().required('Required'),
  chamber: Yup.string().required('Required'),
  state: Yup.string().when('level', {
    is: GovernmentLevel.State,
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  level: Yup.string().required('Required'),
})

export interface FindTermFormProps {
  onClose: () => void
  onComplete: (term: TermParameters) => void
  confirmButtonText?: string
}

const FindTermForm: React.FC<FindTermFormProps> = ({
  onComplete,
  onClose,
  confirmButtonText = 'Add',
}) => {
  return (
    <FormContainer title="Add Vote">
      <Formik
        initialValues={initialFormValues}
        validationSchema={schema}
        validateOnBlur={false}
        onSubmit={(values, form) => {
          onComplete({
            chamber: getOrThrow(values.chamber),
            level: getOrThrow(values.level),
            date: getOrThrow(values.date),
            state: values.state,
          })
          form.resetForm()
          onClose()
        }}
      >
        {(props: FormikProps<any>) => (
          <React.Fragment>
            <FormSection title="Branch">
              <FieldGroup>
                <FormSelectField
                  label="Level"
                  name="level"
                  options={Object.values(GovernmentLevel).map((value) => ({
                    value,
                    label: value,
                  }))}
                  clearable={false}
                />
              </FieldGroup>
              <FieldGroup>
                <FormSelectField
                  label="Chamber"
                  name="chamber"
                  options={Object.values(Chamber).map((value) => ({
                    value,
                    label: value,
                  }))}
                  clearable={false}
                />
              </FieldGroup>
              <FieldGroup hidden={props.values.level !== GovernmentLevel.State}>
                <FormSelectField
                  label="State"
                  name="state"
                  options={Object.values(State).map((value) => ({
                    value,
                    label: value,
                  }))}
                />
              </FieldGroup>
              <FieldGroup>
                <FormDateField label="Date" name="date" />
              </FieldGroup>
            </FormSection>

            <FormButtonGroup>
              <Button
                variant="contained"
                onClick={() => {
                  props.resetForm()
                  onClose()
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={props.isSubmitting}
                onClick={() => {
                  return props.submitForm()
                }}
              >
                {confirmButtonText}
              </Button>
            </FormButtonGroup>
          </React.Fragment>
        )}
      </Formik>
    </FormContainer>
  )
}

export default FindTermForm
