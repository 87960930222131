/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from 'mobx-state-tree'

/**
 * Typescript enum
 */

export enum VoteValue {
  Yea = 'Yea',
  Nay = 'Nay',
  Present = 'Present',
  NotVoting = 'NotVoting',
}

/**
 * VoteValue
 */
export const VoteValueEnumType = types.enumeration('VoteValue', [
  'Yea',
  'Nay',
  'Present',
  'NotVoting',
])
