import { Button } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import * as React from 'react'
import { useMemo } from 'react'
import * as Yup from 'yup'
import { useToast } from '../common/utils/toast'
import { useStore } from '../../getMstGql'
import {
  FieldGroup,
  FormButtonGroup,
  FormContainer,
  FormSection,
  FormSelectField,
  FormTextField,
} from '../common/forms'
import {
  AssignedLayerCreationResponseModelType,
  IssueTemplateModelType,
  LayerListResponseModelSelector,
  LayerListResponseModelType,
  layerModelPrimitives,
  LayerModelType,
} from '../../models'
import { displayMutationError, hasMutationErrors } from '../../utilities/errors'
import { CreateAssignedLayerInput } from '../../models/RootStore.base'
import { useQuery } from '../../models/reactUtils'
import { observer } from 'mobx-react-lite'

const initialFormValues = {
  layerId: '',
  text: '',
  url: '',
}

const schema = Yup.object().shape({
  text: Yup.string().required('Text is required'),
  layerId: Yup.string().required('Layer is required'),
  url: Yup.string().optional(),
})

export interface AssignLayerFormProps {
  issueTemplate: IssueTemplateModelType
  onComplete: () => any
}

const AssignLayerForm: React.FC<AssignLayerFormProps> = ({ issueTemplate, onComplete }) => {
  const store = useStore()
  const { setToast } = useToast()
  const { data } = useQuery<{ layers: LayerListResponseModelType }>((store) =>
    store.queryLayers(
      {},
      new LayerListResponseModelSelector().records(layerModelPrimitives).toString(),
    ),
  )
  const layers: Array<LayerModelType> = useMemo(() => {
    return data?.layers?.records || []
  }, [data])
  return (
    <FormContainer title="Assign Layer">
      <Formik
        initialValues={initialFormValues}
        validationSchema={schema}
        validateOnBlur={false}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true)

          const { layerId, text, url } = values
          const input: CreateAssignedLayerInput = {
            issueTemplateId: issueTemplate.id,
            text,
            layerId,
          }
          if (url) {
            input.url = url
          }
          const response = (await store.mutateCreateAssignedLayer({ input }).currentPromise()) as {
            createAssignedLayer: AssignedLayerCreationResponseModelType
          }
          actions.setSubmitting(false)

          if (hasMutationErrors(response)) {
            setToast(displayMutationError(response))
            return
          }

          setToast({
            message: `Layer has been assigned!`,
            variant: 'success',
          })
          actions.resetForm()
          onComplete()
        }}
      >
        {(props: FormikProps<any>) => (
          <React.Fragment>
            <FormSection title="">
              <FieldGroup>
                <FormSelectField
                  name="layerId"
                  label="Layer"
                  options={layers.map((l) => ({ value: l.id, label: l.name || '' }))}
                />
              </FieldGroup>
            </FormSection>

            <FormSection title="">
              <FieldGroup>
                <FormTextField label="Text" name="text" InputProps={{ rows: 4 }} multiline />
              </FieldGroup>
            </FormSection>

            <FormSection title="">
              <FieldGroup>
                <FormTextField label="URL (optional)" name="url" />
              </FieldGroup>
            </FormSection>

            <FormButtonGroup>
              <Button
                variant="contained"
                onClick={() => {
                  props.resetForm()
                  onComplete()
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={props.isSubmitting}
                onClick={() => {
                  return props.submitForm()
                }}
              >
                Save
              </Button>
            </FormButtonGroup>
          </React.Fragment>
        )}
      </Formik>
    </FormContainer>
  )
}

export default observer(AssignLayerForm)
