import {
  Accordion,
  AccordionSummary,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DownloadIcon from '@material-ui/icons/GetApp'
import MaterialTable, { Column } from 'material-table'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useMemo } from 'react'
import { filterAndSearchDate, formatDate } from 'src/utilities/dates'
import { API_SERVER_URL } from '../../constants/api'
import {
  BookListResponseModelSelector,
  BookListResponseModelType,
  BookModelType,
  userModelPrimitives,
} from '../../models'
import { useQuery, useQueryPoll } from '../../models/reactUtils'

const renderDownloadLink = (book: BookModelType) => {
  return (
    <Tooltip title="Download book">
      <Link href={`${API_SERVER_URL}/books/download/${book.id}`} target="_blank">
        <IconButton>
          <DownloadIcon />
        </IconButton>
      </Link>
    </Tooltip>
  )
}

const selector = new BookListResponseModelSelector()
  .records((sel) => sel.createdAt.title.status.user(userModelPrimitives))
  .toString()

const BooksTable: React.FC = () => {
  const { loading, data, query } = useQuery<{ books: BookListResponseModelType }>((store) =>
    store.queryBooks({}, selector),
  )
  useQueryPoll(query)

  const columns = React.useMemo(
    (): Array<Column<BookModelType>> => [
      { title: 'Name', field: 'title' },
      {
        title: 'Created at',
        field: 'createdAt',
        defaultSort: 'desc',
        render: (d) => formatDate(d.createdAt),
        customFilterAndSearch: (term: string, d) => filterAndSearchDate(d.createdAt, term),
      },
      { title: 'Status', field: 'status' },
      { title: 'Created by', field: 'user.email' },
      { title: '', render: renderDownloadLink },
    ],
    [],
  )

  const issueVotes: Array<BookModelType> = useMemo(() => {
    return data?.books.records || []
  }, [data])

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Books</Typography>
      </AccordionSummary>
      <MaterialTable
        data={issueVotes}
        localization={{ header: { actions: '' } }}
        style={{ boxShadow: 'none' }}
        columns={columns}
        options={{ toolbar: false, sorting: true, filtering: true }}
        isLoading={loading && !issueVotes.length}
      />
    </Accordion>
  )
}

export default observer(BooksTable)
