import * as React from 'react'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  ActionButtonContainer,
  AddImportGroupForm,
  Button,
  ImportGroupsTable,
  PageContents,
  PageHeader,
  useModal,
} from 'src/components'
import { ImportGroupModelType } from '../../models'

const ImportGroupsListPage: React.FC = () => {
  const history = useHistory()
  const { openDrawer, closeDrawer } = useModal()
  const [cacheKey, setCacheKey] = useState(Date.now())
  const updateCacheKey = useCallback(() => setCacheKey(Date.now()), [setCacheKey])
  const handleAddImportGroup = useCallback(
    (willNavigate?: boolean) => {
      if (!willNavigate) {
        updateCacheKey()
      }
      closeDrawer()
    },
    [closeDrawer, updateCacheKey],
  )
  const handleRowClick = React.useCallback(
    (row: ImportGroupModelType): void => {
      history.push(`/importGroups/${row.id}`)
    },
    [history],
  )

  return (
    <>
      <PageHeader title="Import groups" breadcrumbs={[{ label: 'Import groups' }]}>
        <ActionButtonContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={() => openDrawer(<AddImportGroupForm onComplete={handleAddImportGroup} />)}
          >
            Create
          </Button>
        </ActionButtonContainer>
      </PageHeader>
      <PageContents>
        <ImportGroupsTable onRowClick={handleRowClick} cacheKey={cacheKey} />
      </PageContents>
    </>
  )
}

export default ImportGroupsListPage
