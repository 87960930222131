/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from 'mobx-state-tree'

/**
 * Typescript enum
 */

export enum Chamber {
  House = 'House',
  Senate = 'Senate',
}

/**
 * Chamber
 */
export const ChamberEnumType = types.enumeration('Chamber', ['House', 'Senate'])
