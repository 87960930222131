/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from 'mobx-state-tree'

/**
 * Typescript enum
 */

export enum Party {
  Democrat = 'Democrat',
  Republican = 'Republican',
  Independent = 'Independent',
  Other = 'Other',
}

/**
 * Party
 */
export const PartyEnumType = types.enumeration('Party', [
  'Democrat',
  'Republican',
  'Independent',
  'Other',
])
