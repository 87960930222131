import { Button } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useMemo } from 'react'
import * as Yup from 'yup'
import { useStore } from '../../getMstGql'
import {
  CategoryListResponseModelSelector,
  CategoryModelType,
  IssueTemplateModelType,
  IssueTemplateUpdateResponseModelSelector,
  IssueTemplateUpdateResponseModelType,
  subCategoryModelPrimitives,
} from '../../models'
import { useQuery } from '../../models/reactUtils'
import { UpdateIssueTemplateInput } from '../../models/RootStore.base'
import { issueTemplatesSelector } from '../../models/selectors'
import { constructCategoryOptions, constructSubCategoryOptions } from '../../utilities/categories'
import { displayMutationError, hasMutationErrors } from '../../utilities/errors'
import {
  FieldGroup,
  FormButtonGroup,
  FormContainer,
  FormSection,
  FormSelectField,
  FormTextField,
} from '../common/forms'
import { LoadingContainer } from '../common/utils'
import { useToast } from '../common/utils/toast'

const getInitialFormValues = (issueTemplate: IssueTemplateModelType) => {
  return {
    categoryId: issueTemplate?.category?.id || '',
    subCategoryId: issueTemplate.subCategory?.id || '',
    title: issueTemplate.title || '',
    summary: issueTemplate.summary || '',
  }
}

const CreateIssueSchema = Yup.object().shape({
  categoryId: Yup.string().required('Category is required'),
  subCategoryId: Yup.string().optional(),
  title: Yup.string().required('Title is required'),
  summary: Yup.string().required('Summary is required'),
})

export interface EditIssueTemplateFormProps {
  onComplete: () => any
  issueTemplate: IssueTemplateModelType
}

const EditIssueTemplateForm: React.FC<EditIssueTemplateFormProps> = ({
  onComplete,
  issueTemplate,
}) => {
  const store = useStore()
  const { data: categoryData, loading } = useQuery((store) =>
    store.queryCategories(
      {},
      new CategoryListResponseModelSelector()
        .records((sel) => sel.id.title.subCategories(subCategoryModelPrimitives))
        .toString(),
    ),
  )

  const { setToast } = useToast()
  const categories: Array<CategoryModelType> = useMemo(() => {
    return categoryData?.categories?.records?.toJS() || []
  }, [categoryData])

  return (
    <LoadingContainer loading={loading && !categories.length}>
      <FormContainer title="Edit Import Template">
        <Formik
          initialValues={getInitialFormValues(issueTemplate)}
          validationSchema={CreateIssueSchema}
          validateOnBlur={false}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true)
            const { categoryId, subCategoryId, title, summary } = values

            const input: UpdateIssueTemplateInput = {
              title: title,
              summary: summary,
              categoryId: categoryId || '',
            }
            if (subCategoryId) {
              input.subCategoryId = subCategoryId
            }
            const query = store.mutateUpdateIssueTemplate(
              { id: issueTemplate.id, input },
              new IssueTemplateUpdateResponseModelSelector().success.message
                .issueTemplate(issueTemplatesSelector)
                .toString(),
            )
            const response = (await query.currentPromise()) as {
              updateIssueTemplate: IssueTemplateUpdateResponseModelType
            }

            if (hasMutationErrors(response)) {
              setToast(displayMutationError(response))
              return
            }

            setToast({
              message: `Issue template saved!`,
              variant: 'success',
            })
            actions.resetForm()
            onComplete()
          }}
        >
          {(props: FormikProps<any>) => (
            <React.Fragment>
              <FormSection title="Categorization">
                <FieldGroup>
                  <FormSelectField
                    name="categoryId"
                    label="Category"
                    options={constructCategoryOptions(categories)}
                    clearable={false}
                    onChange={() => {
                      props.setFieldValue('subCategoryId', '')
                    }}
                  />
                  <FormSelectField
                    name="subCategoryId"
                    label="Subcategory"
                    options={constructSubCategoryOptions(props.values['categoryId'], categories)}
                    disabled={!props.values['categoryId']}
                  />
                </FieldGroup>
              </FormSection>

              <FormSection title="Templates">
                <FieldGroup>
                  <FormTextField label="Title" name="title" InputProps={{ rows: 4 }} multiline />
                </FieldGroup>
                <FieldGroup>
                  <FormTextField
                    label="Summary"
                    name="summary"
                    InputProps={{ rows: 8 }}
                    multiline
                  />
                </FieldGroup>
              </FormSection>

              <FormButtonGroup>
                <Button
                  variant="contained"
                  onClick={() => {
                    props.resetForm()
                    onComplete()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={props.isSubmitting}
                  onClick={() => {
                    return props.submitForm()
                  }}
                >
                  Save
                </Button>
              </FormButtonGroup>
            </React.Fragment>
          )}
        </Formik>
      </FormContainer>
    </LoadingContainer>
  )
}

export default observer(EditIssueTemplateForm)
