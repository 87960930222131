import { Accordion, AccordionSummary, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MaterialTable, { Column } from 'material-table'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { filterAndSearchDate, formatDate, sortByDate } from 'src/utilities/dates'
import { Paths } from '../../constants/routes'
import {
  categoryModelPrimitives,
  issueModelPrimitives,
  IssueTemplateVoteModelSelector,
  IssueTemplateVoteModelType,
  subCategoryModelPrimitives,
  voteModelPrimitives,
} from '../../models'
import { useQuery } from '../../models/reactUtils'
import { rowDatum } from '../../utilities/coercion'

export type VotesTableProps = {
  legislatorId: string
}

const selector = new IssueTemplateVoteModelSelector()
  .issueTemplate((sel) =>
    sel
      .category(categoryModelPrimitives)
      .subCategory(subCategoryModelPrimitives)
      .issue(issueModelPrimitives),
  )
  .vote(voteModelPrimitives)
  .toString()

const VotesTable: React.FC<VotesTableProps> = ({ legislatorId }) => {
  const history = useHistory()
  const { loading, data } = useQuery<{ issueTemplateVotes: IssueTemplateVoteModelType[] }>(
    (store) => store.queryIssueTemplateVotes({ legislatorId }, selector),
  )
  const handleDetailsClick = React.useCallback(
    (row: IssueTemplateVoteModelType): void => {
      history.push(Paths.TemplateDetails.replace(':id', row.issueTemplate.id))
    },
    [history],
  )

  const columns = React.useMemo(
    (): Array<Column<IssueTemplateVoteModelType>> => [
      {
        title: 'Date',
        field: 'issueTemplate.issue.date',
        render: (d) => formatDate(d.issueTemplate.issue.date),
        customFilterAndSearch: (term: string, d) =>
          filterAndSearchDate(d.issueTemplate.issue.date, term),
        customSort: (a, b) => sortByDate(a.issueTemplate.issue.date, b.issueTemplate.issue.date),
        defaultSort: 'desc',
      },
      { title: 'Category', field: 'issueTemplate.category.title' },
      { title: 'Subcategory', field: 'issueTemplate.subCategory.title' },
      { title: 'Subcategory', field: 'issueTemplate.issue.legislationNumber' },
      { title: 'Description', field: 'issueTemplate.issue.description' },
      { title: 'Response', field: 'vote.value' },
    ],
    [],
  )

  const issueVotes: Array<IssueTemplateVoteModelType> = useMemo(() => {
    return data?.issueTemplateVotes || []
  }, [data])

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Vote History</Typography>
      </AccordionSummary>
      <MaterialTable
        data={issueVotes}
        localization={{ header: { actions: '' } }}
        style={{ boxShadow: 'none' }}
        columns={columns}
        options={{ toolbar: false, sorting: true, filtering: true }}
        isLoading={loading}
        actions={[
          {
            icon: 'pageview',
            tooltip: 'View details',
            onClick: (event, d) => handleDetailsClick(rowDatum(d)),
          },
        ]}
      />
    </Accordion>
  )
}

export default observer(VotesTable)
