import MaterialTable, { Column, Options, Query as TableQuery, QueryResult } from 'material-table'
import * as React from 'react'
import {
  LegislatorListResponseModelSelector,
  LegislatorListResponseModelType,
  legislatorModelPrimitives,
  LegislatorModelType,
  LegislatorsOrderColumns,
  RootStoreType,
} from '../../models'
import { Query } from 'mst-gql'
import { rowDatum } from '../../utilities/coercion'
import { extractOrderByArgs, extractPaginationArgs } from '../../utilities/tables'
import { useStore } from '../../getMstGql'
import { observer } from 'mobx-react-lite'
import { LegislatorsFilters } from '../../models/RootStore.base'
import { Accordion, AccordionSummary, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useTableCacheKey } from '../common/utils/table/useTableCacheKey'

type LegislatorsQueryResponseType = { legislators: LegislatorListResponseModelType }

const legislatorsSelector = new LegislatorListResponseModelSelector().count
  .records(legislatorModelPrimitives)
  .toString()

function queryLegislators(
  store: RootStoreType,
  tableQuery: TableQuery<LegislatorModelType>,
): Query<LegislatorsQueryResponseType> {
  const filters: LegislatorsFilters = {}
  tableQuery.filters.forEach((filter) => {
    switch (filter.column.field) {
      case 'firstName': {
        filters.firstName = filter.value
        break
      }
      case 'lastName': {
        filters.lastName = filter.value
        break
      }
      default:
        break
    }
  })
  return store.queryLegislators(
    {
      pagination: extractPaginationArgs(tableQuery),
      orderBy: extractOrderByArgs(tableQuery, LegislatorsOrderColumns),
      filters,
    },
    legislatorsSelector,
  )
}

export type LegislatorsTableProps = {
  filters?: {}
  tableOptions?: Options<LegislatorModelType>
  onRowClick?: (row: LegislatorModelType) => void
  cacheKey?: string | number
}

const LegislatorsTable: React.FC<LegislatorsTableProps> = ({
  onRowClick,
  cacheKey,
  tableOptions = {},
}) => {
  const store = useStore()
  const tableRef = useTableCacheKey(cacheKey)
  const handleDetailsClick = React.useCallback(
    (row: LegislatorModelType): void => onRowClick && onRowClick(row),
    [onRowClick],
  )

  const handleTableUpdate = React.useCallback(
    async (query: TableQuery<LegislatorModelType>): Promise<QueryResult<LegislatorModelType>> => {
      const results = await queryLegislators(store, query)
      return {
        data: results?.legislators?.records?.toJS() || [],
        totalCount: results?.legislators?.count || 0,
        page: query.page,
      }
    },
    [store],
  )

  const combinedTableOptions: Options<LegislatorModelType> = React.useMemo(
    () => ({
      filtering: true,
      toolbar: false,
      debounceInterval: 300,
      pageSize: 10,
      ...tableOptions,
    }),
    [tableOptions],
  )

  const columns = React.useMemo(
    (): Array<Column<LegislatorModelType>> => [
      {
        title: 'Last Name',
        field: 'lastName',
        defaultSort: 'asc',
        render: (d): string => d?.lastName || '',
      },
      { title: 'First Name', field: 'firstName', render: (d): string => d?.firstName || '' },
    ],
    [],
  )

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Legislators</Typography>
      </AccordionSummary>
      <MaterialTable
        tableRef={tableRef}
        columns={columns}
        data={handleTableUpdate}
        options={combinedTableOptions}
        localization={{ header: { actions: '' } }}
        style={{ boxShadow: 'none' }}
        actions={[
          {
            icon: 'pageview',
            tooltip: 'View details',
            onClick: (event, d): void => handleDetailsClick(rowDatum(d)),
          },
        ]}
      />
    </Accordion>
  )
}

export default observer(LegislatorsTable)
