import { Action, Column, Options, Query as TableQuery, QueryResult } from 'material-table'
import * as React from 'react'
import startCase from 'lodash/startCase'
import { RootStoreType } from '../../../../models'
import { useStore } from '../../../../getMstGql'
import { rowDatum } from '../../../../utilities/coercion'

export function defaultColumnFilter<T extends object>(columns: Array<Column<T>>): Array<Column<T>> {
  return columns
}

export type ColumnFilter<T extends object> = (columns: Array<Column<T>>) => Array<Column<T>>
export type ColumnPropGetter<T extends object> = (fieldName: keyof T | string) => Column<T>

export type ModelListQueryData<T> = {
  records: Array<T>
  count: number
}

export type ModelListQuery<T extends object> = (
  store: RootStoreType,
  tableQuery: TableQuery<T>,
  filters: any,
) => Promise<ModelListQueryData<T>>

export type ModelTableUpdateHandler<T extends object> = (
  query: TableQuery<T>,
) => Promise<QueryResult<T>>

export type ModelTableOptions<T extends object> = {
  materialTableOptions?: Options<T>
  listQuery: ModelListQuery<T>

  baseFilters: any
  filterColumns?: (columns: Array<Column<T>>) => Array<Column<T>>

  onRowClick?: (row: T) => void
  hideDetailsLink?: boolean

  orderColumns: Record<string, string>

  actions?: Array<Action<T>>
}

export type ModelTableInstance<T extends object> = {
  getColumnProps: ColumnPropGetter<T>
  columnFilter: ColumnFilter<T>
  handleTableUpdate: ModelTableUpdateHandler<T>
  // options: Options<T>
  actions: Array<Action<T>>
}

export function isValidSortField<ModelType>(
  columns: Record<string, string>,
  fieldName: keyof ModelType | string,
): boolean {
  return !!Object.values(columns).find(
    (col) => col.toLowerCase() === String(fieldName).toLowerCase(),
  )
}

export function useModelTable<T extends object>(
  options: ModelTableOptions<T>,
): ModelTableInstance<T> {
  const {
    filterColumns,
    orderColumns,
    listQuery,
    baseFilters,
    hideDetailsLink,
    onRowClick,
    actions: passedActions = [],
  } = options
  const store = useStore()
  const columnFilter: ColumnFilter<T> = React.useMemo(() => filterColumns || defaultColumnFilter, [
    filterColumns,
  ])

  const actions = React.useMemo((): Array<Action<T>> => {
    if (!onRowClick || hideDetailsLink) {
      return passedActions
    }
    return [
      ...actions,
      {
        icon: 'pageview',
        tooltip: 'View details',
        onClick: (event, d): void => onRowClick(rowDatum(d)),
      },
    ]
  }, [onRowClick, hideDetailsLink, passedActions])

  const getColumnProps = React.useCallback(
    (fieldName: keyof T | string): Column<T> => {
      const additionalProps = {
        filterOnItemSelect: true,
      } as any
      return {
        ...additionalProps,
        title: startCase(String(fieldName)),
        field: fieldName,
        sorting: isValidSortField<T>(orderColumns, fieldName),
        filtering: false,
      }
    },
    [orderColumns],
  )

  const handleTableUpdate = React.useCallback(
    async (query: TableQuery<T>): Promise<QueryResult<T>> => {
      const data = await listQuery(store, query, baseFilters)
      return {
        data: data.records,
        totalCount: data.count,
        page: query.page,
      }
    },
    [store, listQuery, baseFilters],
  )

  return React.useMemo(
    () => ({
      getColumnProps,
      columnFilter,
      handleTableUpdate,
      actions,
    }),
    [getColumnProps, columnFilter, handleTableUpdate, actions],
  )
}
