import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Button, Form, FormErrors, TextField } from 'src/components'
import { useStore } from 'src/getMstGql'
import * as Yup from 'yup'
import { Box } from '@material-ui/core'

const INITIAL_STATE = {
  email: '',
  password: '',
}

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().required('Email is required'),
  password: Yup.string().required('Password is required'),
})

const LoginUserForm: React.FC = () => {
  const [error, setError] = useState<string | undefined>()
  const store = useStore()

  return (
    <Formik
      initialValues={INITIAL_STATE}
      validationSchema={VALIDATION_SCHEMA}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={async (values, actions): Promise<void> => {
        actions.setSubmitting(true)
        try {
          const response = await store.login(values)
          if (!response.success) {
            setError(response.message || '')
            actions.setSubmitting(false)
            console.error(response)
          }
        } catch (error) {
          console.error(error)
          setError(error.message)
          actions.setSubmitting(false)
        }
      }}
    >
      {({ isSubmitting, submitForm }): React.ReactNode => (
        <Form>
          <FormErrors error={error} />
          <TextField
            name="email"
            type="email"
            placeholder="Enter your email"
            required
            autoComplete="email"
          />
          <TextField
            name="password"
            type="password"
            placeholder="Enter a password"
            required
            autoComplete="current-password"
          />
          <Box marginTop={2} display="flex">
            <Button type="submit" color="primary" disabled={isSubmitting} onClick={submitForm}>
              Login
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default observer(LoginUserForm)
