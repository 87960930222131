/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { CategoryModel, CategoryModelType } from './CategoryModel'
import { CategoryModelSelector } from './CategoryModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  category: CategoryModelType
}

/**
 * SubCategoryBase
 * auto generated base class for the model SubCategoryModel.
 */
export const SubCategoryModelBase = withTypedRefs<Refs>()(
  ModelBase.named('SubCategory')
    .props({
      __typename: types.optional(types.literal('SubCategory'), 'SubCategory'),
      id: types.identifier,
      createdAt: types.union(types.undefined, types.frozen()),
      updatedAt: types.union(types.undefined, types.frozen()),
      title: types.union(types.undefined, types.string),
      category: types.union(types.undefined, MSTGQLRef(types.late((): any => CategoryModel))),
      categoryId: types.union(types.undefined, types.string),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class SubCategoryModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  get title() {
    return this.__attr(`title`)
  }
  get categoryId() {
    return this.__attr(`categoryId`)
  }
  category(
    builder?:
      | string
      | CategoryModelSelector
      | ((selector: CategoryModelSelector) => CategoryModelSelector),
  ) {
    return this.__child(`category`, CategoryModelSelector, builder)
  }
}
export function selectFromSubCategory() {
  return new SubCategoryModelSelector()
}

export const subCategoryModelPrimitives = selectFromSubCategory().createdAt.updatedAt.title
  .categoryId
