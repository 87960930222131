import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import { LocalizationProvider } from '@material-ui/pickers'
import LuxonAdapter from '@material-ui/pickers/adapter/luxon'
import { Formik, FormikProps } from 'formik'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import {
  FieldGroup,
  FormButtonGroup,
  FormContainer,
  FormSection,
  FormSelectField,
  LoadingContainer,
  ReadOnlyTextField,
  useToast,
} from 'src/components/index'
import { useStore } from '../../getMstGql'
import {
  bookModelPrimitives,
  CategoryListResponseModelSelector,
  CategoryListResponseModelType,
  categoryModelPrimitives,
  CategoryModelType,
  GenerateVoteBookMutationResponseModelSelector,
  GenerateVoteBookMutationResponseModelType,
  LayerListResponseModelSelector,
  LayerListResponseModelType,
  layerModelPrimitives,
  LayerModelType,
  LegislatorModelType,
} from '../../models'
import { useQuery } from '../../models/reactUtils'
import { IssueTemplatesForLegislatorFilters } from '../../models/RootStore.base'
import { displayMutationError, hasMutationErrors } from '../../utilities/errors'
import DateInput from '../common/forms/DateInput'

type GenerateVoteBookResponse = { generateVoteBook: GenerateVoteBookMutationResponseModelType }

export interface GenerateVoteBookFormProps {
  onComplete: () => any
  legislator: LegislatorModelType
}

type GenerateVoteBookArgs = {
  legislatorId: string
  filters: IssueTemplatesForLegislatorFilters
}

const GenerateVoteBookForm: React.FC<GenerateVoteBookFormProps> = ({ onComplete, legislator }) => {
  const store = useStore()
  const { setToast } = useToast()
  const history = useHistory()

  const [selectedStartDate, setSelectedStartDate] = React.useState<DateTime | null>(null)
  const [selectedEndDate, setSelectedEndDate] = React.useState<DateTime | null>(null)
  const [addedSinceDate, setAddedSinceDate] = React.useState<DateTime | null>(null)

  const { loading: layersLoading, data: layersData } = useQuery<{
    layers: LayerListResponseModelType
  }>((store) =>
    store.queryLayers(
      {},
      new LayerListResponseModelSelector().count.records(layerModelPrimitives).toString(),
    ),
  )
  const layers: Array<LayerModelType> = useMemo(() => layersData?.layers.records || [], [
    layersData,
  ])

  const { loading: categoriesLoading, data: categoriesData } = useQuery<{
    categories: CategoryListResponseModelType
  }>((store) =>
    store.queryCategories(
      {},
      new CategoryListResponseModelSelector().records(categoryModelPrimitives).toString(),
    ),
  )
  const categories: Array<CategoryModelType> = useMemo(
    () => categoriesData?.categories.records || [],
    [categoriesData],
  )

  const loading = useMemo(() => layersLoading || categoriesLoading, [
    layersLoading,
    categoriesLoading,
  ])
  return (
    <LoadingContainer loading={loading}>
      <LocalizationProvider dateAdapter={LuxonAdapter}>
        <FormContainer title="Generate Vote Book">
          <Formik
            initialValues={{
              selectedLayerIds: [],
              onlyIncludeIssuesWithAllLayers: false,
              categoryId: '',
            }}
            validateOnBlur={false}
            onSubmit={async (values, actions) => {
              actions.setSubmitting(true)
              const { selectedLayerIds, onlyIncludeIssuesWithAllLayers, categoryId } = values
              const variables: GenerateVoteBookArgs = {
                legislatorId: legislator.id,
                filters: {
                  selectedLayerIds,
                  onlyIncludeIssuesWithAllLayers,
                },
              }
              if (selectedStartDate) {
                variables.filters.dateRange = variables.filters.dateRange || {}
                if (variables.filters.dateRange) {
                  variables.filters.dateRange.start = selectedStartDate.toJSDate()
                }
              }
              if (selectedEndDate) {
                variables.filters.dateRange = variables.filters.dateRange || {}
                if (variables.filters.dateRange) {
                  variables.filters.dateRange.end = selectedEndDate.toJSDate()
                }
              }
              if (categoryId) {
                variables.filters.categoryId = categoryId
              }

              if (addedSinceDate) {
                variables.filters.addedSince = addedSinceDate.toJSDate()
              }
              const query = store.mutateGenerateVoteBook(
                variables,
                new GenerateVoteBookMutationResponseModelSelector().message.success
                  .book(bookModelPrimitives)
                  .toString(),
              )
              const response = (await query.currentPromise()) as GenerateVoteBookResponse
              actions.setSubmitting(false)

              if (hasMutationErrors(response)) {
                setToast(displayMutationError(response))
                return
              }

              setToast({
                message: `Vote book ${response.generateVoteBook.book.title} is generating!`,
                variant: 'success',
              })
              onComplete()
              history.push('/books')
            }}
          >
            {(props: FormikProps<any>) => (
              <React.Fragment>
                <FormSection title="Legislator">
                  <FieldGroup>
                    <ReadOnlyTextField
                      label="Legislator name"
                      value={legislator.fullName}
                      fullWidth
                    />
                  </FieldGroup>
                </FormSection>

                <FormSection title="Category">
                  <FieldGroup>
                    <FormSelectField
                      name="categoryId"
                      label="Category"
                      options={categories.map((cat) => ({ value: cat.id, label: cat.title || '' }))}
                    />
                  </FieldGroup>
                </FormSection>

                <FormSection title="Layers">
                  <FieldGroup>
                    <FormSelectField
                      name="selectedLayerIds"
                      label="Layer"
                      multiple
                      options={layers.map((l) => ({ value: l.id, label: l.name || '' }))}
                    />
                  </FieldGroup>
                  <FieldGroup hidden={!props.values.selectedLayerIds.length}>
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          checked={props.values.onlyIncludeIssuesWithAllLayers}
                          onChange={() =>
                            props.setFieldValue(
                              'onlyIncludeIssuesWithAllLayers',
                              !props.values.onlyIncludeIssuesWithAllLayers,
                              false,
                            )
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label="Only include votes that have all specified layers"
                      labelPlacement="end"
                    />
                  </FieldGroup>
                </FormSection>

                <FormSection title="Issue Date Range">
                  <FieldGroup>
                    <DateInput
                      label="Start date"
                      value={selectedStartDate}
                      onChange={setSelectedStartDate}
                    />
                    <DateInput
                      label="End date"
                      value={selectedEndDate}
                      onChange={setSelectedEndDate}
                    />
                  </FieldGroup>
                </FormSection>

                <FormSection title="Added Since">
                  <FieldGroup>
                    <DateInput value={addedSinceDate} onChange={setAddedSinceDate} />
                  </FieldGroup>
                </FormSection>

                <FormButtonGroup>
                  <Button
                    variant="contained"
                    onClick={() => {
                      props.resetForm()
                      onComplete()
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={props.isSubmitting}
                    onClick={props.submitForm}
                  >
                    Run
                  </Button>
                </FormButtonGroup>
              </React.Fragment>
            )}
          </Formik>
        </FormContainer>
      </LocalizationProvider>
    </LoadingContainer>
  )
}

export default observer(GenerateVoteBookForm)
