import BookIcon from '@material-ui/icons/Book'
import CategoryIcon from '@material-ui/icons/Category'
import DashboardIcon from '@material-ui/icons/Dashboard'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import GavelIcon from '@material-ui/icons/Gavel'
import LayersIcon from '@material-ui/icons/Layers'
import PeopleIcon from '@material-ui/icons/People'
import HowToVoteIcon from '@material-ui/icons/HowToVote'
import * as React from 'react'
import { UserRole } from '../models'

export enum Paths {
  Dashboard = '/',
  Login = '/login',
  TemplatesList = '/templates',
  TemplateDetails = '/templates/:id',
  VotesList = '/votes',
  VoteDetails = '/votes/:id',
  EditIssueVotes = '/votes/:id/edit',
  LegislatorsList = '/legislators',
  LegislatorDetails = '/legislators/:id',
  CategoriesList = '/categories',
  CategoryDetails = '/categories/:id',
  LayersList = '/layers',
  LayerDetails = '/layers/:id',
  BooksList = '/books',
  UsersList = '/users',
  UserDetails = '/users/:id',
  UserProfile = '/profile',
  ImportGroupsList = '/importGroups',
  ImportGroupDetails = '/importGroups/:id',
  EditImportGroupLegislators = '/importGroups/:id/edit',
}

export const POST_LOGIN_PATH = Paths.Dashboard

export type NavigationItem = {
  path: Paths
  title: string
  icon: React.ComponentType
  requiredRole?: UserRole
}
export const NAVIGATION_ITEMS: Array<NavigationItem> = [
  { path: Paths.Dashboard, title: 'Dashboard', icon: DashboardIcon },
  { path: Paths.LegislatorsList, title: 'Legislators', icon: GavelIcon },
  { path: Paths.TemplatesList, title: 'Issue templates', icon: FormatListBulletedIcon },
  { path: Paths.VotesList, title: 'Votes', icon: HowToVoteIcon },
  { path: Paths.CategoriesList, title: 'Categories', icon: CategoryIcon },
  { path: Paths.LayersList, title: 'Layers', icon: LayersIcon },
  { path: Paths.BooksList, title: 'Books', icon: BookIcon },
  { path: Paths.UsersList, title: 'Users', icon: PeopleIcon, requiredRole: UserRole.ADMIN },
  { path: Paths.ImportGroupsList, title: 'Import groups', icon: ImportExportIcon },
]
