/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { ObservableMap } from 'mobx'
import { types } from 'mobx-state-tree'
import { MSTGQLStore, configureStoreMixin, QueryOptions, withTypedRefs } from 'mst-gql'

import { ObjectPropertyModel, ObjectPropertyModelType } from './ObjectPropertyModel'
import {
  objectPropertyModelPrimitives,
  ObjectPropertyModelSelector,
} from './ObjectPropertyModel.base'
import { UserModel, UserModelType } from './UserModel'
import { userModelPrimitives, UserModelSelector } from './UserModel.base'
import { UserListResponseModel, UserListResponseModelType } from './UserListResponseModel'
import {
  userListResponseModelPrimitives,
  UserListResponseModelSelector,
} from './UserListResponseModel.base'
import { LegislatorModel, LegislatorModelType } from './LegislatorModel'
import { legislatorModelPrimitives, LegislatorModelSelector } from './LegislatorModel.base'
import { LegislatorIdentityModel, LegislatorIdentityModelType } from './LegislatorIdentityModel'
import {
  legislatorIdentityModelPrimitives,
  LegislatorIdentityModelSelector,
} from './LegislatorIdentityModel.base'
import { TermModel, TermModelType } from './TermModel'
import { termModelPrimitives, TermModelSelector } from './TermModel.base'
import { VoteModel, VoteModelType } from './VoteModel'
import { voteModelPrimitives, VoteModelSelector } from './VoteModel.base'
import { IssueModel, IssueModelType } from './IssueModel'
import { issueModelPrimitives, IssueModelSelector } from './IssueModel.base'
import {
  LegislatorListResponseModel,
  LegislatorListResponseModelType,
} from './LegislatorListResponseModel'
import {
  legislatorListResponseModelPrimitives,
  LegislatorListResponseModelSelector,
} from './LegislatorListResponseModel.base'
import { TermListResponseModel, TermListResponseModelType } from './TermListResponseModel'
import {
  termListResponseModelPrimitives,
  TermListResponseModelSelector,
} from './TermListResponseModel.base'
import { BookModel, BookModelType } from './BookModel'
import { bookModelPrimitives, BookModelSelector } from './BookModel.base'
import { BookListResponseModel, BookListResponseModelType } from './BookListResponseModel'
import {
  bookListResponseModelPrimitives,
  BookListResponseModelSelector,
} from './BookListResponseModel.base'
import { CategoryModel, CategoryModelType } from './CategoryModel'
import { categoryModelPrimitives, CategoryModelSelector } from './CategoryModel.base'
import { SubCategoryModel, SubCategoryModelType } from './SubCategoryModel'
import { subCategoryModelPrimitives, SubCategoryModelSelector } from './SubCategoryModel.base'
import {
  CategoryListResponseModel,
  CategoryListResponseModelType,
} from './CategoryListResponseModel'
import {
  categoryListResponseModelPrimitives,
  CategoryListResponseModelSelector,
} from './CategoryListResponseModel.base'
import { VoteListResponseModel, VoteListResponseModelType } from './VoteListResponseModel'
import {
  voteListResponseModelPrimitives,
  VoteListResponseModelSelector,
} from './VoteListResponseModel.base'
import { IssueListResponseModel, IssueListResponseModelType } from './IssueListResponseModel'
import {
  issueListResponseModelPrimitives,
  IssueListResponseModelSelector,
} from './IssueListResponseModel.base'
import { IssueTemplateModel, IssueTemplateModelType } from './IssueTemplateModel'
import { issueTemplateModelPrimitives, IssueTemplateModelSelector } from './IssueTemplateModel.base'
import { AssignedLayerModel, AssignedLayerModelType } from './AssignedLayerModel'
import { assignedLayerModelPrimitives, AssignedLayerModelSelector } from './AssignedLayerModel.base'
import { LayerModel, LayerModelType } from './LayerModel'
import { layerModelPrimitives, LayerModelSelector } from './LayerModel.base'
import { BlurbModel, BlurbModelType } from './BlurbModel'
import { blurbModelPrimitives, BlurbModelSelector } from './BlurbModel.base'
import {
  IssueTemplateListResponseModel,
  IssueTemplateListResponseModelType,
} from './IssueTemplateListResponseModel'
import {
  issueTemplateListResponseModelPrimitives,
  IssueTemplateListResponseModelSelector,
} from './IssueTemplateListResponseModel.base'
import { IssueTemplateVoteModel, IssueTemplateVoteModelType } from './IssueTemplateVoteModel'
import {
  issueTemplateVoteModelPrimitives,
  IssueTemplateVoteModelSelector,
} from './IssueTemplateVoteModel.base'
import { LayerListResponseModel, LayerListResponseModelType } from './LayerListResponseModel'
import {
  layerListResponseModelPrimitives,
  LayerListResponseModelSelector,
} from './LayerListResponseModel.base'
import {
  AssignedLayerListResponseModel,
  AssignedLayerListResponseModelType,
} from './AssignedLayerListResponseModel'
import {
  assignedLayerListResponseModelPrimitives,
  AssignedLayerListResponseModelSelector,
} from './AssignedLayerListResponseModel.base'
import {
  SubCategoryListResponseModel,
  SubCategoryListResponseModelType,
} from './SubCategoryListResponseModel'
import {
  subCategoryListResponseModelPrimitives,
  SubCategoryListResponseModelSelector,
} from './SubCategoryListResponseModel.base'
import { BlurbListResponseModel, BlurbListResponseModelType } from './BlurbListResponseModel'
import {
  blurbListResponseModelPrimitives,
  BlurbListResponseModelSelector,
} from './BlurbListResponseModel.base'
import { ImportGroupModel, ImportGroupModelType } from './ImportGroupModel'
import { importGroupModelPrimitives, ImportGroupModelSelector } from './ImportGroupModel.base'
import {
  ImportGroupListResponseModel,
  ImportGroupListResponseModelType,
} from './ImportGroupListResponseModel'
import {
  importGroupListResponseModelPrimitives,
  ImportGroupListResponseModelSelector,
} from './ImportGroupListResponseModel.base'
import { UserLoginResponseModel, UserLoginResponseModelType } from './UserLoginResponseModel'
import {
  userLoginResponseModelPrimitives,
  UserLoginResponseModelSelector,
} from './UserLoginResponseModel.base'
import { MutationResponseModel, MutationResponseModelType } from './MutationResponseModel'
import {
  mutationResponseModelPrimitives,
  MutationResponseModelSelector,
} from './MutationResponseModel.base'
import {
  RefreshTokenResponseModel,
  RefreshTokenResponseModelType,
} from './RefreshTokenResponseModel'
import {
  refreshTokenResponseModelPrimitives,
  RefreshTokenResponseModelSelector,
} from './RefreshTokenResponseModel.base'
import {
  UserCreationResponseModel,
  UserCreationResponseModelType,
} from './UserCreationResponseModel'
import {
  userCreationResponseModelPrimitives,
  UserCreationResponseModelSelector,
} from './UserCreationResponseModel.base'
import { UserUpdateResponseModel, UserUpdateResponseModelType } from './UserUpdateResponseModel'
import {
  userUpdateResponseModelPrimitives,
  UserUpdateResponseModelSelector,
} from './UserUpdateResponseModel.base'
import {
  LegislatorCreationResponseModel,
  LegislatorCreationResponseModelType,
} from './LegislatorCreationResponseModel'
import {
  legislatorCreationResponseModelPrimitives,
  LegislatorCreationResponseModelSelector,
} from './LegislatorCreationResponseModel.base'
import {
  LegislatorUpdateResponseModel,
  LegislatorUpdateResponseModelType,
} from './LegislatorUpdateResponseModel'
import {
  legislatorUpdateResponseModelPrimitives,
  LegislatorUpdateResponseModelSelector,
} from './LegislatorUpdateResponseModel.base'
import {
  TermCreationResponseModel,
  TermCreationResponseModelType,
} from './TermCreationResponseModel'
import {
  termCreationResponseModelPrimitives,
  TermCreationResponseModelSelector,
} from './TermCreationResponseModel.base'
import { TermUpdateResponseModel, TermUpdateResponseModelType } from './TermUpdateResponseModel'
import {
  termUpdateResponseModelPrimitives,
  TermUpdateResponseModelSelector,
} from './TermUpdateResponseModel.base'
import {
  GenerateVoteBookMutationResponseModel,
  GenerateVoteBookMutationResponseModelType,
} from './GenerateVoteBookMutationResponseModel'
import {
  generateVoteBookMutationResponseModelPrimitives,
  GenerateVoteBookMutationResponseModelSelector,
} from './GenerateVoteBookMutationResponseModel.base'
import {
  CategoryCreationResponseModel,
  CategoryCreationResponseModelType,
} from './CategoryCreationResponseModel'
import {
  categoryCreationResponseModelPrimitives,
  CategoryCreationResponseModelSelector,
} from './CategoryCreationResponseModel.base'
import {
  CategoryUpdateResponseModel,
  CategoryUpdateResponseModelType,
} from './CategoryUpdateResponseModel'
import {
  categoryUpdateResponseModelPrimitives,
  CategoryUpdateResponseModelSelector,
} from './CategoryUpdateResponseModel.base'
import {
  IssueCreationResponseModel,
  IssueCreationResponseModelType,
} from './IssueCreationResponseModel'
import {
  issueCreationResponseModelPrimitives,
  IssueCreationResponseModelSelector,
} from './IssueCreationResponseModel.base'
import { IssueUpdateResponseModel, IssueUpdateResponseModelType } from './IssueUpdateResponseModel'
import {
  issueUpdateResponseModelPrimitives,
  IssueUpdateResponseModelSelector,
} from './IssueUpdateResponseModel.base'
import {
  IssueTemplateCreationResponseModel,
  IssueTemplateCreationResponseModelType,
} from './IssueTemplateCreationResponseModel'
import {
  issueTemplateCreationResponseModelPrimitives,
  IssueTemplateCreationResponseModelSelector,
} from './IssueTemplateCreationResponseModel.base'
import {
  IssueTemplateUpdateResponseModel,
  IssueTemplateUpdateResponseModelType,
} from './IssueTemplateUpdateResponseModel'
import {
  issueTemplateUpdateResponseModelPrimitives,
  IssueTemplateUpdateResponseModelSelector,
} from './IssueTemplateUpdateResponseModel.base'
import {
  LayerCreationResponseModel,
  LayerCreationResponseModelType,
} from './LayerCreationResponseModel'
import {
  layerCreationResponseModelPrimitives,
  LayerCreationResponseModelSelector,
} from './LayerCreationResponseModel.base'
import { LayerUpdateResponseModel, LayerUpdateResponseModelType } from './LayerUpdateResponseModel'
import {
  layerUpdateResponseModelPrimitives,
  LayerUpdateResponseModelSelector,
} from './LayerUpdateResponseModel.base'
import {
  AssignedLayerCreationResponseModel,
  AssignedLayerCreationResponseModelType,
} from './AssignedLayerCreationResponseModel'
import {
  assignedLayerCreationResponseModelPrimitives,
  AssignedLayerCreationResponseModelSelector,
} from './AssignedLayerCreationResponseModel.base'
import {
  AssignedLayerUpdateResponseModel,
  AssignedLayerUpdateResponseModelType,
} from './AssignedLayerUpdateResponseModel'
import {
  assignedLayerUpdateResponseModelPrimitives,
  AssignedLayerUpdateResponseModelSelector,
} from './AssignedLayerUpdateResponseModel.base'
import {
  SubCategoryCreationResponseModel,
  SubCategoryCreationResponseModelType,
} from './SubCategoryCreationResponseModel'
import {
  subCategoryCreationResponseModelPrimitives,
  SubCategoryCreationResponseModelSelector,
} from './SubCategoryCreationResponseModel.base'
import {
  SubCategoryUpdateResponseModel,
  SubCategoryUpdateResponseModelType,
} from './SubCategoryUpdateResponseModel'
import {
  subCategoryUpdateResponseModelPrimitives,
  SubCategoryUpdateResponseModelSelector,
} from './SubCategoryUpdateResponseModel.base'
import {
  BlurbCreationResponseModel,
  BlurbCreationResponseModelType,
} from './BlurbCreationResponseModel'
import {
  blurbCreationResponseModelPrimitives,
  BlurbCreationResponseModelSelector,
} from './BlurbCreationResponseModel.base'
import { BlurbUpdateResponseModel, BlurbUpdateResponseModelType } from './BlurbUpdateResponseModel'
import {
  blurbUpdateResponseModelPrimitives,
  BlurbUpdateResponseModelSelector,
} from './BlurbUpdateResponseModel.base'
import {
  ImportIssueMutationResponseModel,
  ImportIssueMutationResponseModelType,
} from './ImportIssueMutationResponseModel'
import {
  importIssueMutationResponseModelPrimitives,
  ImportIssueMutationResponseModelSelector,
} from './ImportIssueMutationResponseModel.base'
import {
  ImportGroupCreationResponseModel,
  ImportGroupCreationResponseModelType,
} from './ImportGroupCreationResponseModel'
import {
  importGroupCreationResponseModelPrimitives,
  ImportGroupCreationResponseModelSelector,
} from './ImportGroupCreationResponseModel.base'
import {
  ImportGroupUpdateResponseModel,
  ImportGroupUpdateResponseModelType,
} from './ImportGroupUpdateResponseModel'
import {
  importGroupUpdateResponseModelPrimitives,
  ImportGroupUpdateResponseModelSelector,
} from './ImportGroupUpdateResponseModel.base'

import { UserRole } from './UserRoleEnum'
import { Gender } from './GenderEnum'
import { Party } from './PartyEnum'
import { GovernmentLevel } from './GovernmentLevelEnum'
import { Chamber } from './ChamberEnum'
import { State } from './StateEnum'
import { IssueResult } from './IssueResultEnum'
import { VoteValue } from './VoteValueEnum'
import { LegislatorsOrderColumns } from './LegislatorsOrderColumnsEnum'
import { OrderByDirection } from './OrderByDirectionEnum'
import { TermsOrderColumns } from './TermsOrderColumnsEnum'
import { BookStatus } from './BookStatusEnum'
import { CategoryOrderColumns } from './CategoryOrderColumnsEnum'
import { VotesOrderColumns } from './VotesOrderColumnsEnum'
import { IssuesOrderColumns } from './IssuesOrderColumnsEnum'
import { IssueTemplatesOrderColumns } from './IssueTemplatesOrderColumnsEnum'

export type PaginationArgs = {
  limit: number
  offset: number
}
export type LegislatorsFilters = {
  firstName?: string
  lastName?: string
}
export type LegislatorsOrderBy = {
  column: LegislatorsOrderColumns
  direction: OrderByDirection
}
export type TermsFilters = {
  legislatorId?: string
  state?: State[]
  level?: GovernmentLevel[]
  party?: Party[]
  chamber?: Chamber[]
  startDate?: DateRangeInput
  endDate?: DateRangeInput
}
export type DateRangeInput = {
  start?: any
  end?: any
}
export type TermsOrderBy = {
  column: TermsOrderColumns
  direction: OrderByDirection
}
export type CategoriesFilters = {
  title?: string
  subCategories?: string
}
export type CategoriesOrderBy = {
  column: CategoryOrderColumns
  direction: OrderByDirection
}
export type VotesFilters = {
  legislatorId?: string
  lastName?: string
}
export type VotesOrderBy = {
  column: VotesOrderColumns
  direction: OrderByDirection
}
export type IssuesFilters = {
  date?: DateRangeInput
  rollCallNumber?: string
  legislationNumber?: string
  level?: GovernmentLevel
  chamber?: Chamber
  state?: State
}
export type IssuesOrderBy = {
  column: IssuesOrderColumns
  direction: OrderByDirection
}
export type IssueTemplatesFilters = {
  date?: DateRangeInput
  createdAt?: DateRangeInput
  rollCallNumber?: string
  legislationNumber?: string
  category?: string
  subCategory?: string
  level?: GovernmentLevel
  chamber?: Chamber
  state?: State
}
export type IssueTemplatesOrderBy = {
  column: IssueTemplatesOrderColumns
  direction: OrderByDirection
}
export type UserLoginArgs = {
  email: string
  password: string
}
export type CreateUserInput = {
  email: string
  password: string
  name: string
  role: UserRole
}
export type UpdateUserInput = {
  password?: string
  name?: string
  role?: UserRole
}
export type CreateLegislatorInput = {
  gender?: Gender
  birthday?: any
  identity?: LegislatorIdentityInput
  firstName: string
  lastName: string
  bioguideId?: string
}
export type LegislatorIdentityInput = {
  bioguide?: string
  thomas?: string
  lis?: string
  govTrack?: number
  openSecrets?: string
  voteSmart?: number
  fec?: string[]
  cspan?: number
  wikipedia?: string
  houseHistory?: number
  ballotpedia?: string
  mapLight?: number
  icpsr?: number
  wikidata?: string
  googleEntityId?: string
}
export type UpdateLegislatorInput = {
  gender?: Gender
  birthday?: any
  identity?: LegislatorIdentityInput
  firstName?: string
  lastName?: string
  bioguideId?: string
}
export type CreateTermInput = {
  district?: number
  party: Party
  startDate: any
  endDate: any
  level: GovernmentLevel
  chamber: Chamber
  state: State
  legislatorId: string
}
export type UpdateTermInput = {
  district?: number
  party?: Party
  startDate?: any
  endDate?: any
  level?: GovernmentLevel
  chamber?: Chamber
  state?: State
  legislatorId?: string
}
export type IssueTemplatesForLegislatorFilters = {
  selectedLayerIds?: string[]
  onlyIncludeIssuesWithAllLayers?: boolean
  categoryId?: string
  dateRange?: DateRangeInput
  addedSince?: any
}
export type CreateCategoryInput = {
  title: string
}
export type UpdateCategoryInput = {
  title?: string
}
export type BulkEditVoteRecord = {
  legislatorId: string
  party: Party
  value: VoteValue
}
export type CreateIssueInput = {
  sessionName?: string
  voteType?: string
  rawResult?: string
  result: IssueResult
  url: string
  level: GovernmentLevel
  chamber: Chamber
  state?: State
  legislationNumber: string
  rollCallNumber: number
  description: string
  date: any
}
export type UpdateIssueInput = {
  sessionName?: string
  voteType?: string
  rawResult?: string
  result?: IssueResult
  url?: string
  level?: GovernmentLevel
  chamber?: Chamber
  state?: State
  legislationNumber?: string
  rollCallNumber?: number
  description?: string
  date?: any
}
export type CreateIssueTemplateInput = {
  issueId: string
  title: string
  summary: string
  categoryId: string
  subCategoryId?: string
}
export type UpdateIssueTemplateInput = {
  issueId?: string
  title?: string
  summary?: string
  categoryId?: string
  subCategoryId?: string
}
export type CreateLayerInput = {
  name: string
}
export type UpdateLayerInput = {
  name?: string
}
export type CreateAssignedLayerInput = {
  issueTemplateId: string
  layerId: string
  text: string
  url?: string
}
export type UpdateAssignedLayerInput = {
  issueTemplateId?: string
  layerId?: string
  text?: string
  url?: string
}
export type CreateSubCategoryInput = {
  title: string
  categoryId: string
}
export type UpdateSubCategoryInput = {
  title?: string
  categoryId?: string
}
export type CreateBlurbInput = {
  issueTemplateId: string
  name: string
  text: string
  url?: string
}
export type UpdateBlurbInput = {
  issueTemplateId?: string
  name?: string
  text?: string
  url?: string
}
export type CreateImportGroupInput = {
  name: string
}
export type UpdateImportGroupInput = {
  name?: string
}
/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  users: ObservableMap<string, UserModelType>
  legislators: ObservableMap<string, LegislatorModelType>
  terms: ObservableMap<string, TermModelType>
  votes: ObservableMap<string, VoteModelType>
  issues: ObservableMap<string, IssueModelType>
  books: ObservableMap<string, BookModelType>
  categories: ObservableMap<string, CategoryModelType>
  subCategories: ObservableMap<string, SubCategoryModelType>
  issueTemplates: ObservableMap<string, IssueTemplateModelType>
  assignedLayers: ObservableMap<string, AssignedLayerModelType>
  layers: ObservableMap<string, LayerModelType>
  blurbs: ObservableMap<string, BlurbModelType>
  importGroups: ObservableMap<string, ImportGroupModelType>
}

/**
 * Enums for the names of base graphql actions
 */
export enum RootStoreBaseQueries {
  queryGetObjectProperties = 'queryGetObjectProperties',
  queryUser = 'queryUser',
  queryUsers = 'queryUsers',
  queryWhoAmI = 'queryWhoAmI',
  queryLegislator = 'queryLegislator',
  queryLegislators = 'queryLegislators',
  queryLegislatorsByTerm = 'queryLegislatorsByTerm',
  queryLegislatorsByName = 'queryLegislatorsByName',
  queryTerm = 'queryTerm',
  queryTerms = 'queryTerms',
  queryBook = 'queryBook',
  queryBooks = 'queryBooks',
  queryCategory = 'queryCategory',
  queryCategories = 'queryCategories',
  queryVotes = 'queryVotes',
  queryIssue = 'queryIssue',
  queryIssues = 'queryIssues',
  queryIssueTemplate = 'queryIssueTemplate',
  queryIssueTemplates = 'queryIssueTemplates',
  queryIssueTemplateVotes = 'queryIssueTemplateVotes',
  queryLayer = 'queryLayer',
  queryLayers = 'queryLayers',
  queryAssignedLayer = 'queryAssignedLayer',
  queryAssignedLayers = 'queryAssignedLayers',
  querySubCategory = 'querySubCategory',
  querySubCategories = 'querySubCategories',
  queryBlurb = 'queryBlurb',
  queryBlurbs = 'queryBlurbs',
  queryImportGroup = 'queryImportGroup',
  queryImportGroups = 'queryImportGroups',
}
export enum RootStoreBaseMutations {
  mutateLogin = 'mutateLogin',
  mutateLogout = 'mutateLogout',
  mutateRefreshToken = 'mutateRefreshToken',
  mutateDeleteUser = 'mutateDeleteUser',
  mutateCreateUser = 'mutateCreateUser',
  mutateUpdateUser = 'mutateUpdateUser',
  mutateCreateLegislator = 'mutateCreateLegislator',
  mutateUpdateLegislator = 'mutateUpdateLegislator',
  mutateDeleteLegislator = 'mutateDeleteLegislator',
  mutateCreateTerm = 'mutateCreateTerm',
  mutateUpdateTerm = 'mutateUpdateTerm',
  mutateDeleteTerm = 'mutateDeleteTerm',
  mutateDeleteBook = 'mutateDeleteBook',
  mutateGenerateVoteBook = 'mutateGenerateVoteBook',
  mutateCreateCategory = 'mutateCreateCategory',
  mutateUpdateCategory = 'mutateUpdateCategory',
  mutateDeleteCategory = 'mutateDeleteCategory',
  mutateBulkEditVotes = 'mutateBulkEditVotes',
  mutateCreateIssue = 'mutateCreateIssue',
  mutateUpdateIssue = 'mutateUpdateIssue',
  mutateDeleteIssue = 'mutateDeleteIssue',
  mutateCreateIssueTemplate = 'mutateCreateIssueTemplate',
  mutateUpdateIssueTemplate = 'mutateUpdateIssueTemplate',
  mutateDeleteIssueTemplate = 'mutateDeleteIssueTemplate',
  mutateCreateLayer = 'mutateCreateLayer',
  mutateUpdateLayer = 'mutateUpdateLayer',
  mutateDeleteLayer = 'mutateDeleteLayer',
  mutateCreateAssignedLayer = 'mutateCreateAssignedLayer',
  mutateUpdateAssignedLayer = 'mutateUpdateAssignedLayer',
  mutateDeleteAssignedLayer = 'mutateDeleteAssignedLayer',
  mutateCreateSubCategory = 'mutateCreateSubCategory',
  mutateUpdateSubCategory = 'mutateUpdateSubCategory',
  mutateDeleteSubCategory = 'mutateDeleteSubCategory',
  mutateCreateBlurb = 'mutateCreateBlurb',
  mutateUpdateBlurb = 'mutateUpdateBlurb',
  mutateDeleteBlurb = 'mutateDeleteBlurb',
  mutateImportIssue = 'mutateImportIssue',
  mutateCreateImportGroup = 'mutateCreateImportGroup',
  mutateUpdateImportGroup = 'mutateUpdateImportGroup',
  mutateDeleteImportGroup = 'mutateDeleteImportGroup',
  mutateSetImportGroupLegislators = 'mutateSetImportGroupLegislators',
}

/**
 * Store, managing, among others, all the objects received through graphQL
 */
export const RootStoreBase = withTypedRefs<Refs>()(
  MSTGQLStore.named('RootStore')
    .extend(
      configureStoreMixin(
        [
          ['ObjectProperty', () => ObjectPropertyModel],
          ['User', () => UserModel],
          ['UserListResponse', () => UserListResponseModel],
          ['Legislator', () => LegislatorModel],
          ['LegislatorIdentity', () => LegislatorIdentityModel],
          ['Term', () => TermModel],
          ['Vote', () => VoteModel],
          ['Issue', () => IssueModel],
          ['LegislatorListResponse', () => LegislatorListResponseModel],
          ['TermListResponse', () => TermListResponseModel],
          ['Book', () => BookModel],
          ['BookListResponse', () => BookListResponseModel],
          ['Category', () => CategoryModel],
          ['SubCategory', () => SubCategoryModel],
          ['CategoryListResponse', () => CategoryListResponseModel],
          ['VoteListResponse', () => VoteListResponseModel],
          ['IssueListResponse', () => IssueListResponseModel],
          ['IssueTemplate', () => IssueTemplateModel],
          ['AssignedLayer', () => AssignedLayerModel],
          ['Layer', () => LayerModel],
          ['Blurb', () => BlurbModel],
          ['IssueTemplateListResponse', () => IssueTemplateListResponseModel],
          ['IssueTemplateVote', () => IssueTemplateVoteModel],
          ['LayerListResponse', () => LayerListResponseModel],
          ['AssignedLayerListResponse', () => AssignedLayerListResponseModel],
          ['SubCategoryListResponse', () => SubCategoryListResponseModel],
          ['BlurbListResponse', () => BlurbListResponseModel],
          ['ImportGroup', () => ImportGroupModel],
          ['ImportGroupListResponse', () => ImportGroupListResponseModel],
          ['UserLoginResponse', () => UserLoginResponseModel],
          ['MutationResponse', () => MutationResponseModel],
          ['RefreshTokenResponse', () => RefreshTokenResponseModel],
          ['UserCreationResponse', () => UserCreationResponseModel],
          ['UserUpdateResponse', () => UserUpdateResponseModel],
          ['LegislatorCreationResponse', () => LegislatorCreationResponseModel],
          ['LegislatorUpdateResponse', () => LegislatorUpdateResponseModel],
          ['TermCreationResponse', () => TermCreationResponseModel],
          ['TermUpdateResponse', () => TermUpdateResponseModel],
          ['GenerateVoteBookMutationResponse', () => GenerateVoteBookMutationResponseModel],
          ['CategoryCreationResponse', () => CategoryCreationResponseModel],
          ['CategoryUpdateResponse', () => CategoryUpdateResponseModel],
          ['IssueCreationResponse', () => IssueCreationResponseModel],
          ['IssueUpdateResponse', () => IssueUpdateResponseModel],
          ['IssueTemplateCreationResponse', () => IssueTemplateCreationResponseModel],
          ['IssueTemplateUpdateResponse', () => IssueTemplateUpdateResponseModel],
          ['LayerCreationResponse', () => LayerCreationResponseModel],
          ['LayerUpdateResponse', () => LayerUpdateResponseModel],
          ['AssignedLayerCreationResponse', () => AssignedLayerCreationResponseModel],
          ['AssignedLayerUpdateResponse', () => AssignedLayerUpdateResponseModel],
          ['SubCategoryCreationResponse', () => SubCategoryCreationResponseModel],
          ['SubCategoryUpdateResponse', () => SubCategoryUpdateResponseModel],
          ['BlurbCreationResponse', () => BlurbCreationResponseModel],
          ['BlurbUpdateResponse', () => BlurbUpdateResponseModel],
          ['ImportIssueMutationResponse', () => ImportIssueMutationResponseModel],
          ['ImportGroupCreationResponse', () => ImportGroupCreationResponseModel],
          ['ImportGroupUpdateResponse', () => ImportGroupUpdateResponseModel],
        ],
        [
          'User',
          'Legislator',
          'Term',
          'Vote',
          'Issue',
          'Book',
          'Category',
          'SubCategory',
          'IssueTemplate',
          'AssignedLayer',
          'Layer',
          'Blurb',
          'ImportGroup',
        ],
        'js',
      ),
    )
    .props({
      users: types.optional(types.map(types.late((): any => UserModel)), {}),
      legislators: types.optional(types.map(types.late((): any => LegislatorModel)), {}),
      terms: types.optional(types.map(types.late((): any => TermModel)), {}),
      votes: types.optional(types.map(types.late((): any => VoteModel)), {}),
      issues: types.optional(types.map(types.late((): any => IssueModel)), {}),
      books: types.optional(types.map(types.late((): any => BookModel)), {}),
      categories: types.optional(types.map(types.late((): any => CategoryModel)), {}),
      subCategories: types.optional(types.map(types.late((): any => SubCategoryModel)), {}),
      issueTemplates: types.optional(types.map(types.late((): any => IssueTemplateModel)), {}),
      assignedLayers: types.optional(types.map(types.late((): any => AssignedLayerModel)), {}),
      layers: types.optional(types.map(types.late((): any => LayerModel)), {}),
      blurbs: types.optional(types.map(types.late((): any => BlurbModel)), {}),
      importGroups: types.optional(types.map(types.late((): any => ImportGroupModel)), {}),
    })
    .actions((self) => ({
      queryGetObjectProperties(
        variables: { objectName: string },
        resultSelector:
          | string
          | ((
              qb: ObjectPropertyModelSelector,
            ) => ObjectPropertyModelSelector) = objectPropertyModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ getObjectProperties: ObjectPropertyModelType[] }>(
          `query getObjectProperties($objectName: String!) { getObjectProperties(objectName: $objectName) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new ObjectPropertyModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryUser(
        variables: { id: string },
        resultSelector:
          | string
          | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ user: UserModelType }>(
          `query user($id: ID!) { user(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new UserModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryUsers(
        variables: { pagination?: PaginationArgs },
        resultSelector:
          | string
          | ((
              qb: UserListResponseModelSelector,
            ) => UserListResponseModelSelector) = userListResponseModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ users: UserListResponseModelType }>(
          `query users($pagination: PaginationArgs) { users(pagination: $pagination) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new UserListResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryWhoAmI(
        variables?: {},
        resultSelector:
          | string
          | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ whoAmI: UserModelType }>(
          `query whoAmI { whoAmI {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new UserModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryLegislator(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: LegislatorModelSelector,
            ) => LegislatorModelSelector) = legislatorModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ legislator: LegislatorModelType }>(
          `query legislator($id: ID!) { legislator(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new LegislatorModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryLegislators(
        variables: {
          filters?: LegislatorsFilters
          orderBy?: LegislatorsOrderBy
          pagination?: PaginationArgs
        },
        resultSelector:
          | string
          | ((
              qb: LegislatorListResponseModelSelector,
            ) => LegislatorListResponseModelSelector) = legislatorListResponseModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ legislators: LegislatorListResponseModelType }>(
          `query legislators($filters: LegislatorsFilters, $orderBy: LegislatorsOrderBy, $pagination: PaginationArgs) { legislators(filters: $filters, orderBy: $orderBy, pagination: $pagination) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new LegislatorListResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryLegislatorsByTerm(
        variables: { state?: State; chamber: Chamber; level: GovernmentLevel; date: any },
        resultSelector:
          | string
          | ((
              qb: LegislatorModelSelector,
            ) => LegislatorModelSelector) = legislatorModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ legislatorsByTerm: LegislatorModelType[] }>(
          `query legislatorsByTerm($state: State, $chamber: Chamber!, $level: GovernmentLevel!, $date: LuxonDateTime!) { legislatorsByTerm(state: $state, chamber: $chamber, level: $level, date: $date) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new LegislatorModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryLegislatorsByName(
        variables: { limit?: number; name: string },
        resultSelector:
          | string
          | ((
              qb: LegislatorModelSelector,
            ) => LegislatorModelSelector) = legislatorModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ legislatorsByName: LegislatorModelType[] }>(
          `query legislatorsByName($limit: Int, $name: String!) { legislatorsByName(limit: $limit, name: $name) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new LegislatorModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryTerm(
        variables: { id: string },
        resultSelector:
          | string
          | ((qb: TermModelSelector) => TermModelSelector) = termModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ term: TermModelType }>(
          `query term($id: ID!) { term(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new TermModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryTerms(
        variables: { filters?: TermsFilters; orderBy?: TermsOrderBy; pagination?: PaginationArgs },
        resultSelector:
          | string
          | ((
              qb: TermListResponseModelSelector,
            ) => TermListResponseModelSelector) = termListResponseModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ terms: TermListResponseModelType }>(
          `query terms($filters: TermsFilters, $orderBy: TermsOrderBy, $pagination: PaginationArgs) { terms(filters: $filters, orderBy: $orderBy, pagination: $pagination) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new TermListResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryBook(
        variables: { id: string },
        resultSelector:
          | string
          | ((qb: BookModelSelector) => BookModelSelector) = bookModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ book: BookModelType }>(
          `query book($id: ID!) { book(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new BookModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryBooks(
        variables: { pagination?: PaginationArgs },
        resultSelector:
          | string
          | ((
              qb: BookListResponseModelSelector,
            ) => BookListResponseModelSelector) = bookListResponseModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ books: BookListResponseModelType }>(
          `query books($pagination: PaginationArgs) { books(pagination: $pagination) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new BookListResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryCategory(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: CategoryModelSelector,
            ) => CategoryModelSelector) = categoryModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ category: CategoryModelType }>(
          `query category($id: ID!) { category(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new CategoryModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryCategories(
        variables: {
          filters?: CategoriesFilters
          orderBy?: CategoriesOrderBy
          pagination?: PaginationArgs
        },
        resultSelector:
          | string
          | ((
              qb: CategoryListResponseModelSelector,
            ) => CategoryListResponseModelSelector) = categoryListResponseModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ categories: CategoryListResponseModelType }>(
          `query categories($filters: CategoriesFilters, $orderBy: CategoriesOrderBy, $pagination: PaginationArgs) { categories(filters: $filters, orderBy: $orderBy, pagination: $pagination) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new CategoryListResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryVotes(
        variables: { filters?: VotesFilters; orderBy?: VotesOrderBy; pagination?: PaginationArgs },
        resultSelector:
          | string
          | ((
              qb: VoteListResponseModelSelector,
            ) => VoteListResponseModelSelector) = voteListResponseModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ votes: VoteListResponseModelType }>(
          `query votes($filters: VotesFilters, $orderBy: VotesOrderBy, $pagination: PaginationArgs) { votes(filters: $filters, orderBy: $orderBy, pagination: $pagination) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new VoteListResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryIssue(
        variables: { id: string },
        resultSelector:
          | string
          | ((qb: IssueModelSelector) => IssueModelSelector) = issueModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ issue: IssueModelType }>(
          `query issue($id: ID!) { issue(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new IssueModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryIssues(
        variables: {
          filters?: IssuesFilters
          orderBy?: IssuesOrderBy
          pagination?: PaginationArgs
        },
        resultSelector:
          | string
          | ((
              qb: IssueListResponseModelSelector,
            ) => IssueListResponseModelSelector) = issueListResponseModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ issues: IssueListResponseModelType }>(
          `query issues($filters: IssuesFilters, $orderBy: IssuesOrderBy, $pagination: PaginationArgs) { issues(filters: $filters, orderBy: $orderBy, pagination: $pagination) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new IssueListResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryIssueTemplate(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: IssueTemplateModelSelector,
            ) => IssueTemplateModelSelector) = issueTemplateModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ issueTemplate: IssueTemplateModelType }>(
          `query issueTemplate($id: ID!) { issueTemplate(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new IssueTemplateModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryIssueTemplates(
        variables: {
          filters?: IssueTemplatesFilters
          orderBy?: IssueTemplatesOrderBy
          pagination?: PaginationArgs
        },
        resultSelector:
          | string
          | ((
              qb: IssueTemplateListResponseModelSelector,
            ) => IssueTemplateListResponseModelSelector) = issueTemplateListResponseModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ issueTemplates: IssueTemplateListResponseModelType }>(
          `query issueTemplates($filters: IssueTemplatesFilters, $orderBy: IssueTemplatesOrderBy, $pagination: PaginationArgs) { issueTemplates(filters: $filters, orderBy: $orderBy, pagination: $pagination) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new IssueTemplateListResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryIssueTemplateVotes(
        variables: { legislatorId: string },
        resultSelector:
          | string
          | ((
              qb: IssueTemplateVoteModelSelector,
            ) => IssueTemplateVoteModelSelector) = issueTemplateVoteModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ issueTemplateVotes: IssueTemplateVoteModelType[] }>(
          `query issueTemplateVotes($legislatorId: String!) { issueTemplateVotes(legislatorId: $legislatorId) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new IssueTemplateVoteModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryLayer(
        variables: { id: string },
        resultSelector:
          | string
          | ((qb: LayerModelSelector) => LayerModelSelector) = layerModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ layer: LayerModelType }>(
          `query layer($id: ID!) { layer(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new LayerModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryLayers(
        variables: { pagination?: PaginationArgs },
        resultSelector:
          | string
          | ((
              qb: LayerListResponseModelSelector,
            ) => LayerListResponseModelSelector) = layerListResponseModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ layers: LayerListResponseModelType }>(
          `query layers($pagination: PaginationArgs) { layers(pagination: $pagination) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new LayerListResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryAssignedLayer(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: AssignedLayerModelSelector,
            ) => AssignedLayerModelSelector) = assignedLayerModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ assignedLayer: AssignedLayerModelType }>(
          `query assignedLayer($id: ID!) { assignedLayer(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new AssignedLayerModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryAssignedLayers(
        variables: { pagination?: PaginationArgs },
        resultSelector:
          | string
          | ((
              qb: AssignedLayerListResponseModelSelector,
            ) => AssignedLayerListResponseModelSelector) = assignedLayerListResponseModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ assignedLayers: AssignedLayerListResponseModelType }>(
          `query assignedLayers($pagination: PaginationArgs) { assignedLayers(pagination: $pagination) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new AssignedLayerListResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      querySubCategory(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: SubCategoryModelSelector,
            ) => SubCategoryModelSelector) = subCategoryModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ subCategory: SubCategoryModelType }>(
          `query subCategory($id: ID!) { subCategory(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new SubCategoryModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      querySubCategories(
        variables: { pagination?: PaginationArgs },
        resultSelector:
          | string
          | ((
              qb: SubCategoryListResponseModelSelector,
            ) => SubCategoryListResponseModelSelector) = subCategoryListResponseModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ subCategories: SubCategoryListResponseModelType }>(
          `query subCategories($pagination: PaginationArgs) { subCategories(pagination: $pagination) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new SubCategoryListResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryBlurb(
        variables: { id: string },
        resultSelector:
          | string
          | ((qb: BlurbModelSelector) => BlurbModelSelector) = blurbModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ blurb: BlurbModelType }>(
          `query blurb($id: ID!) { blurb(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new BlurbModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryBlurbs(
        variables: { pagination?: PaginationArgs },
        resultSelector:
          | string
          | ((
              qb: BlurbListResponseModelSelector,
            ) => BlurbListResponseModelSelector) = blurbListResponseModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ blurbs: BlurbListResponseModelType }>(
          `query blurbs($pagination: PaginationArgs) { blurbs(pagination: $pagination) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new BlurbListResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryImportGroup(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: ImportGroupModelSelector,
            ) => ImportGroupModelSelector) = importGroupModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ importGroup: ImportGroupModelType }>(
          `query importGroup($id: ID!) { importGroup(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new ImportGroupModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryImportGroups(
        variables: { pagination?: PaginationArgs },
        resultSelector:
          | string
          | ((
              qb: ImportGroupListResponseModelSelector,
            ) => ImportGroupListResponseModelSelector) = importGroupListResponseModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ importGroups: ImportGroupListResponseModelType }>(
          `query importGroups($pagination: PaginationArgs) { importGroups(pagination: $pagination) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new ImportGroupListResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      mutateLogin(
        variables: { input: UserLoginArgs },
        resultSelector:
          | string
          | ((
              qb: UserLoginResponseModelSelector,
            ) => UserLoginResponseModelSelector) = userLoginResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ login: UserLoginResponseModelType }>(
          `mutation login($input: UserLoginArgs!) { login(input: $input) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new UserLoginResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateLogout(
        variables?: {},
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ logout: MutationResponseModelType }>(
          `mutation logout { logout {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateRefreshToken(
        variables?: {},
        resultSelector:
          | string
          | ((
              qb: RefreshTokenResponseModelSelector,
            ) => RefreshTokenResponseModelSelector) = refreshTokenResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ refreshToken: RefreshTokenResponseModelType }>(
          `mutation refreshToken { refreshToken {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new RefreshTokenResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateDeleteUser(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ deleteUser: MutationResponseModelType }>(
          `mutation deleteUser($id: ID!) { deleteUser(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateCreateUser(
        variables: { input: CreateUserInput },
        resultSelector:
          | string
          | ((
              qb: UserCreationResponseModelSelector,
            ) => UserCreationResponseModelSelector) = userCreationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createUser: UserCreationResponseModelType }>(
          `mutation createUser($input: CreateUserInput!) { createUser(input: $input) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new UserCreationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateUpdateUser(
        variables: { input: UpdateUserInput; id: string },
        resultSelector:
          | string
          | ((
              qb: UserUpdateResponseModelSelector,
            ) => UserUpdateResponseModelSelector) = userUpdateResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ updateUser: UserUpdateResponseModelType }>(
          `mutation updateUser($input: UpdateUserInput!, $id: ID!) { updateUser(input: $input, id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new UserUpdateResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateCreateLegislator(
        variables: { input: CreateLegislatorInput },
        resultSelector:
          | string
          | ((
              qb: LegislatorCreationResponseModelSelector,
            ) => LegislatorCreationResponseModelSelector) = legislatorCreationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createLegislator: LegislatorCreationResponseModelType }>(
          `mutation createLegislator($input: CreateLegislatorInput!) { createLegislator(input: $input) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new LegislatorCreationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateUpdateLegislator(
        variables: { input: UpdateLegislatorInput; id: string },
        resultSelector:
          | string
          | ((
              qb: LegislatorUpdateResponseModelSelector,
            ) => LegislatorUpdateResponseModelSelector) = legislatorUpdateResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ updateLegislator: LegislatorUpdateResponseModelType }>(
          `mutation updateLegislator($input: UpdateLegislatorInput!, $id: ID!) { updateLegislator(input: $input, id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new LegislatorUpdateResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateDeleteLegislator(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ deleteLegislator: MutationResponseModelType }>(
          `mutation deleteLegislator($id: ID!) { deleteLegislator(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateCreateTerm(
        variables: { input: CreateTermInput },
        resultSelector:
          | string
          | ((
              qb: TermCreationResponseModelSelector,
            ) => TermCreationResponseModelSelector) = termCreationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createTerm: TermCreationResponseModelType }>(
          `mutation createTerm($input: CreateTermInput!) { createTerm(input: $input) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new TermCreationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateUpdateTerm(
        variables: { input: UpdateTermInput; id: string },
        resultSelector:
          | string
          | ((
              qb: TermUpdateResponseModelSelector,
            ) => TermUpdateResponseModelSelector) = termUpdateResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ updateTerm: TermUpdateResponseModelType }>(
          `mutation updateTerm($input: UpdateTermInput!, $id: ID!) { updateTerm(input: $input, id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new TermUpdateResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateDeleteTerm(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ deleteTerm: MutationResponseModelType }>(
          `mutation deleteTerm($id: ID!) { deleteTerm(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateDeleteBook(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ deleteBook: MutationResponseModelType }>(
          `mutation deleteBook($id: ID!) { deleteBook(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateGenerateVoteBook(
        variables: { filters: IssueTemplatesForLegislatorFilters; legislatorId: string },
        resultSelector:
          | string
          | ((
              qb: GenerateVoteBookMutationResponseModelSelector,
            ) => GenerateVoteBookMutationResponseModelSelector) = generateVoteBookMutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ generateVoteBook: GenerateVoteBookMutationResponseModelType }>(
          `mutation generateVoteBook($filters: IssueTemplatesForLegislatorFilters!, $legislatorId: String!) { generateVoteBook(filters: $filters, legislatorId: $legislatorId) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new GenerateVoteBookMutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateCreateCategory(
        variables: { input: CreateCategoryInput },
        resultSelector:
          | string
          | ((
              qb: CategoryCreationResponseModelSelector,
            ) => CategoryCreationResponseModelSelector) = categoryCreationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createCategory: CategoryCreationResponseModelType }>(
          `mutation createCategory($input: CreateCategoryInput!) { createCategory(input: $input) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new CategoryCreationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateUpdateCategory(
        variables: { input: UpdateCategoryInput; id: string },
        resultSelector:
          | string
          | ((
              qb: CategoryUpdateResponseModelSelector,
            ) => CategoryUpdateResponseModelSelector) = categoryUpdateResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ updateCategory: CategoryUpdateResponseModelType }>(
          `mutation updateCategory($input: UpdateCategoryInput!, $id: ID!) { updateCategory(input: $input, id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new CategoryUpdateResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateDeleteCategory(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ deleteCategory: MutationResponseModelType }>(
          `mutation deleteCategory($id: ID!) { deleteCategory(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateBulkEditVotes(
        variables: { votes: BulkEditVoteRecord[]; issueId: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ bulkEditVotes: MutationResponseModelType }>(
          `mutation bulkEditVotes($votes: [BulkEditVoteRecord!]!, $issueId: String!) { bulkEditVotes(votes: $votes, issueId: $issueId) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateCreateIssue(
        variables: { input: CreateIssueInput },
        resultSelector:
          | string
          | ((
              qb: IssueCreationResponseModelSelector,
            ) => IssueCreationResponseModelSelector) = issueCreationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createIssue: IssueCreationResponseModelType }>(
          `mutation createIssue($input: CreateIssueInput!) { createIssue(input: $input) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new IssueCreationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateUpdateIssue(
        variables: { input: UpdateIssueInput; id: string },
        resultSelector:
          | string
          | ((
              qb: IssueUpdateResponseModelSelector,
            ) => IssueUpdateResponseModelSelector) = issueUpdateResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ updateIssue: IssueUpdateResponseModelType }>(
          `mutation updateIssue($input: UpdateIssueInput!, $id: ID!) { updateIssue(input: $input, id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new IssueUpdateResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateDeleteIssue(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ deleteIssue: MutationResponseModelType }>(
          `mutation deleteIssue($id: ID!) { deleteIssue(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateCreateIssueTemplate(
        variables: { input: CreateIssueTemplateInput },
        resultSelector:
          | string
          | ((
              qb: IssueTemplateCreationResponseModelSelector,
            ) => IssueTemplateCreationResponseModelSelector) = issueTemplateCreationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createIssueTemplate: IssueTemplateCreationResponseModelType }>(
          `mutation createIssueTemplate($input: CreateIssueTemplateInput!) { createIssueTemplate(input: $input) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new IssueTemplateCreationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateUpdateIssueTemplate(
        variables: { input: UpdateIssueTemplateInput; id: string },
        resultSelector:
          | string
          | ((
              qb: IssueTemplateUpdateResponseModelSelector,
            ) => IssueTemplateUpdateResponseModelSelector) = issueTemplateUpdateResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ updateIssueTemplate: IssueTemplateUpdateResponseModelType }>(
          `mutation updateIssueTemplate($input: UpdateIssueTemplateInput!, $id: ID!) { updateIssueTemplate(input: $input, id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new IssueTemplateUpdateResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateDeleteIssueTemplate(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ deleteIssueTemplate: MutationResponseModelType }>(
          `mutation deleteIssueTemplate($id: ID!) { deleteIssueTemplate(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateCreateLayer(
        variables: { input: CreateLayerInput },
        resultSelector:
          | string
          | ((
              qb: LayerCreationResponseModelSelector,
            ) => LayerCreationResponseModelSelector) = layerCreationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createLayer: LayerCreationResponseModelType }>(
          `mutation createLayer($input: CreateLayerInput!) { createLayer(input: $input) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new LayerCreationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateUpdateLayer(
        variables: { input: UpdateLayerInput; id: string },
        resultSelector:
          | string
          | ((
              qb: LayerUpdateResponseModelSelector,
            ) => LayerUpdateResponseModelSelector) = layerUpdateResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ updateLayer: LayerUpdateResponseModelType }>(
          `mutation updateLayer($input: UpdateLayerInput!, $id: ID!) { updateLayer(input: $input, id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new LayerUpdateResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateDeleteLayer(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ deleteLayer: MutationResponseModelType }>(
          `mutation deleteLayer($id: ID!) { deleteLayer(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateCreateAssignedLayer(
        variables: { input: CreateAssignedLayerInput },
        resultSelector:
          | string
          | ((
              qb: AssignedLayerCreationResponseModelSelector,
            ) => AssignedLayerCreationResponseModelSelector) = assignedLayerCreationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createAssignedLayer: AssignedLayerCreationResponseModelType }>(
          `mutation createAssignedLayer($input: CreateAssignedLayerInput!) { createAssignedLayer(input: $input) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new AssignedLayerCreationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateUpdateAssignedLayer(
        variables: { input: UpdateAssignedLayerInput; id: string },
        resultSelector:
          | string
          | ((
              qb: AssignedLayerUpdateResponseModelSelector,
            ) => AssignedLayerUpdateResponseModelSelector) = assignedLayerUpdateResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ updateAssignedLayer: AssignedLayerUpdateResponseModelType }>(
          `mutation updateAssignedLayer($input: UpdateAssignedLayerInput!, $id: ID!) { updateAssignedLayer(input: $input, id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new AssignedLayerUpdateResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateDeleteAssignedLayer(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ deleteAssignedLayer: MutationResponseModelType }>(
          `mutation deleteAssignedLayer($id: ID!) { deleteAssignedLayer(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateCreateSubCategory(
        variables: { input: CreateSubCategoryInput },
        resultSelector:
          | string
          | ((
              qb: SubCategoryCreationResponseModelSelector,
            ) => SubCategoryCreationResponseModelSelector) = subCategoryCreationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createSubCategory: SubCategoryCreationResponseModelType }>(
          `mutation createSubCategory($input: CreateSubCategoryInput!) { createSubCategory(input: $input) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new SubCategoryCreationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateUpdateSubCategory(
        variables: { input: UpdateSubCategoryInput; id: string },
        resultSelector:
          | string
          | ((
              qb: SubCategoryUpdateResponseModelSelector,
            ) => SubCategoryUpdateResponseModelSelector) = subCategoryUpdateResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ updateSubCategory: SubCategoryUpdateResponseModelType }>(
          `mutation updateSubCategory($input: UpdateSubCategoryInput!, $id: ID!) { updateSubCategory(input: $input, id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new SubCategoryUpdateResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateDeleteSubCategory(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ deleteSubCategory: MutationResponseModelType }>(
          `mutation deleteSubCategory($id: ID!) { deleteSubCategory(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateCreateBlurb(
        variables: { input: CreateBlurbInput },
        resultSelector:
          | string
          | ((
              qb: BlurbCreationResponseModelSelector,
            ) => BlurbCreationResponseModelSelector) = blurbCreationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createBlurb: BlurbCreationResponseModelType }>(
          `mutation createBlurb($input: CreateBlurbInput!) { createBlurb(input: $input) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new BlurbCreationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateUpdateBlurb(
        variables: { input: UpdateBlurbInput; id: string },
        resultSelector:
          | string
          | ((
              qb: BlurbUpdateResponseModelSelector,
            ) => BlurbUpdateResponseModelSelector) = blurbUpdateResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ updateBlurb: BlurbUpdateResponseModelType }>(
          `mutation updateBlurb($input: UpdateBlurbInput!, $id: ID!) { updateBlurb(input: $input, id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new BlurbUpdateResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateDeleteBlurb(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ deleteBlurb: MutationResponseModelType }>(
          `mutation deleteBlurb($id: ID!) { deleteBlurb(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateImportIssue(
        variables: { url: string },
        resultSelector:
          | string
          | ((
              qb: ImportIssueMutationResponseModelSelector,
            ) => ImportIssueMutationResponseModelSelector) = importIssueMutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ importIssue: ImportIssueMutationResponseModelType }>(
          `mutation importIssue($url: String!) { importIssue(url: $url) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new ImportIssueMutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateCreateImportGroup(
        variables: { input: CreateImportGroupInput },
        resultSelector:
          | string
          | ((
              qb: ImportGroupCreationResponseModelSelector,
            ) => ImportGroupCreationResponseModelSelector) = importGroupCreationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createImportGroup: ImportGroupCreationResponseModelType }>(
          `mutation createImportGroup($input: CreateImportGroupInput!) { createImportGroup(input: $input) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new ImportGroupCreationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateUpdateImportGroup(
        variables: { input: UpdateImportGroupInput; id: string },
        resultSelector:
          | string
          | ((
              qb: ImportGroupUpdateResponseModelSelector,
            ) => ImportGroupUpdateResponseModelSelector) = importGroupUpdateResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ updateImportGroup: ImportGroupUpdateResponseModelType }>(
          `mutation updateImportGroup($input: UpdateImportGroupInput!, $id: ID!) { updateImportGroup(input: $input, id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new ImportGroupUpdateResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateDeleteImportGroup(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ deleteImportGroup: MutationResponseModelType }>(
          `mutation deleteImportGroup($id: ID!) { deleteImportGroup(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateSetImportGroupLegislators(
        variables: { legislatorIds: string[]; importGroupId: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ setImportGroupLegislators: MutationResponseModelType }>(
          `mutation setImportGroupLegislators($legislatorIds: [String!]!, $importGroupId: String!) { setImportGroupLegislators(legislatorIds: $legislatorIds, importGroupId: $importGroupId) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
    })),
)
