/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { UserRoleEnumType } from './UserRoleEnum'
import { RootStoreType } from './index'

/**
 * UserBase
 * auto generated base class for the model UserModel.
 */
export const UserModelBase = ModelBase.named('User')
  .props({
    __typename: types.optional(types.literal('User'), 'User'),
    id: types.identifier,
    createdAt: types.union(types.undefined, types.frozen()),
    updatedAt: types.union(types.undefined, types.frozen()),
    email: types.union(types.undefined, types.string),
    name: types.union(types.undefined, types.string),
    role: types.union(types.undefined, UserRoleEnumType),
    profileImageUrl: types.union(types.undefined, types.string),
  })
  .views((self) => ({
    get store() {
      return self.__getStore<RootStoreType>()
    },
  }))

export class UserModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  get email() {
    return this.__attr(`email`)
  }
  get name() {
    return this.__attr(`name`)
  }
  get role() {
    return this.__attr(`role`)
  }
  get profileImageUrl() {
    return this.__attr(`profileImageUrl`)
  }
}
export function selectFromUser() {
  return new UserModelSelector()
}

export const userModelPrimitives = selectFromUser().createdAt.updatedAt.email.name.role
  .profileImageUrl
