import React, { useCallback, useState } from 'react'
import { Errors, FormButtonGroup, FormContainer } from '../common'
import { Button, Theme } from '@material-ui/core'
import { useDropzone } from 'react-dropzone'
import { makeStyles } from '@material-ui/core/styles'
import {
  isErrorArray,
  isParsedResult,
  loadWorkbook,
  parseWorkbook,
  WorkbookRecordParser,
} from '../../utilities/spreadsheets'

const useStyles = makeStyles((theme: Theme) => ({
  dropzoneContainer: {
    borderWidth: '3px',
    borderStyle: 'dashed',
    borderColor: theme.palette.divider,
    display: 'flex',
    justifyContent: 'center',
    padding: '32px 0px',
    cursor: 'pointer',
  },
}))

export type LoadImportGroupTemplateFormProps<T extends object> = {
  onCancel: () => void
  onImport: (records: Array<T>) => Promise<void> | void
  recordParser: WorkbookRecordParser<T>
  loading?: boolean
}

const LoadImportGroupTemplateForm = <T extends object>({
  onCancel,
  onImport,
  recordParser,
  loading,
}: LoadImportGroupTemplateFormProps<T>) => {
  const classes = useStyles()
  const [errorMessages, setErrorMessages] = useState<Array<string>>([])
  const [records, setRecords] = useState<Array<T>>([])
  const onDrop = useCallback(
    async (acceptedFiles: Array<File>) => {
      setErrorMessages([])
      if (acceptedFiles.length !== 1) {
        console.error(`Expected 1 uploaded file, received ${acceptedFiles.length}`)
        return
      }
      const workbook = await loadWorkbook(acceptedFiles[0])
      if (!workbook) {
        setErrorMessages(['Could not load file as spreadsheet'])
        return
      }
      const recordResults = parseWorkbook(workbook, recordParser)
      const errors = recordResults.filter(isErrorArray).flat()
      if (errors.length) {
        setErrorMessages(errors)
        return
      }
      setRecords(recordResults.filter(isParsedResult))
    },
    [recordParser],
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const handleImport = useCallback(async () => {
    onImport(records)
  }, [onImport, records])

  return (
    <FormContainer title="Load import group file">
      <Errors errorMessages={errorMessages} />
      <div {...getRootProps()} className={classes.dropzoneContainer}>
        <input {...getInputProps()} />
        {isDragActive ? <p>Drop the files here ...</p> : <p>Drag and drop a file or click here</p>}
      </div>

      <FormButtonGroup>
        <Button variant="contained" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!records.length || loading}
          onClick={handleImport}
        >
          Import
        </Button>
      </FormButtonGroup>
    </FormContainer>
  )
}

export default LoadImportGroupTemplateForm
