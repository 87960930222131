import { UserModelType, UserRole } from '../models'

export const allRoles: Array<UserRole> = Object.values(UserRole)

const roleValues: Record<UserRole, number> = {
  USER: 0,
  ADMIN: 1,
  SUPERADMIN: 2,
}

export const hasRequiredRole = (user: UserModelType | undefined, requires: UserRole): boolean => {
  if (!user || !user?.role) return false
  return roleValues[user.role] >= roleValues[requires]
}

export const canModifyRole = (currentUser: UserModelType, role: UserRole): boolean => {
  if (!currentUser || !currentUser?.role) return false
  return roleValues[currentUser.role] >= roleValues[role]
}

export const canModifyUser = (currentUser: UserModelType, modifying: UserModelType): boolean => {
  return canModifyRole(currentUser, modifying.role as UserRole)
}

export const assignableRoles = (user: UserModelType | undefined | null): Array<UserRole> => {
  if (!user) return []
  return allRoles.filter((role) => canModifyRole(user, role))
}
