import { Button, CircularProgress, InputAdornment } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import * as React from 'react'
import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useToast } from '../common/utils/toast'
import {
  FieldGroup,
  FormattedTextField,
  FormButtonGroup,
  FormContainer,
  FormSection,
  FormSelectField,
  FormTextField,
} from '../common/forms'
import { displayMutationError, hasMutationErrors } from '../../utilities/errors'
import { observer } from 'mobx-react-lite'
import { formatDateTime } from '../../utilities/dates'
import { DateTime } from 'luxon'
import { useQuery } from '../../models/reactUtils'
import {
  CategoryListResponseModelSelector,
  CategoryModelType,
  ImportIssueMutationResponseModelSelector,
  ImportIssueMutationResponseModelType,
  issueModelPrimitives,
  IssueModelType,
  IssueTemplateCreationResponseModelSelector,
  IssueTemplateCreationResponseModelType,
  issueTemplateModelPrimitives,
  subCategoryModelPrimitives,
} from '../../models'
import { constructCategoryOptions, constructSubCategoryOptions } from '../../utilities/categories'
import { useStore } from '../../getMstGql'
import { CreateIssueTemplateInput } from '../../models/RootStore.base'
import { LoadingContainer } from '../common/utils'
import { Maybe } from '../../utilities/types'

const initialFormValues = {
  categoryId: undefined as Maybe<string>,
  subCategoryId: '',
  title: '',
  summary: '',
}

const CreateIssueSchema = Yup.object().shape({
  categoryId: Yup.string().required('Category is required'),
  subCategoryId: Yup.string(),
  title: Yup.string().required('Title is required'),
  summary: Yup.string().required('Summary is required'),
})

export interface AddIssueTemplateFormProps {
  onComplete: (willNavigate?: boolean) => any
  issue?: IssueModelType
}

const AddIssueTemplateForm: React.FC<AddIssueTemplateFormProps> = ({
  onComplete,
  issue: managedIssue,
}) => {
  const [issueUrl, setIssueUrl] = useState(managedIssue?.url || '')
  const [issueLoading, setIssueLoading] = useState(false)
  const [issue, setIssue] = useState<IssueModelType | undefined>(managedIssue)
  const [navigateAfterCreation, setNavigateAfterCreate] = useState(false)
  const [addBlurb, setAddBlurb] = useState(false)

  const history = useHistory()
  const store = useStore()

  const { data: categoryData, loading: categoriesLoading } = useQuery((store) =>
    store.queryCategories(
      {},
      new CategoryListResponseModelSelector()
        .records((sel) => sel.id.title.subCategories(subCategoryModelPrimitives))
        .toString(),
    ),
  )

  const { setToast } = useToast()
  const categories: Array<CategoryModelType> = useMemo(() => {
    return categoryData?.categories?.records?.toJS() || []
  }, [categoryData])

  return (
    <LoadingContainer loading={categoriesLoading && !categories.length}>
      <FormContainer title="Add Issue Template">
        <FormSection title="Issue details">
          <FieldGroup>
            <FormattedTextField
              label="Issue URL"
              fullWidth
              disabled={issueLoading || !!managedIssue}
              value={issueUrl}
              onChange={(event) => setIssueUrl(event.target.value)}
              onBlur={async (event) => {
                try {
                  setIssueLoading(true)
                  const url = event.target.value as string

                  const query = store.mutateImportIssue(
                    { url },
                    new ImportIssueMutationResponseModelSelector().success.message
                      .issue(issueModelPrimitives)
                      .toString(),
                  )
                  const results = (await query.currentPromise()) as {
                    importIssue: ImportIssueMutationResponseModelType
                  }

                  if (hasMutationErrors(results)) {
                    setToast(displayMutationError(results))
                    return
                  }
                  setIssue(results?.importIssue?.issue)
                } finally {
                  setIssueLoading(false)
                }
              }}
              InputProps={{
                endAdornment: issueLoading && (
                  <InputAdornment position="end">
                    <CircularProgress color="inherit" size={20} />
                  </InputAdornment>
                ),
              }}
            />
          </FieldGroup>
          <FieldGroup>
            <FormattedTextField disabled label="Name" value={issue?.legislationNumber || ''} />
            <FormattedTextField disabled label="Roll #" value={issue?.rollCallNumber || ''} />
          </FieldGroup>
          <FieldGroup>
            <FormattedTextField
              disabled
              label="Date"
              value={(issue?.date && formatDateTime(DateTime.fromISO(issue.date))) || ''}
            />
          </FieldGroup>
          <FieldGroup>
            <FormattedTextField
              disabled
              label="Description"
              value={issue?.description || ''}
              multiline
            />
          </FieldGroup>
        </FormSection>

        <Formik
          initialValues={initialFormValues}
          validationSchema={CreateIssueSchema}
          validateOnBlur={false}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true)
            const { categoryId, subCategoryId, title, summary } = values

            const input: CreateIssueTemplateInput = {
              issueId: issue?.id || '',
              title: title,
              summary: summary,
              categoryId: categoryId || '',
            }
            if (subCategoryId) {
              input.subCategoryId = subCategoryId
            }
            const query = store.mutateCreateIssueTemplate(
              { input },
              new IssueTemplateCreationResponseModelSelector().success.message
                .issueTemplate(issueTemplateModelPrimitives)
                .toString(),
            )
            const response = (await query.currentPromise()) as {
              createIssueTemplate: IssueTemplateCreationResponseModelType
            }

            if (hasMutationErrors(response)) {
              setToast(displayMutationError(response))
              return
            }

            const issueName = issue?.legislationNumber || ''
            setToast({
              message: `Issue template for ${issueName} has been created!`,
              variant: 'success',
            })
            actions.resetForm()
            onComplete(navigateAfterCreation || addBlurb)
            if (navigateAfterCreation) {
              const templateId = response?.createIssueTemplate?.issueTemplate?.id
              history.push(`/templates/${templateId}`)
            }
            if (addBlurb) {
              const templateId = response?.createIssueTemplate?.issueTemplate?.id
              history.push(`/templates/${templateId}?addBlurb=true`)
            }
          }}
        >
          {(props: FormikProps<any>) => (
            <React.Fragment>
              <FormSection title="Categorization">
                <FieldGroup>
                  <FormSelectField
                    name="categoryId"
                    label="Category"
                    options={constructCategoryOptions(categories)}
                    clearable={false}
                    onChange={() => {
                      props.setFieldValue('subCategoryId', '')
                    }}
                  />
                  <FormSelectField
                    name="subCategoryId"
                    label="Subcategory"
                    options={constructSubCategoryOptions(props.values['categoryId'], categories)}
                    disabled={!props.values['categoryId']}
                  />
                </FieldGroup>
              </FormSection>

              <FormSection title="Templates">
                <FieldGroup>
                  <FormTextField label="Title" name="title" InputProps={{ rows: 4 }} multiline />
                </FieldGroup>
                <FieldGroup>
                  <FormTextField
                    label="Summary"
                    name="summary"
                    InputProps={{ rows: 8 }}
                    multiline
                  />
                </FieldGroup>
              </FormSection>

              <FormButtonGroup>
                <Button
                  variant="contained"
                  onClick={() => {
                    props.resetForm()
                    onComplete()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={!issue || issueLoading || props.isSubmitting}
                  onClick={() => {
                    setNavigateAfterCreate(false)
                    return props.submitForm()
                  }}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!issue || issueLoading || props.isSubmitting}
                  onClick={() => {
                    setNavigateAfterCreate(true)
                    return props.submitForm()
                  }}
                >
                  Save and View
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!issue || issueLoading || props.isSubmitting}
                  onClick={() => {
                    setAddBlurb(true)
                    return props.submitForm()
                  }}
                >
                  Save and Add Blurb
                </Button>
              </FormButtonGroup>
            </React.Fragment>
          )}
        </Formik>
      </FormContainer>
    </LoadingContainer>
  )
}

export default observer(AddIssueTemplateForm)
