import { Accordion, AccordionSummary, Button, Typography } from '@material-ui/core'
import MaterialTable from 'material-table'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  ActionButtonContainer,
  ActionsMenu,
  AddBlurbForm,
  AssignLayerForm,
  EditIssueTemplateForm,
  FieldGroup,
  PageContents,
  PageHeader,
  ReadOnlyForm,
  ReadOnlyTextField,
  TableActions,
} from '../../components'
import { LoadingContainer, NotFound } from '../../components/common/utils'
import { useModal } from '../../components/common/utils/modal'
import { useToast } from '../../components/common/utils/toast'
import { Paths } from '../../constants/routes'
import { useStore } from '../../getMstGql'
import { IssueTemplateModelType, MutationResponseModelType } from '../../models'
import { useQuery } from '../../models/reactUtils'
import { issueTemplatesSelector } from '../../models/selectors'
import { rowDatum } from '../../utilities/coercion'
import { formatDate } from '../../utilities/dates'
import { displayMutationError, hasMutationErrors } from '../../utilities/errors'
import { IssueUrl } from '../../utilities/IssueUrl'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export interface IssueTemplateDetailsPageParams {
  id: string
}

const IssueTemplateDetailsPage = () => {
  const { id } = useParams<IssueTemplateDetailsPageParams>()
  const { loading, data, query } = useQuery<{ issueTemplate: IssueTemplateModelType }>((store) =>
    store.queryIssueTemplate({ id }, issueTemplatesSelector, { fetchPolicy: 'no-cache' }),
  )
  const { mutateDeleteIssueTemplate, mutateDeleteAssignedLayer, mutateDeleteBlurb } = useStore()
  const history = useHistory()
  const { setToast } = useToast()
  const { openDialog, openDrawer, closeDrawer } = useModal()

  const issueTemplate = useMemo(() => {
    return data?.issueTemplate
  }, [data])

  const handleDeleteIssueTemplate = useCallback(async () => {
    const response = (await mutateDeleteIssueTemplate({ id }).currentPromise()) as {
      deleteIssueTemplate: MutationResponseModelType
    }

    if (hasMutationErrors(response)) {
      setToast(displayMutationError(response))
    } else {
      setToast('Deleted issue template.')
      history.push(Paths.TemplatesList)
    }
  }, [id, mutateDeleteIssueTemplate, setToast, history])

  const handleDeleteAssignedLayer = useCallback(
    async (assignedLayerId: string) => {
      const deleteMutation = mutateDeleteAssignedLayer({ id: assignedLayerId })
      const response = (await deleteMutation.currentPromise()) as {
        deleteAssignedLayer: MutationResponseModelType
      }

      if (hasMutationErrors(response)) {
        setToast(displayMutationError(response))
      } else {
        query?.refetch()
        setToast('Deleted assigned layer.')
      }
    },
    [mutateDeleteAssignedLayer, setToast, query],
  )

  const handleDeleteBlurb = useCallback(
    async (blurbId: string) => {
      const deleteMutation = mutateDeleteBlurb({ id: blurbId })
      const response = (await deleteMutation.currentPromise()) as {
        deleteBlurb: MutationResponseModelType
      }

      if (hasMutationErrors(response)) {
        setToast(displayMutationError(response))
      } else {
        query?.refetch()
        setToast('Deleted blurb.')
      }
    },
    [mutateDeleteBlurb, setToast, query],
  )

  const handleUpdateTemplate = useCallback(() => {
    closeDrawer()
    query?.refetch()
  }, [query, closeDrawer])

  const handleLayerAssigned = useCallback(() => {
    closeDrawer()
    query?.refetch()
  }, [closeDrawer, query])

  useEffect(() => {
    if (!loading) {
      const params = new URLSearchParams(window.location.search)
      const addBlurb = params.get('addBlurb') === 'true'
      if (addBlurb && issueTemplate) {
        openDrawer(<AddBlurbForm issueTemplate={issueTemplate} onComplete={handleLayerAssigned} />)
        history.replace(window.location.pathname, {})
      }
    }
  }, [handleLayerAssigned, handleUpdateTemplate, history, issueTemplate, loading, openDrawer])

  if (loading && !issueTemplate) {
    return <LoadingContainer loading={loading} />
  }

  if (!issueTemplate) {
    return <NotFound />
  }
  const title = issueTemplate?.issue?.legislationNumber || ''
  const url = issueTemplate?.issue.url || ''
  const issueUrl = IssueUrl.from(url)
  return (
    <LoadingContainer loading={loading && !issueTemplate}>
      <PageHeader
        title={title}
        breadcrumbs={[{ link: Paths.TemplatesList, label: 'Issue templates' }, { label: title }]}
      >
        <ActionButtonContainer>
          <ActionsMenu
            actions={[
              {
                label: 'View votes',
                onClick: (): void => {
                  history.push(`/votes/${issueTemplate?.issue?.id}`)
                },
              },
              {
                label: 'Edit',
                onClick: (): void => {
                  openDrawer(
                    <EditIssueTemplateForm
                      issueTemplate={issueTemplate}
                      onComplete={handleUpdateTemplate}
                    />,
                  )
                },
              },
              {
                label: 'Delete',
                onClick: (): void => {
                  openDialog({
                    title: 'Delete this record?',
                    body: 'This is permanent. Deleted records cannot be restored.',
                    onConfirm: handleDeleteIssueTemplate,
                  })
                },
              },
            ]}
          />
        </ActionButtonContainer>
      </PageHeader>
      <PageContents>
        <ReadOnlyForm title="Issue details">
          <FieldGroup>
            <ReadOnlyTextField label="URL" value={issueUrl?.oldFormat} />
          </FieldGroup>
          <FieldGroup>
            <ReadOnlyTextField label="New URL" value={issueUrl?.newFormat} />
          </FieldGroup>
          <FieldGroup>
            <ReadOnlyTextField label="Bill title" value={issueTemplate?.issue.legislationNumber} />
            <ReadOnlyTextField label="Date" value={formatDate(issueTemplate?.issue.date)} />
            <ReadOnlyTextField label="Roll #" value={issueTemplate?.issue.rollCallNumber} />
          </FieldGroup>
          <FieldGroup>
            <ReadOnlyTextField
              label="Description"
              multiline
              value={issueTemplate?.issue.description}
            />
          </FieldGroup>
        </ReadOnlyForm>

        <ReadOnlyForm title="Categorization">
          <FieldGroup>
            <ReadOnlyTextField label="Category" value={issueTemplate?.category.title} />
            <ReadOnlyTextField label="Subcategory" value={issueTemplate?.subCategory?.title} />
          </FieldGroup>
        </ReadOnlyForm>

        <ReadOnlyForm title="Templates">
          <FieldGroup>
            <ReadOnlyTextField label="Title" multiline value={issueTemplate?.title} />
          </FieldGroup>
          <FieldGroup>
            <ReadOnlyTextField label="Summary" multiline value={issueTemplate?.summary} />
          </FieldGroup>
        </ReadOnlyForm>

        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Assigned layers</Typography>
          </AccordionSummary>
          <TableActions>
            <Button
              variant="contained"
              onClick={(): void => {
                openDrawer(
                  <AssignLayerForm
                    issueTemplate={issueTemplate}
                    onComplete={handleLayerAssigned}
                  />,
                )
              }}
            >
              Add
            </Button>
          </TableActions>
          <MaterialTable
            data={issueTemplate?.assignedLayers || []}
            columns={[
              {
                title: 'Layer',
                field: 'layer.name',
                render: (d) => d.layer.name,
                defaultSort: 'asc',
              },
              {
                title: 'Text',
                field: 'text',
              },
              {
                title: 'URL',
                field: 'url',
              },
            ]}
            options={{ search: false, filtering: true, toolbar: false }}
            style={{ boxShadow: 'none' }}
            localization={{ header: { actions: '' } }}
            actions={[
              {
                icon: 'delete',
                tooltip: 'Delete assigned layer',
                onClick: (event, data) => {
                  openDialog({
                    title: 'Delete this record?',
                    body: 'This is permanent. Deleted records cannot be restored.',
                    onConfirm: () => handleDeleteAssignedLayer(rowDatum(data).id),
                  })
                },
              },
            ]}
          />
        </Accordion>

        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Blurbs</Typography>
          </AccordionSummary>
          <TableActions>
            <Button
              variant="contained"
              onClick={(): void => {
                openDrawer(
                  <AddBlurbForm issueTemplate={issueTemplate} onComplete={handleLayerAssigned} />,
                )
              }}
            >
              Add
            </Button>
          </TableActions>
          <MaterialTable
            data={issueTemplate?.blurbs.toJS() || []}
            columns={[
              {
                title: 'Name',
                field: 'name',
                defaultSort: 'asc',
              },
              {
                title: 'Text',
                field: 'text',
              },
              {
                title: 'URL',
                field: 'url',
              },
            ]}
            options={{ search: false, filtering: true, toolbar: false }}
            style={{ boxShadow: 'none' }}
            localization={{ header: { actions: '' } }}
            actions={[
              {
                icon: 'delete',
                tooltip: 'Delete blurb',
                onClick: (event, data) => {
                  openDialog({
                    title: 'Delete this record?',
                    body: 'This is permanent. Deleted records cannot be restored.',
                    onConfirm: () => handleDeleteBlurb(rowDatum(data).id),
                  })
                },
              },
            ]}
          />
        </Accordion>
      </PageContents>
    </LoadingContainer>
  )
}

export default observer(IssueTemplateDetailsPage)
