/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from 'mobx'
import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { IssueModel, IssueModelType } from './IssueModel'
import { IssueModelSelector } from './IssueModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  records: IObservableArray<IssueModelType>
}

/**
 * IssueListResponseBase
 * auto generated base class for the model IssueListResponseModel.
 */
export const IssueListResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('IssueListResponse')
    .props({
      __typename: types.optional(types.literal('IssueListResponse'), 'IssueListResponse'),
      records: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => IssueModel))),
      ),
      count: types.union(types.undefined, types.number),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class IssueListResponseModelSelector extends QueryBuilder {
  get count() {
    return this.__attr(`count`)
  }
  records(
    builder?: string | IssueModelSelector | ((selector: IssueModelSelector) => IssueModelSelector),
  ) {
    return this.__child(`records`, IssueModelSelector, builder)
  }
}
export function selectFromIssueListResponse() {
  return new IssueListResponseModelSelector()
}

export const issueListResponseModelPrimitives = selectFromIssueListResponse().count
