/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from 'mobx'
import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { CategoryModel, CategoryModelType } from './CategoryModel'
import { CategoryModelSelector } from './CategoryModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  records: IObservableArray<CategoryModelType>
}

/**
 * CategoryListResponseBase
 * auto generated base class for the model CategoryListResponseModel.
 */
export const CategoryListResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('CategoryListResponse')
    .props({
      __typename: types.optional(types.literal('CategoryListResponse'), 'CategoryListResponse'),
      records: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => CategoryModel))),
      ),
      count: types.union(types.undefined, types.number),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class CategoryListResponseModelSelector extends QueryBuilder {
  get count() {
    return this.__attr(`count`)
  }
  records(
    builder?:
      | string
      | CategoryModelSelector
      | ((selector: CategoryModelSelector) => CategoryModelSelector),
  ) {
    return this.__child(`records`, CategoryModelSelector, builder)
  }
}
export function selectFromCategoryListResponse() {
  return new CategoryListResponseModelSelector()
}

export const categoryListResponseModelPrimitives = selectFromCategoryListResponse().count
