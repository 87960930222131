import { Breadcrumbs, Link, makeStyles, Paper, Theme, Typography } from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home'
import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'flex',
    width: '100%',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider,
  },
  breadcrumbs: {
    marginTop: theme.spacing(2),
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginTop: 2,
    marginRight: theme.spacing(0.5),
    width: 19,
    height: 19,
  },
  title: {
    color: theme.palette.text.primary,
  },
  content: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  titleContainer: {
    flex: 1,
  },
}))

export interface BreadcrumbItem {
  link?: string
  label: string
  icon?: JSX.Element
}

export interface PageHeaderProps {
  title: string | JSX.Element
  breadcrumbs: Array<BreadcrumbItem>
  children?: JSX.Element | Array<JSX.Element>
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, breadcrumbs, children }) => {
  const classes = useStyles()

  return (
    <Paper square className={classes.header}>
      <div className={classes.titleContainer}>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
        <Breadcrumbs className={classes.breadcrumbs} aria-label="breadcrumb">
          <Link color="inherit" component={RouterLink} to="/" className={classes.link}>
            <HomeIcon className={classes.icon} />
            Home
          </Link>
          {breadcrumbs.map((breadcrumb, index) => {
            if (breadcrumb.link) {
              return (
                <Link
                  key={index}
                  color="inherit"
                  component={RouterLink}
                  to={breadcrumb.link}
                  className={classes.link}
                >
                  {breadcrumb.icon && breadcrumb.icon}
                  {breadcrumb.label}
                </Link>
              )
            }
            return (
              <Typography key={index} color="textPrimary">
                {breadcrumb.label}
              </Typography>
            )
          })}
        </Breadcrumbs>
      </div>
      {children}
    </Paper>
  )
}

export default PageHeader
