import { Formik, FormikProps } from 'formik'
import * as React from 'react'
import * as Yup from 'yup'
import { useToast } from '../common/utils/toast'
import { useStore } from '../../getMstGql'
import {
  FieldGroup,
  FormButtonGroup,
  FormContainer,
  FormSection,
  FormSelectField,
  FormTextField,
} from '../common/forms'
import { Button } from '@material-ui/core'
import {
  userModelPrimitives,
  UserModelType,
  UserRole,
  UserUpdateResponseModelSelector,
  UserUpdateResponseModelType,
} from '../../models'
import { displayMutationError, hasMutationErrors } from '../../utilities/errors'
import { UpdateUserInput } from '../../models/RootStore.base'
import { observer } from 'mobx-react-lite'
import { assignableRoles } from '../../utilities/authorization'

const getInitialFormValues = (user: UserModelType) => ({
  name: user.name,
  password: '',
  confirmPassword: '',
  role: user.role,
})

const schema = Yup.object().shape({
  name: Yup.string().label('Name').required().min(1),
  password: Yup.string().label('Password').min(6),
  confirmPassword: Yup.string()
    .label('Confirm password')
    .test('passwords-match', 'Passwords are not the same', function (value) {
      return this.parent.password === value
    }),
  role: Yup.string().label('Role').required().oneOf(Object.values(UserRole)),
})

export interface EditUserFormProps {
  user: UserModelType
  profile?: boolean
  onComplete: () => any
}

const EditUserForm: React.FC<EditUserFormProps> = ({ user, profile, onComplete }) => {
  const {
    mutateUpdateUser,
    authentication: { currentUser },
  } = useStore()
  const { setToast } = useToast()

  return (
    <FormContainer title={profile ? 'Edit Profile' : 'Edit User'}>
      <Formik
        initialValues={getInitialFormValues(user)}
        validationSchema={schema}
        validateOnBlur={false}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true)

          const { name, password, role } = values
          actions.setSubmitting(true)

          const input: UpdateUserInput = {}
          if (role) {
            input.role = role as any
          }
          if (name) {
            input.name = name
          }
          if (password) {
            input.password = password
          }
          const response = (await mutateUpdateUser(
            { id: user.id, input },
            new UserUpdateResponseModelSelector().message.success
              .user(userModelPrimitives)
              .toString(),
          ).currentPromise()) as { updateUser: UserUpdateResponseModelType }
          actions.setSubmitting(false)

          if (hasMutationErrors(response)) {
            setToast(displayMutationError(response))
            return
          }

          setToast({
            message: `User has been updated!`,
            variant: 'success',
          })
          actions.resetForm()
          onComplete()
        }}
      >
        {(props: FormikProps<any>) => (
          <React.Fragment>
            <FormSection title="">
              <FieldGroup>
                <FormTextField label="Name" name="name" />
              </FieldGroup>
              <FieldGroup>
                <FormTextField label="Password" name="password" type="password" />
              </FieldGroup>
              <FieldGroup>
                <FormTextField label="Confirm password" name="confirmPassword" type="password" />
              </FieldGroup>
              {!profile ? (
                <FieldGroup>
                  <FormSelectField
                    label="Role"
                    name="role"
                    options={assignableRoles(currentUser).map((role) => ({
                      value: role,
                      label: role,
                    }))}
                  />
                </FieldGroup>
              ) : (
                <div />
              )}
            </FormSection>

            <FormButtonGroup>
              <Button
                variant="contained"
                onClick={() => {
                  props.resetForm()
                  onComplete()
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={props.isSubmitting || !props.isValid}
                color="primary"
                onClick={() => {
                  return props.submitForm()
                }}
              >
                Save
              </Button>
            </FormButtonGroup>
          </React.Fragment>
        )}
      </Formik>
    </FormContainer>
  )
}

export default observer(EditUserForm)
