import { IssueTemplateModelSelector } from './IssueTemplateModel.base'
import { categoryModelPrimitives } from './CategoryModel.base'
import { subCategoryModelPrimitives } from './SubCategoryModel.base'
import { issueModelPrimitives, IssueModelSelector } from './IssueModel.base'
import { VoteModelSelector } from './VoteModel.base'
import { legislatorModelPrimitives } from './LegislatorModel'
import { blurbModelPrimitives } from './BlurbModel'

export const issueTemplatesSelector = new IssueTemplateModelSelector().id.createdAt.title.summary
  .category(categoryModelPrimitives)
  .subCategory(subCategoryModelPrimitives)
  .issue(issueModelPrimitives)
  .assignedLayers(
    (qb) => qb.layer((qb) => qb.name.id.createdAt).id.text.url.layerId.issueTemplateId,
  )
  .blurbs(blurbModelPrimitives)
  .toString()

export const voteSelector = new VoteModelSelector().id.issueId.party.value.legislatorId
  .legislator(legislatorModelPrimitives)
  .toString()

export const issueSelector = new IssueModelSelector().id.date.chamber.legislationNumber.level.result.state.rollCallNumber.url.issueTemplateId
  .votes(voteSelector)
  .toString()
