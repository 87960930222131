import { Form as FormikForm, FormikFormProps } from 'formik'
import React from 'react'

export type FormProps = FormikFormProps

const Form: React.FC<FormProps> = ({ children, ...props }) => {
  return (
    <FormikForm translate="" {...props}>
      {children}
    </FormikForm>
  )
}

export default Form
