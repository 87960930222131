/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { IssueModel, IssueModelType } from './IssueModel'
import { IssueModelSelector } from './IssueModel.base'
import { LegislatorModel, LegislatorModelType } from './LegislatorModel'
import { LegislatorModelSelector } from './LegislatorModel.base'
import { PartyEnumType } from './PartyEnum'
import { VoteValueEnumType } from './VoteValueEnum'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  legislator: LegislatorModelType
  issue: IssueModelType
}

/**
 * VoteBase
 * auto generated base class for the model VoteModel.
 */
export const VoteModelBase = withTypedRefs<Refs>()(
  ModelBase.named('Vote')
    .props({
      __typename: types.optional(types.literal('Vote'), 'Vote'),
      id: types.identifier,
      createdAt: types.union(types.undefined, types.frozen()),
      updatedAt: types.union(types.undefined, types.frozen()),
      legislator: types.union(types.undefined, MSTGQLRef(types.late((): any => LegislatorModel))),
      issue: types.union(types.undefined, MSTGQLRef(types.late((): any => IssueModel))),
      value: types.union(types.undefined, VoteValueEnumType),
      party: types.union(types.undefined, PartyEnumType),
      legislatorId: types.union(types.undefined, types.string),
      issueId: types.union(types.undefined, types.string),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class VoteModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  get value() {
    return this.__attr(`value`)
  }
  get party() {
    return this.__attr(`party`)
  }
  get legislatorId() {
    return this.__attr(`legislatorId`)
  }
  get issueId() {
    return this.__attr(`issueId`)
  }
  legislator(
    builder?:
      | string
      | LegislatorModelSelector
      | ((selector: LegislatorModelSelector) => LegislatorModelSelector),
  ) {
    return this.__child(`legislator`, LegislatorModelSelector, builder)
  }
  issue(
    builder?: string | IssueModelSelector | ((selector: IssueModelSelector) => IssueModelSelector),
  ) {
    return this.__child(`issue`, IssueModelSelector, builder)
  }
}
export function selectFromVote() {
  return new VoteModelSelector()
}

export const voteModelPrimitives = selectFromVote().createdAt.updatedAt.value.party.legislatorId
  .issueId
