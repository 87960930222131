/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { LegislatorModel, LegislatorModelType } from './LegislatorModel'
import { LegislatorModelSelector } from './LegislatorModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  legislator: LegislatorModelType
}

/**
 * LegislatorUpdateResponseBase
 * auto generated base class for the model LegislatorUpdateResponseModel.
 */
export const LegislatorUpdateResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('LegislatorUpdateResponse')
    .props({
      __typename: types.optional(
        types.literal('LegislatorUpdateResponse'),
        'LegislatorUpdateResponse',
      ),
      success: types.union(types.undefined, types.boolean),
      message: types.union(types.undefined, types.string),
      legislator: types.union(
        types.undefined,
        types.null,
        MSTGQLRef(types.late((): any => LegislatorModel)),
      ),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class LegislatorUpdateResponseModelSelector extends QueryBuilder {
  get success() {
    return this.__attr(`success`)
  }
  get message() {
    return this.__attr(`message`)
  }
  legislator(
    builder?:
      | string
      | LegislatorModelSelector
      | ((selector: LegislatorModelSelector) => LegislatorModelSelector),
  ) {
    return this.__child(`legislator`, LegislatorModelSelector, builder)
  }
}
export function selectFromLegislatorUpdateResponse() {
  return new LegislatorUpdateResponseModelSelector()
}

export const legislatorUpdateResponseModelPrimitives = selectFromLegislatorUpdateResponse().success
  .message
