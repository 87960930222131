import { Accordion, AccordionSummary, Button, Typography } from '@material-ui/core'
import MaterialTable from 'material-table'
import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Paths } from '../../constants/routes'
import { useQuery } from '../../models/reactUtils'
import { useToast } from '../../components/common/utils/toast'
import { useModal } from '../../components/common/utils/modal'
import { LoadingContainer, NotFound } from '../../components/common/utils'
import {
  ActionButtonContainer,
  FieldGroup,
  PageContents,
  PageHeader,
  ReadOnlyForm,
  ReadOnlyTextField,
  TableActions,
} from '../../components/common'
import AddSubCategoryForm from '../../components/categories/AddSubCategoryForm'
import {
  CategoryModelSelector,
  CategoryModelType,
  MutationResponseModelType,
  subCategoryModelPrimitives,
} from '../../models'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../getMstGql'
import { rowDatum } from '../../utilities/coercion'
import { displayMutationError, hasMutationErrors } from '../../utilities/errors'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export interface CategoryDetailsPageParams {
  id: string
}

const CategoryDetailsPage = () => {
  const { id } = useParams<CategoryDetailsPageParams>()
  const { loading, data, query } = useQuery<{ category: CategoryModelType }>((store) =>
    store.queryCategory(
      { id },
      new CategoryModelSelector().title.id.subCategories(subCategoryModelPrimitives).toString(),
    ),
  )
  const { mutateDeleteCategory, mutateDeleteSubCategory } = useStore()
  const history = useHistory()
  const { setToast } = useToast()
  const { openDrawer, closeDrawer, openDialog } = useModal()

  const category = useMemo(() => {
    return data?.category
  }, [data])

  const handleDeleteCategory = useCallback(async () => {
    const response = (await mutateDeleteCategory({ id }).currentPromise()) as {
      deleteCategory: MutationResponseModelType
    }

    if (hasMutationErrors(response)) {
      setToast(displayMutationError(response))
    } else {
      setToast('Deleted category.')
      history.push(Paths.CategoriesList)
    }
  }, [id, mutateDeleteCategory, setToast, history])

  const handleDeleteSubCategory = useCallback(
    async (subCategoryId: string) => {
      const response = (await mutateDeleteSubCategory({ id: subCategoryId }).currentPromise()) as {
        deleteSubCategory: MutationResponseModelType
      }

      if (hasMutationErrors(response)) {
        setToast(displayMutationError(response))
      } else {
        setToast('Deleted subcategory.')
        query?.refetch()
      }
    },
    [mutateDeleteSubCategory, setToast, query],
  )

  const handleSubCategoryAdded = useCallback(() => {
    closeDrawer()
    query?.refetch()
  }, [closeDrawer, query])

  if (loading && !category) {
    return <LoadingContainer loading={loading} />
  }

  if (!category) {
    return <NotFound />
  }

  return (
    <LoadingContainer loading={loading && !category}>
      <PageHeader
        title={category.title || ''}
        breadcrumbs={[
          { link: Paths.CategoriesList, label: 'Categories' },
          { label: category.title || '' },
        ]}
      >
        <ActionButtonContainer>
          <Button
            variant="outlined"
            color="secondary"
            style={{ marginRight: 7 }}
            onClick={() => {
              openDialog({
                title: 'Delete this record?',
                body: 'This is permanent. Deleted records cannot be restored.',
                onConfirm: handleDeleteCategory,
              })
            }}
          >
            Delete
          </Button>
        </ActionButtonContainer>
      </PageHeader>
      <PageContents>
        <ReadOnlyForm title="General">
          <FieldGroup>
            <ReadOnlyTextField label="Title" value={category.title} />
          </FieldGroup>
        </ReadOnlyForm>

        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Subcategories</Typography>
          </AccordionSummary>
          <TableActions>
            <Button
              variant="contained"
              onClick={(): void => {
                openDrawer(
                  <AddSubCategoryForm category={category} onComplete={handleSubCategoryAdded} />,
                )
              }}
            >
              Add
            </Button>
          </TableActions>
          <MaterialTable
            data={category.subCategories || []}
            columns={[
              {
                title: 'Title',
                field: 'title',
                render: (d) => d.title || '',
                defaultSort: 'desc',
              },
            ]}
            options={{ search: false, filtering: true, toolbar: false }}
            style={{ boxShadow: 'none' }}
            localization={{ header: { actions: '' } }}
            actions={[
              {
                icon: 'delete',
                tooltip: 'Delete subcategory',
                onClick: (event, data) => {
                  openDialog({
                    title: 'Delete this record?',
                    body: 'This is permanent. Deleted records cannot be restored.',
                    onConfirm: () => handleDeleteSubCategory(rowDatum(data).id),
                  })
                },
              },
            ]}
          />
        </Accordion>
      </PageContents>
    </LoadingContainer>
  )
}

export default observer(CategoryDetailsPage)
