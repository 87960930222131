/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from 'mobx'
import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { GenderEnumType } from './GenderEnum'
import { LegislatorIdentityModel, LegislatorIdentityModelType } from './LegislatorIdentityModel'
import { LegislatorIdentityModelSelector } from './LegislatorIdentityModel.base'
import { PartyEnumType } from './PartyEnum'
import { TermModel, TermModelType } from './TermModel'
import { TermModelSelector } from './TermModel.base'
import { VoteModel, VoteModelType } from './VoteModel'
import { VoteModelSelector } from './VoteModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  terms: IObservableArray<TermModelType>
  votes: IObservableArray<VoteModelType>
}

/**
 * LegislatorBase
 * auto generated base class for the model LegislatorModel.
 */
export const LegislatorModelBase = withTypedRefs<Refs>()(
  ModelBase.named('Legislator')
    .props({
      __typename: types.optional(types.literal('Legislator'), 'Legislator'),
      id: types.identifier,
      createdAt: types.union(types.undefined, types.frozen()),
      updatedAt: types.union(types.undefined, types.frozen()),
      gender: types.union(types.undefined, types.null, GenderEnumType),
      birthday: types.union(types.undefined, types.null, types.frozen()),
      identity: types.union(
        types.undefined,
        types.null,
        types.late((): any => LegislatorIdentityModel),
      ),
      terms: types.union(types.undefined, types.array(MSTGQLRef(types.late((): any => TermModel)))),
      votes: types.union(types.undefined, types.array(MSTGQLRef(types.late((): any => VoteModel)))),
      firstName: types.union(types.undefined, types.string),
      lastName: types.union(types.undefined, types.string),
      bioguideId: types.union(types.undefined, types.null, types.string),
      fullName: types.union(types.undefined, types.string),
      mostRecentParty: types.union(types.undefined, types.null, PartyEnumType),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class LegislatorModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  get gender() {
    return this.__attr(`gender`)
  }
  get birthday() {
    return this.__attr(`birthday`)
  }
  get firstName() {
    return this.__attr(`firstName`)
  }
  get lastName() {
    return this.__attr(`lastName`)
  }
  get bioguideId() {
    return this.__attr(`bioguideId`)
  }
  get fullName() {
    return this.__attr(`fullName`)
  }
  get mostRecentParty() {
    return this.__attr(`mostRecentParty`)
  }
  identity(
    builder?:
      | string
      | LegislatorIdentityModelSelector
      | ((selector: LegislatorIdentityModelSelector) => LegislatorIdentityModelSelector),
  ) {
    return this.__child(`identity`, LegislatorIdentityModelSelector, builder)
  }
  terms(
    builder?: string | TermModelSelector | ((selector: TermModelSelector) => TermModelSelector),
  ) {
    return this.__child(`terms`, TermModelSelector, builder)
  }
  votes(
    builder?: string | VoteModelSelector | ((selector: VoteModelSelector) => VoteModelSelector),
  ) {
    return this.__child(`votes`, VoteModelSelector, builder)
  }
}
export function selectFromLegislator() {
  return new LegislatorModelSelector()
}

export const legislatorModelPrimitives = selectFromLegislator().createdAt.updatedAt.gender.birthday
  .firstName.lastName.bioguideId.fullName.mostRecentParty
