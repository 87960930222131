import { Accordion, AccordionSummary, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MaterialTable, { Column, Options, Query as TableQuery, QueryResult } from 'material-table'
import { observer } from 'mobx-react-lite'
import { Query } from 'mst-gql'
import * as React from 'react'
import { useStore } from '../../getMstGql'
import {
  Chamber,
  GovernmentLevel,
  IssueTemplateListResponseModelSelector,
  IssueTemplateListResponseModelType,
  IssueTemplateModelType,
  RootStoreType,
} from '../../models'
import { IssueTemplatesFilters } from '../../models/RootStore.base'
import { issueTemplatesSelector } from '../../models/selectors'
import { rowDatum } from '../../utilities/coercion'
import { formatDate } from '../../utilities/dates'
import { extractDateRangeFilter, extractEqualsFilter } from '../../utilities/filters'
import { extractPaginationArgs } from '../../utilities/tables'
import { DateRangeFilter, SelectFilter } from '../common/utils/table'
import { useTableCacheKey } from '../common/utils/table/useTableCacheKey'
import { StateLookup } from '../../utilities/states'

enum IssueTemplateColumnKey {
  Category = 'category.title',
  Subcategory = 'subCategory.title',
  Date = 'issue.date',
  RollNumber = 'issue.rollCallNumber',
  LegislationNumber = 'issue.legislationNumber',
  Level = 'issue.level',
  Chamber = 'issue.chamber',
  State = 'issue.state',
  Added = 'createdAt',
}

type IssueTemplatesQueryResponseType = { issueTemplates: IssueTemplateListResponseModelType }

const issueTemplatesListSelector = new IssueTemplateListResponseModelSelector().count
  .records(issueTemplatesSelector)
  .toString()

function queryIssueTemplates(
  store: RootStoreType,
  tableQuery: TableQuery<IssueTemplateModelType>,
): Query<IssueTemplatesQueryResponseType> {
  const filters: IssueTemplatesFilters = {}
  extractEqualsFilter(tableQuery, IssueTemplateColumnKey.Category, filters, 'category')
  extractEqualsFilter(tableQuery, IssueTemplateColumnKey.Subcategory, filters, 'subCategory')
  extractEqualsFilter(
    tableQuery,
    IssueTemplateColumnKey.LegislationNumber,
    filters,
    'legislationNumber',
  )
  extractEqualsFilter(tableQuery, IssueTemplateColumnKey.RollNumber, filters, 'rollCallNumber')
  extractEqualsFilter(tableQuery, IssueTemplateColumnKey.Level, filters, 'level')
  extractEqualsFilter(tableQuery, IssueTemplateColumnKey.Chamber, filters, 'chamber')
  extractEqualsFilter(tableQuery, IssueTemplateColumnKey.State, filters, 'state')
  extractDateRangeFilter(tableQuery, IssueTemplateColumnKey.Date, filters, 'date')
  extractDateRangeFilter(tableQuery, IssueTemplateColumnKey.Added, filters)
  return store.queryIssueTemplates(
    { pagination: extractPaginationArgs(tableQuery), filters },
    issueTemplatesListSelector,
  )
}

export type IssueTemplatesTableProps = {
  filters?: {}
  tableOptions?: Options<IssueTemplateModelType>
  onRowClick?: (row: IssueTemplateModelType) => void
  cacheKey?: string | number
}

const IssueTemplatesTable: React.FC<IssueTemplatesTableProps> = ({
  onRowClick,
  cacheKey,
  tableOptions = {},
}) => {
  const store = useStore()
  const tableRef = useTableCacheKey(cacheKey)
  const handleDetailsClick = React.useCallback(
    (row: IssueTemplateModelType): void => onRowClick && onRowClick(row),
    [onRowClick],
  )

  const handleTableUpdate = React.useCallback(
    async (
      query: TableQuery<IssueTemplateModelType>,
    ): Promise<QueryResult<IssueTemplateModelType>> => {
      const results = await queryIssueTemplates(store, query)
      return {
        data: results?.issueTemplates?.records?.toJS() || [],
        totalCount: results?.issueTemplates?.count || 0,
        page: query.page,
      }
    },
    [store],
  )

  const combinedTableOptions: Options<IssueTemplateModelType> = React.useMemo(
    () => ({
      filtering: true,
      sorting: false,
      toolbar: false,
      debounceInterval: 300,
      pageSize: 10,
      ...tableOptions,
    }),
    [tableOptions],
  )

  const columns = React.useMemo(
    (): Array<Column<IssueTemplateModelType>> => [
      { title: 'Category', field: IssueTemplateColumnKey.Category },
      { title: 'Subcategory', field: 'subCategory.title' },
      {
        title: 'Date',
        field: IssueTemplateColumnKey.Date,
        render: (d) => formatDate(d?.issue?.date),
        filtering: true,
        filterComponent: DateRangeFilter,
      },
      { title: 'Roll #', field: IssueTemplateColumnKey.RollNumber },
      { title: 'Bill title', field: IssueTemplateColumnKey.LegislationNumber },
      {
        title: 'Level',
        field: IssueTemplateColumnKey.Level,
        lookup: GovernmentLevel,
        filterComponent: SelectFilter,
      },
      {
        title: 'Chamber',
        field: IssueTemplateColumnKey.Chamber,
        lookup: Chamber,
        filterComponent: SelectFilter,
      },
      {
        title: 'State',
        field: IssueTemplateColumnKey.State,
        lookup: StateLookup,
        filterComponent: SelectFilter,
      },
      {
        title: 'Added',
        field: 'createdAt',
        render: (d) => formatDate(d?.createdAt),
        filtering: true,
        filterComponent: DateRangeFilter,
      },
    ],
    [],
  )

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Issue templates</Typography>
      </AccordionSummary>
      <MaterialTable
        tableRef={tableRef}
        columns={columns}
        data={handleTableUpdate}
        options={combinedTableOptions}
        localization={{ header: { actions: '' } }}
        style={{ boxShadow: 'none' }}
        actions={[
          {
            icon: 'pageview',
            tooltip: 'View details',
            onClick: (event, d): void => handleDetailsClick(rowDatum(d)),
          },
        ]}
      />
    </Accordion>
  )
}

export default observer(IssueTemplatesTable)
