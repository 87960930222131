import { Button, IconButton, makeStyles, TextField, Theme, Typography } from '@material-ui/core'
import { Add, Remove } from '@material-ui/icons'
import debounce from 'lodash/debounce'
import MaterialTable from 'material-table'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { LegislatorModelType } from '../../models'
import { useQuery } from '../../models/reactUtils'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  cancelButton: {
    marginTop: theme.spacing(3),
  },
  buttonContainer: {
    textAlign: 'center',
  },
}))

export interface FindLegislatorFormProps {
  onComplete: () => void
  onAddLegislator: (legislator: LegislatorModelType) => void
  selectedLegislatorIds?: Array<string>
  onRemoveLegislator?: (legislator: LegislatorModelType) => void
}

const FindLegislatorForm: React.FC<FindLegislatorFormProps> = ({
  onComplete,
  onAddLegislator,
  selectedLegislatorIds,
  onRemoveLegislator,
}) => {
  const classes = useStyles()
  const { setQuery, data } = useQuery<{ legislatorsByName: Array<LegislatorModelType> }>()
  const debouncedSetQuery = useMemo(() => debounce(setQuery, 200), [setQuery])
  const [searchName, setSearchName] = useState('')
  const [searchResults, setSearchResults] = useState<Array<LegislatorModelType>>([])

  useEffect(() => {
    if (searchName) {
      debouncedSetQuery((store) => store.queryLegislatorsByName({ name: searchName }))
    } else {
      setSearchResults([])
    }
  }, [searchName, debouncedSetQuery])

  useEffect(() => {
    setSearchResults((previousResults) => data?.legislatorsByName || previousResults)
  }, [data])

  return (
    <div className={classes.root}>
      <Typography variant="h4" color="primary" className={classes.title}>
        Find legislator
      </Typography>
      <TextField
        name="Search"
        variant="standard"
        fullWidth
        value={searchName}
        placeholder="Search legislator name..."
        onChange={(event) => {
          setSearchName(event.target.value)
        }}
      />
      <MaterialTable
        data={searchResults}
        columns={[
          {
            title: '',
            render: (row: LegislatorModelType): React.ReactElement => {
              return (
                <IconButton
                  aria-label="add or remove legislator"
                  component="span"
                  size="small"
                  onClick={() => {
                    if (selectedLegislatorIds?.includes(row.id) && onRemoveLegislator) {
                      onRemoveLegislator(row)
                    } else {
                      onAddLegislator(row)
                    }
                  }}
                >
                  {selectedLegislatorIds?.includes(row.id) && onRemoveLegislator ? (
                    <Remove />
                  ) : (
                    <Add />
                  )}
                </IconButton>
              )
            },
          },
          { title: 'Name', field: 'fullName' },
          { title: 'Party', field: 'mostRecentParty' },
        ]}
        options={{
          search: false,
          toolbar: false,
          paging: false,
        }}
        style={{ boxShadow: 'none' }}
        localization={{ header: { actions: '' } }}
      />
      <div className={classes.buttonContainer}>
        <Button variant="contained" onClick={onComplete} className={classes.cancelButton}>
          Done
        </Button>
      </div>
    </div>
  )
}

export default observer(FindLegislatorForm)
