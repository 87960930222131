import { observer } from 'mobx-react-lite'
import { useStore } from 'src/getMstGql'
import { LoadingContainer } from './index'
import React, { useEffect } from 'react'

const AppInitializer: React.FC = ({ children }) => {
  const store = useStore()
  useEffect(() => {
    if (!store.initializing && !store.initialized) {
      store.initializeApp()
    }
  }, [store])

  return (
    <LoadingContainer loading={!store.initialized} marginTop={10}>
      {children}
    </LoadingContainer>
  )
}

export default observer(AppInitializer)
