/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { LayerModel, LayerModelType } from './LayerModel'
import { LayerModelSelector } from './LayerModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  layer: LayerModelType
}

/**
 * LayerCreationResponseBase
 * auto generated base class for the model LayerCreationResponseModel.
 */
export const LayerCreationResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('LayerCreationResponse')
    .props({
      __typename: types.optional(types.literal('LayerCreationResponse'), 'LayerCreationResponse'),
      success: types.union(types.undefined, types.boolean),
      message: types.union(types.undefined, types.string),
      layer: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => LayerModel))),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class LayerCreationResponseModelSelector extends QueryBuilder {
  get success() {
    return this.__attr(`success`)
  }
  get message() {
    return this.__attr(`message`)
  }
  layer(
    builder?: string | LayerModelSelector | ((selector: LayerModelSelector) => LayerModelSelector),
  ) {
    return this.__child(`layer`, LayerModelSelector, builder)
  }
}
export function selectFromLayerCreationResponse() {
  return new LayerCreationResponseModelSelector()
}

export const layerCreationResponseModelPrimitives = selectFromLayerCreationResponse().success
  .message
