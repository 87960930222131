import { makeStyles, Paper, Theme } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.divider,
  },
}))

export type PageFooterProps = {}

const PageFooter: React.FC<PageFooterProps> = ({ children }) => {
  const classes = useStyles()

  return (
    <Paper square className={classes.footer}>
      {children}
    </Paper>
  )
}

export default PageFooter
