import React from 'react'
import { PageHeader } from 'src/components'

const Dashboard: React.FC = () => {
  return (
    <>
      <PageHeader title="Dashboard" breadcrumbs={[]} />
    </>
  )
}

export default Dashboard
