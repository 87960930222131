/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from 'mobx'
import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { SubCategoryModel, SubCategoryModelType } from './SubCategoryModel'
import { SubCategoryModelSelector } from './SubCategoryModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  records: IObservableArray<SubCategoryModelType>
}

/**
 * SubCategoryListResponseBase
 * auto generated base class for the model SubCategoryListResponseModel.
 */
export const SubCategoryListResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('SubCategoryListResponse')
    .props({
      __typename: types.optional(
        types.literal('SubCategoryListResponse'),
        'SubCategoryListResponse',
      ),
      records: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => SubCategoryModel))),
      ),
      count: types.union(types.undefined, types.number),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class SubCategoryListResponseModelSelector extends QueryBuilder {
  get count() {
    return this.__attr(`count`)
  }
  records(
    builder?:
      | string
      | SubCategoryModelSelector
      | ((selector: SubCategoryModelSelector) => SubCategoryModelSelector),
  ) {
    return this.__child(`records`, SubCategoryModelSelector, builder)
  }
}
export function selectFromSubCategoryListResponse() {
  return new SubCategoryListResponseModelSelector()
}

export const subCategoryListResponseModelPrimitives = selectFromSubCategoryListResponse().count
