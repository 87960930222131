import { Button } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import * as React from 'react'
import * as Yup from 'yup'
import { useToast } from '../common/utils/toast'
import { useStore } from '../../getMstGql'
import {
  FieldGroup,
  FormButtonGroup,
  FormContainer,
  FormSection,
  FormTextField,
} from '../common/forms'
import { BlurbCreationResponseModelType, IssueTemplateModelType } from '../../models'
import { displayMutationError, hasMutationErrors } from '../../utilities/errors'
import { CreateBlurbInput } from '../../models/RootStore.base'
import { observer } from 'mobx-react-lite'

const initialFormValues = {
  name: '',
  text: '',
  url: '',
}

const schema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  text: Yup.string().required('Text is required'),
  url: Yup.string().optional(),
})

export interface AssignLayerFormProps {
  issueTemplate: IssueTemplateModelType
  onComplete: () => any
}

const AssignLayerForm: React.FC<AssignLayerFormProps> = ({ issueTemplate, onComplete }) => {
  const { mutateCreateBlurb } = useStore()
  const { setToast } = useToast()
  return (
    <FormContainer title="Add Blurb">
      <Formik
        initialValues={initialFormValues}
        validationSchema={schema}
        validateOnBlur={false}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true)

          const { name, text, url } = values
          const input: CreateBlurbInput = {
            issueTemplateId: issueTemplate.id,
            name,
            text,
          }
          if (url) {
            input.url = url
          }
          const response = (await mutateCreateBlurb({ input }).currentPromise()) as {
            createBlurb: BlurbCreationResponseModelType
          }
          actions.setSubmitting(false)

          if (hasMutationErrors(response)) {
            setToast(displayMutationError(response))
            return
          }

          setToast({
            message: `Blurb has been created!`,
            variant: 'success',
          })
          actions.resetForm()
          onComplete()
        }}
      >
        {(props: FormikProps<any>) => (
          <React.Fragment>
            <FormSection title="">
              <FieldGroup>
                <FormTextField label="Name" name="name" />
              </FieldGroup>
            </FormSection>

            <FormSection title="">
              <FieldGroup>
                <FormTextField label="Text" name="text" InputProps={{ rows: 4 }} multiline />
              </FieldGroup>
            </FormSection>

            <FormSection title="">
              <FieldGroup>
                <FormTextField label="URL (optional)" name="url" />
              </FieldGroup>
            </FormSection>

            <FormButtonGroup>
              <Button
                variant="contained"
                onClick={() => {
                  props.resetForm()
                  onComplete()
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={props.isSubmitting}
                onClick={() => {
                  return props.submitForm()
                }}
              >
                Save
              </Button>
            </FormButtonGroup>
          </React.Fragment>
        )}
      </Formik>
    </FormContainer>
  )
}

export default observer(AssignLayerForm)
