export type Maybe<T> = T | undefined

export class IssueUrl {
  static Formats = [
    new RegExp(/^.*evs\/(\d+)\D+(\d+).*$/),
    new RegExp(/^.*otes\/(\d\d\d\d)(\d+).*$/),
  ]

  constructor(public readonly year: string, public readonly rollNumber: string) {}

  static from(url: string): Maybe<IssueUrl> {
    const lowerUrl = url.toLowerCase()
    const match = IssueUrl.Formats.map((regEx) => regEx.exec(lowerUrl)).find((match) => !!match)
    if (!match) {
      return undefined
    }
    const [, year, rollNumber] = match
    return new IssueUrl(year, rollNumber)
  }

  get newFormat(): string {
    return `https://clerk.house.gov/votes/${this.year}${this.rollNumber}`
  }

  get oldFormat(): string {
    return `http://clerk.house.gov/evs/${this.year}/roll${this.rollNumber}.xml`
  }
}
