import { Query as TableQuery } from 'material-table'
import { Maybe } from './types'
import { IDateRange } from './dates'

export function extractInFilter<T extends object>(
  tableQuery: TableQuery<T>,
  key: string,
  filters: any,
  filterKey?: string,
): void {
  const filterValue: Maybe<Array<any>> = tableQuery.filters.find((f) => f.column.field === key)
    ?.value
  if (filterValue && filterValue.length) {
    filters[filterKey || key] = filterValue
  }
}

export function extractEqualsFilter<T extends object>(
  tableQuery: TableQuery<T>,
  key: string,
  filters: any,
  filterKey?: string,
): void {
  const filterValue: Maybe<any> = tableQuery.filters.find((f) => f.column.field === key)?.value
  if (filterValue) {
    filters[filterKey || key] = filterValue
  }
}

export function extractDateRangeFilter<T extends object>(
  tableQuery: TableQuery<T>,
  key: string,
  filters: any,
  filterKey?: string,
): void {
  const filterValue: Maybe<IDateRange> = tableQuery.filters.find((f) => f.column.field === key)
    ?.value
  if (!filterValue?.start && !filterValue?.end) {
    return
  }

  if (filterValue) {
    filters[filterKey || key] = filterValue
  }
}
