/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from 'mobx'
import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { LegislatorModel, LegislatorModelType } from './LegislatorModel'
import { LegislatorModelSelector } from './LegislatorModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  records: IObservableArray<LegislatorModelType>
}

/**
 * LegislatorListResponseBase
 * auto generated base class for the model LegislatorListResponseModel.
 */
export const LegislatorListResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('LegislatorListResponse')
    .props({
      __typename: types.optional(types.literal('LegislatorListResponse'), 'LegislatorListResponse'),
      records: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => LegislatorModel))),
      ),
      count: types.union(types.undefined, types.number),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class LegislatorListResponseModelSelector extends QueryBuilder {
  get count() {
    return this.__attr(`count`)
  }
  records(
    builder?:
      | string
      | LegislatorModelSelector
      | ((selector: LegislatorModelSelector) => LegislatorModelSelector),
  ) {
    return this.__child(`records`, LegislatorModelSelector, builder)
  }
}
export function selectFromLegislatorListResponse() {
  return new LegislatorListResponseModelSelector()
}

export const legislatorListResponseModelPrimitives = selectFromLegislatorListResponse().count
