/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from 'mobx'
import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { TermModel, TermModelType } from './TermModel'
import { TermModelSelector } from './TermModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  records: IObservableArray<TermModelType>
}

/**
 * TermListResponseBase
 * auto generated base class for the model TermListResponseModel.
 */
export const TermListResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('TermListResponse')
    .props({
      __typename: types.optional(types.literal('TermListResponse'), 'TermListResponse'),
      records: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => TermModel))),
      ),
      count: types.union(types.undefined, types.number),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class TermListResponseModelSelector extends QueryBuilder {
  get count() {
    return this.__attr(`count`)
  }
  records(
    builder?: string | TermModelSelector | ((selector: TermModelSelector) => TermModelSelector),
  ) {
    return this.__child(`records`, TermModelSelector, builder)
  }
}
export function selectFromTermListResponse() {
  return new TermListResponseModelSelector()
}

export const termListResponseModelPrimitives = selectFromTermListResponse().count
