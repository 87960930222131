import Snackbar from '@material-ui/core/Snackbar'
import * as React from 'react'
import { ToastContentProps, ToastContext } from './Context'
import { ToastContent } from 'src/components'

export interface ToastProviderProps {
  children: JSX.Element | Array<JSX.Element>
}

interface ToastProviderState {
  open: boolean
  content: ToastContentProps
}

const defaultState: ToastProviderState = {
  open: false,
  content: {
    message: '',
  },
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [state, setState] = React.useState<ToastProviderState>(defaultState)

  const handleClose = (): void => {
    setState({ ...state, open: false })
  }

  const handleSetToastContent = (contentVal: string | ToastContentProps): void => {
    const content = typeof contentVal === 'string' ? { message: contentVal } : contentVal
    setState({ open: true, content })
  }

  return (
    <ToastContext.Provider
      value={{
        closeToast: handleClose,
        setToast: handleSetToastContent,
      }}
    >
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        autoHideDuration={2000}
        open={state.open}
        message={state.content.message}
        onClose={handleClose}
      >
        <ToastContent
          variant={state.content.variant || 'info'}
          message={state.content.message}
          onClose={handleClose}
        />
      </Snackbar>
      {children}
    </ToastContext.Provider>
  )
}
