import MaterialTable, { Column, Options, Query as TableQuery, QueryResult } from 'material-table'
import * as React from 'react'
import {
  CategoryListResponseModelSelector,
  CategoryListResponseModelType,
  CategoryModelType,
  CategoryOrderColumns,
  RootStoreType,
  subCategoryModelPrimitives,
} from '../../models'
import { Query } from 'mst-gql'
import { rowDatum } from '../../utilities/coercion'
import { extractOrderByArgs, extractPaginationArgs } from '../../utilities/tables'
import { useStore } from '../../getMstGql'
import { observer } from 'mobx-react-lite'
import { Accordion, AccordionSummary, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { CategoriesFilters } from '../../models/RootStore.base'
import { extractEqualsFilter } from '../../utilities/filters'
import { useTableCacheKey } from '../common/utils/table/useTableCacheKey'

type LegislatorsQueryResponseType = { categories: CategoryListResponseModelType }

const categoriesSelector = new CategoryListResponseModelSelector().count
  .records((qb) => qb.id.createdAt.title.updatedAt.subCategories(subCategoryModelPrimitives))
  .toString()

function queryLegislators(
  store: RootStoreType,
  tableQuery: TableQuery<CategoryModelType>,
): Query<LegislatorsQueryResponseType> {
  const filters: CategoriesFilters = {}
  extractEqualsFilter(tableQuery, 'title', filters)
  extractEqualsFilter(tableQuery, 'subCategories', filters)
  return store.queryCategories(
    {
      pagination: extractPaginationArgs(tableQuery),
      orderBy: extractOrderByArgs(tableQuery, CategoryOrderColumns),
      filters,
    },
    categoriesSelector,
  )
}

export type CategoriesTableProps = {
  filters?: {}
  tableOptions?: Options<CategoryModelType>
  onRowClick?: (row: CategoryModelType) => void
  cacheKey?: string | number
}

const CategoriesTable: React.FC<CategoriesTableProps> = ({
  onRowClick,
  cacheKey,
  tableOptions = {},
}) => {
  const store = useStore()
  const tableRef = useTableCacheKey(cacheKey)
  const handleDetailsClick = React.useCallback(
    (row: CategoryModelType): void => onRowClick && onRowClick(row),
    [onRowClick],
  )

  const handleTableUpdate = React.useCallback(
    async (query: TableQuery<CategoryModelType>): Promise<QueryResult<CategoryModelType>> => {
      const results = await queryLegislators(store, query)
      return {
        data: results?.categories?.records?.toJS() || [],
        totalCount: results?.categories?.count || 0,
        page: query.page,
      }
    },
    [store],
  )

  const combinedTableOptions: Options<CategoryModelType> = React.useMemo(
    () => ({
      filtering: true,
      toolbar: false,
      debounceInterval: 300,
      pageSize: 10,
      ...tableOptions,
    }),
    [tableOptions],
  )

  const columns = React.useMemo(
    (): Array<Column<CategoryModelType>> => [
      {
        title: 'Title',
        field: 'title',
        filtering: true,
      },
      {
        title: 'Subcategories',
        field: 'subCategories',
        render: (d) =>
          d.subCategories
            ?.toJS()
            .map((sc) => sc.title)
            .join(', '),
        filtering: true,
      },
    ],
    [],
  )

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Categories</Typography>
      </AccordionSummary>
      <MaterialTable
        tableRef={tableRef}
        columns={columns}
        data={handleTableUpdate}
        options={combinedTableOptions}
        localization={{ header: { actions: '' } }}
        style={{ boxShadow: 'none' }}
        actions={[
          {
            icon: 'pageview',
            tooltip: 'View details',
            onClick: (event, d): void => handleDetailsClick(rowDatum(d)),
          },
        ]}
      />
    </Accordion>
  )
}

export default observer(CategoriesTable)
