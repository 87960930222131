import * as React from 'react'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  ActionButtonContainer,
  AddLayerForm,
  Button,
  LayersTable,
  PageContents,
  PageHeader,
  useModal,
} from 'src/components'
import { LayerModelType } from '../../models'

const LayersListPage: React.FC = () => {
  const history = useHistory()
  const { openDrawer, closeDrawer } = useModal()
  const [cacheKey, setCacheKey] = useState(Date.now())
  const updateCacheKey = useCallback(() => setCacheKey(Date.now()), [setCacheKey])

  const handleAddLayer = useCallback(
    (willNavigate?: boolean) => {
      closeDrawer()
      if (!willNavigate) {
        updateCacheKey()
      }
    },
    [closeDrawer, updateCacheKey],
  )

  const handleRowClick = React.useCallback(
    (row: LayerModelType): void => {
      history.push(`/layers/${row.id}`)
    },
    [history],
  )

  return (
    <>
      <PageHeader title="Layers" breadcrumbs={[{ label: 'Layers' }]}>
        <ActionButtonContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={() => openDrawer(<AddLayerForm onComplete={handleAddLayer} />)}
          >
            Create
          </Button>
        </ActionButtonContainer>
      </PageHeader>
      <PageContents>
        <LayersTable onRowClick={handleRowClick} cacheKey={cacheKey} />
      </PageContents>
    </>
  )
}

export default LayersListPage
