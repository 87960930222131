import MaterialTable, { Column, Options, Query as TableQuery, QueryResult } from 'material-table'
import * as React from 'react'
import {
  LayerListResponseModelSelector,
  LayerListResponseModelType,
  layerModelPrimitives,
  LayerModelType,
  RootStoreType,
} from '../../models'
import { Query } from 'mst-gql'
import { rowDatum } from '../../utilities/coercion'
import { extractPaginationArgs } from '../../utilities/tables'
import { useStore } from '../../getMstGql'
import { observer } from 'mobx-react-lite'
import { Accordion, AccordionSummary, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useTableCacheKey } from '../common/utils/table/useTableCacheKey'

type LayersQueryResponseType = { layers: LayerListResponseModelType }

const layersSelector = new LayerListResponseModelSelector().count
  .records(layerModelPrimitives)
  .toString()

function queryLegislators(
  store: RootStoreType,
  tableQuery: TableQuery<LayerModelType>,
): Query<LayersQueryResponseType> {
  return store.queryLayers({ pagination: extractPaginationArgs(tableQuery) }, layersSelector)
}

export type LayersTableProps = {
  filters?: {}
  tableOptions?: Options<LayerModelType>
  onRowClick?: (row: LayerModelType) => void
  cacheKey?: string | number
}

const LayersTable: React.FC<LayersTableProps> = ({ onRowClick, cacheKey, tableOptions = {} }) => {
  const store = useStore()
  const tableRef = useTableCacheKey(cacheKey)
  const handleDetailsClick = React.useCallback(
    (row: LayerModelType): void => onRowClick && onRowClick(row),
    [onRowClick],
  )

  const handleTableUpdate = React.useCallback(
    async (query: TableQuery<LayerModelType>): Promise<QueryResult<LayerModelType>> => {
      const results = await queryLegislators(store, query)
      return {
        data: results?.layers?.records?.toJS() || [],
        totalCount: results?.layers?.count || 0,
        page: query.page,
      }
    },
    [store],
  )

  const combinedTableOptions: Options<LayerModelType> = React.useMemo(
    () => ({
      filtering: false,
      sorting: false,
      toolbar: false,
      debounceInterval: 300,
      pageSize: 10,
      ...tableOptions,
    }),
    [tableOptions],
  )

  const columns = React.useMemo(
    (): Array<Column<LayerModelType>> => [{ title: 'Name', field: 'name', render: (d) => d.name }],
    [],
  )

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Layers</Typography>
      </AccordionSummary>
      <MaterialTable
        tableRef={tableRef}
        columns={columns}
        data={handleTableUpdate}
        options={combinedTableOptions}
        localization={{ header: { actions: '' } }}
        style={{ boxShadow: 'none' }}
        actions={[
          {
            icon: 'pageview',
            tooltip: 'View details',
            onClick: (event, d): void => handleDetailsClick(rowDatum(d)),
          },
        ]}
      />
    </Accordion>
  )
}

export default observer(LayersTable)
