/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from 'mobx-state-tree'

/**
 * Typescript enum
 */

export enum VotesOrderColumns {
  IssueDate = 'IssueDate',
}

/**
 * VotesOrderColumns
 */
export const VotesOrderColumnsEnumType = types.enumeration('VotesOrderColumns', ['IssueDate'])
