import * as React from 'react'
import { useCallback, useState } from 'react'
import {
  ActionButtonContainer,
  AddIssueForm,
  Button,
  IssuesTable,
  PageContents,
  PageHeader,
  useModal,
} from 'src/components'
import { useHistory } from 'react-router-dom'
import { IssueModelType } from '../../models'

const VotesListPage: React.FC = () => {
  const history = useHistory()
  const handleRowClick = React.useCallback(
    (row: IssueModelType): void => {
      history.push(`/votes/${row.id}`)
    },
    [history],
  )

  const { openDrawer, closeDrawer } = useModal()
  const [cacheKey, setCacheKey] = useState(Date.now())
  const updateCacheKey = useCallback(() => setCacheKey(Date.now()), [setCacheKey])

  const handleAddIssue = useCallback(
    (willNavigate?: boolean) => {
      if (!willNavigate) {
        updateCacheKey()
      }
      closeDrawer()
    },
    [closeDrawer, updateCacheKey],
  )

  return (
    <>
      <PageHeader title="Votes" breadcrumbs={[{ label: 'Votes' }]}>
        <ActionButtonContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={() => openDrawer(<AddIssueForm onComplete={handleAddIssue} />)}
          >
            Create
          </Button>
        </ActionButtonContainer>
      </PageHeader>
      <PageContents>
        <IssuesTable onRowClick={handleRowClick} cacheKey={cacheKey} />
      </PageContents>
    </>
  )
}

export default VotesListPage
