import * as _ from 'lodash'
import { SelectOption } from '../components/common/forms/FormSelectField'
import { CategoryModelType, SubCategoryModelType } from '../models'

export const constructSubCategoryOptions = (
  categoryId: string | undefined,
  categories: Array<CategoryModelType>,
): Array<SelectOption> => {
  if (!categoryId) return []
  const subCategories: Array<SubCategoryModelType> =
    categories.find((c) => c.id === categoryId)?.subCategories?.toJS() || []
  return _.sortBy(subCategories, ['title']).map((subCat) => ({
    value: subCat.id,
    label: subCat.title || '',
  }))
}

export const constructCategoryOptions = (
  categories: Array<CategoryModelType>,
): Array<SelectOption> =>
  _.sortBy(categories, ['title']).map((cat) => ({ value: cat.id, label: cat.title || '' }))
