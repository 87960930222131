/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from 'mobx'
import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { LayerModel, LayerModelType } from './LayerModel'
import { LayerModelSelector } from './LayerModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  records: IObservableArray<LayerModelType>
}

/**
 * LayerListResponseBase
 * auto generated base class for the model LayerListResponseModel.
 */
export const LayerListResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('LayerListResponse')
    .props({
      __typename: types.optional(types.literal('LayerListResponse'), 'LayerListResponse'),
      records: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => LayerModel))),
      ),
      count: types.union(types.undefined, types.number),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class LayerListResponseModelSelector extends QueryBuilder {
  get count() {
    return this.__attr(`count`)
  }
  records(
    builder?: string | LayerModelSelector | ((selector: LayerModelSelector) => LayerModelSelector),
  ) {
    return this.__child(`records`, LayerModelSelector, builder)
  }
}
export function selectFromLayerListResponse() {
  return new LayerListResponseModelSelector()
}

export const layerListResponseModelPrimitives = selectFromLayerListResponse().count
