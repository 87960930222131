import { Drawer, makeStyles, Theme } from '@material-ui/core'
import * as React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: '800px',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '600px',
    },
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
  },
}))

export interface FormDrawerProps {
  open: boolean
  onClose: () => any
  children: any
}

const FormDrawer: React.FC<FormDrawerProps> = ({ children, open, onClose }) => {
  const classes = useStyles()
  return (
    <Drawer anchor="right" open={open} onClose={onClose} PaperProps={{ className: classes.paper }}>
      {children}
    </Drawer>
  )
}

export default FormDrawer
