import React from 'react'
import { TextField, TextFieldProps, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginRight: 0,
      marginBottom: 0,
    },
  },
}))

export type TextInputProps = TextFieldProps

const TextInput: React.FC<TextInputProps> = (props) => {
  const styles = useStyles()
  return <TextField variant="standard" fullWidth className={styles.container} {...props} />
}

export default TextInput
