import { Button } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import * as React from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useToast } from '../common/utils/toast'
import { useStore } from '../../getMstGql'
import {
  FieldGroup,
  FormButtonGroup,
  FormContainer,
  FormSection,
  FormSelectField,
  FormTextField,
} from '../common/forms'
import {
  UserCreationResponseModelSelector,
  UserCreationResponseModelType,
  userModelPrimitives,
  UserRole,
} from '../../models'
import { displayMutationError, hasMutationErrors } from '../../utilities/errors'
import { observer } from 'mobx-react-lite'
import { assignableRoles } from '../../utilities/authorization'
import { CreateUserInput } from '../../models/RootStore.base'

const initialFormValues = {
  email: '',
  name: '',
  password: '',
  confirmPassword: '',
  role: UserRole.USER,
}
const schema = Yup.object().shape({
  email: Yup.string().label('Email').required().email(),
  name: Yup.string().label('Name').required().min(1),
  password: Yup.string().label('Password').required().min(6),
  confirmPassword: Yup.string()
    .label('Confirm password')
    .required()
    .test('passwords-match', 'Passwords are not the same', function (value) {
      return this.parent.password === value
    }),
  role: Yup.string().label('Role').required().oneOf(Object.values(UserRole)),
})

export interface AddUserFormProps {
  onComplete: (willNavigate?: boolean) => any
}

const AddUserForm: React.FC<AddUserFormProps> = ({ onComplete }) => {
  const history = useHistory()
  const [navigateAfterCreation, setNavigateAfterCreate] = useState(false)
  const {
    mutateCreateUser,
    authentication: { currentUser },
  } = useStore()
  const { setToast } = useToast()

  return (
    <FormContainer title="Add User">
      <Formik
        initialValues={initialFormValues}
        validationSchema={schema}
        validateOnBlur={false}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true)
          const input: CreateUserInput = {
            email: values.email,
            name: values.name,
            role: values.role,
            password: values.password,
          }
          const response = (await mutateCreateUser(
            { input },
            new UserCreationResponseModelSelector().message.success
              .user(userModelPrimitives)
              .toString(),
          ).currentPromise()) as { createUser: UserCreationResponseModelType }
          actions.setSubmitting(false)

          if (hasMutationErrors(response)) {
            setToast(displayMutationError(response))
            return
          }

          setToast({
            message: `User has been created!`,
            variant: 'success',
          })
          actions.resetForm()
          onComplete(navigateAfterCreation)
          if (navigateAfterCreation) {
            const userId = response?.createUser?.user?.id
            history.push(`/users/${userId}`)
          }
        }}
      >
        {(props: FormikProps<any>) => (
          <React.Fragment>
            <FormSection title="">
              <FieldGroup>
                <FormTextField label="Email" name="email" />
              </FieldGroup>
              <FieldGroup>
                <FormTextField label="Name" name="name" />
              </FieldGroup>
              <FieldGroup>
                <FormTextField label="Password" name="password" type="password" />
              </FieldGroup>
              <FieldGroup>
                <FormTextField label="Confirm password" name="confirmPassword" type="password" />
              </FieldGroup>
              <FieldGroup>
                <FormSelectField
                  label="Role"
                  name="role"
                  options={assignableRoles(currentUser).map((role) => ({
                    value: role,
                    label: role,
                  }))}
                />
              </FieldGroup>
            </FormSection>

            <FormButtonGroup>
              <Button
                variant="contained"
                onClick={() => {
                  props.resetForm()
                  onComplete()
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={props.isSubmitting}
                onClick={() => {
                  setNavigateAfterCreate(false)
                  return props.submitForm()
                }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={props.isSubmitting}
                onClick={() => {
                  setNavigateAfterCreate(true)
                  return props.submitForm()
                }}
              >
                Save and View
              </Button>
            </FormButtonGroup>
          </React.Fragment>
        )}
      </Formik>
    </FormContainer>
  )
}

export default observer(AddUserForm)
