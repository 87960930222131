import { Button } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import * as React from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useToast } from '../common/utils/toast'
import { useStore } from '../../getMstGql'
import {
  FieldGroup,
  FormButtonGroup,
  FormContainer,
  FormDateField,
  FormSection,
  FormSelectField,
  FormTextField,
} from '../common/forms'
import {
  Chamber,
  GovernmentLevel,
  IssueCreationResponseModelSelector,
  IssueCreationResponseModelType,
  issueModelPrimitives,
  IssueResult,
  State,
} from '../../models'
import { displayMutationError, hasMutationErrors } from '../../utilities/errors'
import { CreateIssueInput } from '../../models/RootStore.base'

const initialFormValues: Partial<CreateIssueInput> = {
  date: undefined,
  chamber: undefined,
  legislationNumber: undefined,
  state: undefined,
  description: '',
  url: '',
  rollCallNumber: undefined,
  result: undefined,
  level: undefined,
}

const schema = Yup.object().shape({
  date: Yup.object().required('Required'),
  chamber: Yup.string().required('Required'),
  legislationNumber: Yup.string().required('Required'),
  state: Yup.string().when('level', {
    is: GovernmentLevel.State,
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  description: Yup.string(),
  url: Yup.string().required('Required'),
  rollCallNumber: Yup.number().required('Required'),
  result: Yup.string().required('Required'),
  level: Yup.string().required('Required'),
})

export interface AddIssueFormProps {
  onComplete: (willNavigate?: boolean) => any
}

const AddIssueForm: React.FC<AddIssueFormProps> = ({ onComplete }) => {
  const history = useHistory()
  const [navigateAfterCreation, setNavigateAfterCreate] = useState(false)
  const store = useStore()
  const { setToast } = useToast()

  return (
    <FormContainer title="Add Vote">
      <Formik
        initialValues={initialFormValues}
        validationSchema={schema}
        validateOnBlur={false}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true)

          const response = (await store
            .mutateCreateIssue(
              { input: values as CreateIssueInput },
              new IssueCreationResponseModelSelector().message.success
                .issue(issueModelPrimitives)
                .toString(),
            )
            .currentPromise()) as { createIssue: IssueCreationResponseModelType }

          if (hasMutationErrors(response)) {
            setToast(displayMutationError(response))
            return
          }

          setToast({
            message: `Vote ${values.legislationNumber} has been created!`,
            variant: 'success',
          })
          actions.resetForm()
          onComplete(navigateAfterCreation)
          if (navigateAfterCreation) {
            const issueId = response?.createIssue?.issue?.id
            history.push(`/votes/${issueId}`)
          }
        }}
      >
        {(props: FormikProps<any>) => (
          <React.Fragment>
            <FormSection title="Branch">
              <FieldGroup>
                <FormSelectField
                  label="Level"
                  name="level"
                  options={Object.values(GovernmentLevel).map((value) => ({
                    value,
                    label: value,
                  }))}
                  clearable={false}
                />
              </FieldGroup>
              <FieldGroup>
                <FormSelectField
                  label="Chamber"
                  name="chamber"
                  options={Object.values(Chamber).map((value) => ({
                    value,
                    label: value,
                  }))}
                  clearable={false}
                />
              </FieldGroup>
              <FieldGroup hidden={props.values.level !== GovernmentLevel.State}>
                <FormSelectField
                  label="State"
                  name="state"
                  options={Object.values(State).map((value) => ({
                    value,
                    label: value,
                  }))}
                />
              </FieldGroup>
            </FormSection>

            <FormSection title="Details">
              <FieldGroup>
                <FormTextField label="URL" name="url" />
              </FieldGroup>
              <FieldGroup>
                <FormDateField label="Date" name="date" />
              </FieldGroup>
              <FieldGroup>
                <FormSelectField
                  label="Result"
                  name="result"
                  options={Object.values(IssueResult).map((value) => ({
                    value,
                    label: value,
                  }))}
                  clearable={false}
                />
              </FieldGroup>
              <FieldGroup>
                <FormTextField label="Bill title" name="legislationNumber" />
              </FieldGroup>
              <FieldGroup>
                <FormTextField label="Roll #" name="rollCallNumber" type="number" />
              </FieldGroup>
              <FieldGroup>
                <FormTextField
                  label="Description"
                  name="description"
                  InputProps={{ rows: 3 }}
                  multiline
                />
              </FieldGroup>
            </FormSection>

            <FormButtonGroup>
              <Button
                variant="contained"
                onClick={() => {
                  props.resetForm()
                  onComplete()
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={props.isSubmitting}
                onClick={() => {
                  setNavigateAfterCreate(false)
                  return props.submitForm()
                }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={props.isSubmitting}
                onClick={() => {
                  setNavigateAfterCreate(true)
                  return props.submitForm()
                }}
              >
                Save and View
              </Button>
            </FormButtonGroup>
          </React.Fragment>
        )}
      </Formik>
    </FormContainer>
  )
}

export default AddIssueForm
