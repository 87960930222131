import { observer } from 'mobx-react-lite'
import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useStore } from 'src/getMstGql'
import { Paths } from '../../../constants/routes'
import { UserRole } from '../../../models'

interface PrivateRouteProps extends RouteProps {
  redirect?: string
  mustBeLoggedOut?: boolean
  requiredRole?: UserRole
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  redirect,
  location,
  mustBeLoggedOut,
  requiredRole,
  ...rest
}) => {
  const { authentication } = useStore()
  const isLoggedIn = authentication.isLoggedIn()
  const redirectComponent = (
    <Redirect
      to={{
        pathname: redirect || Paths.Login,
        state: { from: location },
      }}
    />
  )

  if (requiredRole && !authentication.hasRole(requiredRole)) {
    return redirectComponent
  }

  const shouldRedirect = mustBeLoggedOut ? isLoggedIn : !isLoggedIn
  return shouldRedirect ? redirectComponent : <Route {...rest} />
}

export default observer(PrivateRoute)
