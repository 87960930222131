/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from 'mobx'
import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { VoteModel, VoteModelType } from './VoteModel'
import { VoteModelSelector } from './VoteModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  records: IObservableArray<VoteModelType>
}

/**
 * VoteListResponseBase
 * auto generated base class for the model VoteListResponseModel.
 */
export const VoteListResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('VoteListResponse')
    .props({
      __typename: types.optional(types.literal('VoteListResponse'), 'VoteListResponse'),
      records: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => VoteModel))),
      ),
      count: types.union(types.undefined, types.number),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class VoteListResponseModelSelector extends QueryBuilder {
  get count() {
    return this.__attr(`count`)
  }
  records(
    builder?: string | VoteModelSelector | ((selector: VoteModelSelector) => VoteModelSelector),
  ) {
    return this.__child(`records`, VoteModelSelector, builder)
  }
}
export function selectFromVoteListResponse() {
  return new VoteListResponseModelSelector()
}

export const voteListResponseModelPrimitives = selectFromVoteListResponse().count
