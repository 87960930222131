/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { ImportGroupModel, ImportGroupModelType } from './ImportGroupModel'
import { ImportGroupModelSelector } from './ImportGroupModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  importGroup: ImportGroupModelType
}

/**
 * ImportGroupUpdateResponseBase
 * auto generated base class for the model ImportGroupUpdateResponseModel.
 */
export const ImportGroupUpdateResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('ImportGroupUpdateResponse')
    .props({
      __typename: types.optional(
        types.literal('ImportGroupUpdateResponse'),
        'ImportGroupUpdateResponse',
      ),
      success: types.union(types.undefined, types.boolean),
      message: types.union(types.undefined, types.string),
      importGroup: types.union(
        types.undefined,
        types.null,
        MSTGQLRef(types.late((): any => ImportGroupModel)),
      ),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class ImportGroupUpdateResponseModelSelector extends QueryBuilder {
  get success() {
    return this.__attr(`success`)
  }
  get message() {
    return this.__attr(`message`)
  }
  importGroup(
    builder?:
      | string
      | ImportGroupModelSelector
      | ((selector: ImportGroupModelSelector) => ImportGroupModelSelector),
  ) {
    return this.__child(`importGroup`, ImportGroupModelSelector, builder)
  }
}
export function selectFromImportGroupUpdateResponse() {
  return new ImportGroupUpdateResponseModelSelector()
}

export const importGroupUpdateResponseModelPrimitives = selectFromImportGroupUpdateResponse()
  .success.message
