import { Button } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import * as React from 'react'
import { useMemo } from 'react'
import * as Yup from 'yup'
import { useToast } from '../common/utils/toast'
import { useStore } from '../../getMstGql'
import {
  FieldGroup,
  FormButtonGroup,
  FormContainer,
  FormDateField,
  FormSection,
  FormSelectField,
  FormTextField,
} from '../common/forms'
import {
  Chamber,
  GovernmentLevel,
  Party,
  State,
  TermCreationResponseModelSelector,
  TermCreationResponseModelType,
  termModelPrimitives,
} from '../../models'
import { displayMutationError, hasMutationErrors } from '../../utilities/errors'
import { CreateTermInput } from '../../models/RootStore.base'
import { InitialFormValues } from '../../utilities/forms'

const getInitialFormValues = (legislatorId: string): InitialFormValues<CreateTermInput> => {
  return {
    legislatorId,
    district: null,
    chamber: null,
    endDate: null,
    level: null,
    party: null,
    startDate: null,
    state: null,
  }
}

const schema = Yup.object().shape({
  district: Yup.number().nullable(),
  chamber: Yup.string().required('Required').oneOf(Object.values(Chamber)),
  level: Yup.string().required('Required').oneOf(Object.values(GovernmentLevel)),
  party: Yup.string().required('Required').oneOf(Object.values(Party)),
  state: Yup.string().required('Required').oneOf(Object.values(State)),
  startDate: Yup.object().nullable().required('Required'),
  endDate: Yup.object().nullable().required('Required'),
})

export interface AddTermFormProps {
  onComplete: () => any
  legislatorId: string
}

const AddTermForm: React.FC<AddTermFormProps> = ({ onComplete, legislatorId }) => {
  const store = useStore()
  const { setToast } = useToast()
  const initialValues = useMemo(() => getInitialFormValues(legislatorId), [legislatorId])
  return (
    <FormContainer title="Add Term">
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true)

          const response = (await store
            .mutateCreateTerm(
              { input: values as CreateTermInput },
              new TermCreationResponseModelSelector().message.success
                .term(termModelPrimitives)
                .toString(),
            )
            .currentPromise()) as { createTerm: TermCreationResponseModelType }

          if (hasMutationErrors(response)) {
            setToast(displayMutationError(response))
            return
          }

          setToast({
            message: 'Term has been created!',
            variant: 'success',
          })
          actions.resetForm()
          onComplete()
        }}
      >
        {(props: FormikProps<any>) => (
          <React.Fragment>
            <FormSection title="Position">
              <FieldGroup>
                <FormSelectField
                  label="Level"
                  name="level"
                  options={Object.values(GovernmentLevel).map((value) => ({
                    value,
                    label: value,
                  }))}
                />
              </FieldGroup>
              <FieldGroup>
                <FormSelectField
                  label="Chamber"
                  name="chamber"
                  options={Object.values(Chamber).map((value) => ({
                    value,
                    label: value,
                  }))}
                />
              </FieldGroup>
              <FieldGroup>
                <FormSelectField
                  label="State"
                  name="state"
                  options={Object.values(State).map((value) => ({
                    value,
                    label: value,
                  }))}
                />
              </FieldGroup>

              <FieldGroup>
                <FormTextField label="District (optional)" name="district" type="number" />
              </FieldGroup>
            </FormSection>
            <FormSection title="Party">
              <FieldGroup>
                <FormSelectField
                  label="Party"
                  name="party"
                  options={Object.values(Party).map((party) => ({
                    value: party,
                    label: party,
                  }))}
                />
              </FieldGroup>
            </FormSection>
            <FormSection title="Date">
              <FieldGroup>
                <FormDateField label="Start date" name="startDate" />
                <FormDateField label="End date" name="endDate" />
              </FieldGroup>
            </FormSection>

            <FormButtonGroup>
              <Button
                variant="contained"
                onClick={() => {
                  props.resetForm()
                  onComplete()
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={props.isSubmitting}
                onClick={() => {
                  props.submitForm()
                }}
              >
                Save
              </Button>
            </FormButtonGroup>
          </React.Fragment>
        )}
      </Formik>
    </FormContainer>
  )
}

export default AddTermForm
