import React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

export type ActionMenuAction = {
  label: string | React.ReactNode
  onClick: () => void
}

export type ActionsMenuProps = {
  title?: string
  actions: Array<ActionMenuAction>
}

const ActionsMenu: React.FC<ActionsMenuProps> = ({ actions, title = 'Actions' }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        variant="contained"
        onClick={handleClick}
      >
        {title}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actions.map((action, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              action.onClick()
              handleClose()
            }}
          >
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default ActionsMenu
