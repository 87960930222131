/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react'

export interface ToastContentProps {
  message: string
  variant?: 'success' | 'warning' | 'error' | 'info'
}

export interface ToastContextValue {
  closeToast: () => void
  setToast: (content: string | ToastContentProps) => void
}

export const ToastContext = React.createContext<ToastContextValue>({
  closeToast: () => {},
  setToast: () => {},
})
