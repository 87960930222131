/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { IssueTemplateModel, IssueTemplateModelType } from './IssueTemplateModel'
import { IssueTemplateModelSelector } from './IssueTemplateModel.base'
import { VoteModel, VoteModelType } from './VoteModel'
import { VoteModelSelector } from './VoteModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  vote: VoteModelType
  issueTemplate: IssueTemplateModelType
}

/**
 * IssueTemplateVoteBase
 * auto generated base class for the model IssueTemplateVoteModel.
 */
export const IssueTemplateVoteModelBase = withTypedRefs<Refs>()(
  ModelBase.named('IssueTemplateVote')
    .props({
      __typename: types.optional(types.literal('IssueTemplateVote'), 'IssueTemplateVote'),
      vote: types.union(types.undefined, MSTGQLRef(types.late((): any => VoteModel))),
      issueTemplate: types.union(
        types.undefined,
        MSTGQLRef(types.late((): any => IssueTemplateModel)),
      ),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class IssueTemplateVoteModelSelector extends QueryBuilder {
  vote(
    builder?: string | VoteModelSelector | ((selector: VoteModelSelector) => VoteModelSelector),
  ) {
    return this.__child(`vote`, VoteModelSelector, builder)
  }
  issueTemplate(
    builder?:
      | string
      | IssueTemplateModelSelector
      | ((selector: IssueTemplateModelSelector) => IssueTemplateModelSelector),
  ) {
    return this.__child(`issueTemplate`, IssueTemplateModelSelector, builder)
  }
}
export function selectFromIssueTemplateVote() {
  return new IssueTemplateVoteModelSelector()
}

export const issueTemplateVoteModelPrimitives = selectFromIssueTemplateVote()
