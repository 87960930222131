/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { IssueModel, IssueModelType } from './IssueModel'
import { IssueModelSelector } from './IssueModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  issue: IssueModelType
}

/**
 * IssueUpdateResponseBase
 * auto generated base class for the model IssueUpdateResponseModel.
 */
export const IssueUpdateResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('IssueUpdateResponse')
    .props({
      __typename: types.optional(types.literal('IssueUpdateResponse'), 'IssueUpdateResponse'),
      success: types.union(types.undefined, types.boolean),
      message: types.union(types.undefined, types.string),
      issue: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => IssueModel))),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class IssueUpdateResponseModelSelector extends QueryBuilder {
  get success() {
    return this.__attr(`success`)
  }
  get message() {
    return this.__attr(`message`)
  }
  issue(
    builder?: string | IssueModelSelector | ((selector: IssueModelSelector) => IssueModelSelector),
  ) {
    return this.__child(`issue`, IssueModelSelector, builder)
  }
}
export function selectFromIssueUpdateResponse() {
  return new IssueUpdateResponseModelSelector()
}

export const issueUpdateResponseModelPrimitives = selectFromIssueUpdateResponse().success.message
